import { useTranslation } from "react-i18next";
import { questionMarks, someOne } from "../../assets";
const How = () => {
  const { t } = useTranslation();

  return (
    <section className="mt-20 lg:mt-32">
      <div className="container">
        <div className="flex items-center flex-col justify-center max-w-[520px] mx-auto">
          <div className="rounded-full shadow-[0px_0px_300px_100px_#5E5DDE] absolute -z-1"></div>
          <h4 className="text-[22px] md:text-3xl md:leading-[48px] font-bold text-center relative">
            <img
              loading="lazy"
              className="-bottom-[200px] z-10 -right-2 md:-top-14 md:-right-8 absolute"
              alt="question-marks"
              src={questionMarks}
            />
            {t("home.how")}
          </h4>
          <div className="mt-40 h-[330px] bg-white w-full rounded-[30px] relative shadow-[0px_20px_54px_0px_#00000026]">
            <img
              loading="lazy"
              className="absolute -top-[144px] w-[320px] md:w-[900px] max-content left-[50%] translate-x-[-50%]"
              alt="some-one"
              src={someOne}
            />
            <div className="hidden xl:block rounded-[10px] -right-[300px] -top-[35px] right-20px h-fit bg-white w-[380px] mx-auto shadow-[0px_20px_54px_0px_#00000026] absolute -bottom-4 font-medium text-2xl py-5 px-[6px] text-center">
              {t("home.aOne")}
            </div>
            <div className="hidden xl:block rounded-[10px] -left-[300px] top-[50px] right-20px h-fit bg-white w-[355px] mx-auto shadow-[0px_20px_54px_0px_#00000026] absolute -bottom-4 font-medium text-xl py-5 px-[6px] text-center">
              {t("home.aTwo")}
            </div>
            <div className="text-base md:text-xl rounded-[10px] left-[50%] translate-x-[-50%] bg-white w-[90%] md:w-[80%] mx-auto shadow-[0px_20px_54px_0px_#00000026] absolute -bottom-4 font-medium  py-5 px-[6px] text-center">
              {t("home.aThree")}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default How;
