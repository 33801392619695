import { useContext, useEffect } from "react";
import { logo, shield } from "../assets";
import DOMPurify from "dompurify";
import LogoLoading from "../components/Layouts/LogoLoading";
import { AppContext } from "../context/AppContext";
import EngineSeo from "../Engine/EngineSeo";
import { useTranslation } from "react-i18next";
const SEO_PRIVACIES = {
  en: {
    title: "SkillTax - Privacy Policy | How We Protect Your Information",
    description:
      "Visit SkillTax's Privacy Policy page to learn how we protect and handle your personal information. Our policy outlines the measures we take to ensure your data is secure while using our POS system for restaurant management.",
    keywords:
      "Privacy Policy, SkillTax POS System, Data Protection, Personal Information Security, SkillTax Privacy, Information Handling, POS System Privacy",
  },
  ar: {
    title: "SkillTax - سياسة الخصوصية | كيف نحمي معلوماتك",
    description:
      "قم بزيارة صفحة سياسة الخصوصية الخاصة بسكيل تاكس لتتعرف على كيفية حماية ومعالجة معلوماتك الشخصية. توضح سياستنا التدابير التي نتخذها لضمان أمان بياناتك أثناء استخدام نظام نقاط البيع لإدارة المطاعم.",
    keywords:
      "سياسة الخصوصية, نظام سكيل تاكس, حماية البيانات, أمان المعلومات الشخصية, خصوصية سكيل تاكس, معالجة المعلومات, خصوصية نظام نقاط البيع",
  },
};

const Privacies = () => {
  const { i18n, t } = useTranslation();
  const { privacies, PrivaciesIsLoading } = useContext(AppContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <EngineSeo {...SEO_PRIVACIES} />
      <div className="w-full h-full mt-2 sm:mt-4 md:mt-16">
        {PrivaciesIsLoading ? (
          <div className="w-full h-[55vh] flex items-center justify-center">
            <LogoLoading />
          </div>
        ) : (
          <div className="container">
            {/* Head */}
            <div className="bg-[#4541C1] w-full md:h-36 rounded-[20px] px-4 md:px-12 py-4 flex items-center justify-between overflow-hidden">
              <img
                loading="lazy"
                src={shield}
                className="hidden md:block"
                alt="shield"
              />
              <h3 className="text-white font-medium text-2xl md:text-3xl">
                {t("links.privacies")}
              </h3>
              <img
                loading="lazy"
                src={shield}
                className="w-20 md:w-auto"
                alt="shield"
              />
            </div>
            <div className="py-10 pb-5 md:py-14">
              <img
                loading="lazy"
                src={logo}
                alt="logo"
                className="mx-auto object-cover"
              />
            </div>
            <ul className="font-medium">
              {privacies.map(({ id, ar_first, en_first, second }) => (
                <li key={id} className="mt-10 mb-3">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        i18n.language == "ar" ? ar_first : en_first
                      ),
                    }}
                    className="text-[#131313] text-2xl"
                  />
                  {second && (
                    <ul className="list-disc px-[18px] text-[#62646C]">
                      {second.map(({ id, ar_second, en_second, third }) => (
                        <li key={id} className="mt-1">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                i18n.language == "ar" ? ar_second : en_second
                              ),
                            }}
                            className="text-lg"
                          />
                          {third && (
                            <ul className="text-[#354FB9] mb-3 list-disc px-2.5">
                              {third.map(({ id, ar_third, en_third }) => (
                                <li key={id} className="mt-1">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        i18n.language == "ar"
                                          ? ar_third
                                          : en_third
                                      ),
                                    }}
                                    className="text-lg"
                                  />
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Privacies;
