import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import LogoLoading from "../../components/Layouts/LogoLoading";
import { AppContext } from "../../context/AppContext";
import EngineSeo from "../../Engine/EngineSeo";
import DOMPurify from "dompurify";

const SEO_BLOG = (blogs) => ({
  en: {
    title: `SkillTax Blog | ${blogs?.en_department || "Department"}`,
    description:
      "Welcome to the SkillTax Blog. Explore our latest news, important contracts, service agreements, and strategic partnerships. Stay informed with detailed insights and updates on our POS systems and restaurant management solutions.",
    keywords:
      "SkillTax Blog, POS System Insights, Restaurant Management News, Service Contracts, Legal Agreements, Strategic Partnerships, SkillTax Updates, Business Collaborations, POS System News",
  },
  ar: {
    title: `مدونة سكيل تاكس | ${blogs?.ar_department || "قسم"}`,
    description:
      "مرحبًا بك في مدونة سكيل تاكس. استكشف أحدث الأخبار، العقود المهمة، اتفاقيات الخدمة، والشراكات الاستراتيجية. تابع أحدث الرؤى والتحديثات حول أنظمة نقاط البيع وإدارة المطاعم لدينا.",
    keywords:
      "مدونة سكيل تاكس, رؤى نظام نقاط البيع, أخبار إدارة المطاعم, عقود الخدمة, الاتفاقيات القانونية, الشراكات الاستراتيجية, تحديثات سكيل تاكس, التعاونات التجارية, أخبار نظام نقاط البيع",
  },
});

const Blogs = () => {
  const { id } = useParams();
  const { i18n } = useTranslation();
  const { formatDate } = useContext(AppContext);
  const navigate = useNavigate();

  // BLOG
  const [blogs, setBlogs] = useState({});
  const [blogsIsLoading, setBlogsIsLoading] = useState(false);
  const [blogsError, setBlogsError] = useState(null);

  const fetchblogs = async () => {
    try {
      setBlogsIsLoading(true);
      let response = await fetch(`https://dash.wajen.net/api/v2/blogs/${id}`);
      let data = await response.json();
      if (Object.keys(data).length == 0) {
        navigate("/404");
        return;
      }
      setBlogs(data);
    } catch (err) {
      setBlogsError(err);
      navigate("/404");
    } finally {
      setBlogsIsLoading(false);
    }
  };

  useEffect(() => {
    fetchblogs();
  }, [id]);

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EngineSeo {...SEO_BLOG(blogs)} />
      {blogsIsLoading ? (
        <div className="h-[70vh] flex items-center ju justify-center">
          <LogoLoading />
        </div>
      ) : (
        <>
          <section
            className="absolute top-0 sm:top-1.5 left-[50%] translate-x-[-50%] z-[-1] h-[50vh] sm:h-[80vh] bg-cover w-full sm:w-[99%] bg-center px-4 sm:px-14 rounded-b-3xl sm:rounded-3xl overflow-hidden"
            style={{
              backgroundImage:
                blogs?.image && `url(https://dash.wajen.net${blogs?.image})`,
            }}
          >
            <div className="w-full h-full absolute top-0 left-0 bg-black bg-opacity-30 rounded-b-3xl"></div>
            <div className=" absolute bottom-5 sm:bottom-10">
              <h3 className="text-4xl text-white font-medium capitalize">
                {i18n.language == "ar"
                  ? blogs?.ar_department
                  : blogs?.en_department}
              </h3>
            </div>
          </section>
          {blogs?.blog?.length > 0 ? (
            <section className="relative pt-[50vh] sm:pt-[80vh] w-full">
              <div className="container">
                <div className="flex items-center flex-wrap justify-center gap-7">
                  {blogs?.blog?.map(
                    ({
                      id,
                      ar_title,
                      en_title,
                      image,
                      ar_details,
                      en_details,
                      created_at,
                    }) => {
                      return (
                        <Link
                          onClick={handleLinkClick}
                          key={id}
                          to={`/blog/${id}`}
                          className="w-[400px] h-[390px] border bg-white p-2 rounded-xl overflow-hidden"
                        >
                          <div>
                            <img
                              className="object-cover rounded-xl w-full h-64"
                              src={image && `https://dash.wajen.net${image}`}
                              alt={en_title}
                            />
                          </div>
                          <div className="mt-2">
                            <p className="text-[#62646C] text-sm">
                              {formatDate(created_at)}
                            </p>
                            <h4 className="mt-1 mb-1 text-2xl font-medium text-[#222] capitalize line-clamp-1">
                              {i18n.language == "ar" ? ar_title : en_title}
                            </h4>
                            <p className="text-[#62646C] text-sm line-clamp-2">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    i18n.language == "ar"
                                      ? ar_details
                                      : en_details
                                  ),
                                }}
                              />
                            </p>
                          </div>
                        </Link>
                      );
                    }
                  )}
                </div>
              </div>
            </section>
          ) : (
            <section className="relative pt-[50vh] sm:pt-[72vh] w-full">
              <div className="container text-2xl font-medium text-[#222222] capitalize">
                {i18n.language == "ar"
                  ? "لا يوجد اخبار عن"
                  : "There is no news about"}{" "}
                {i18n.language == "ar"
                  ? blogs?.ar_department
                  : blogs?.en_department}{" "}
                {i18n.language == "ar"
                  ? "جديدة في الوقت الراهن"
                  : "new at the moment"}
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};

export default Blogs;
