import { useTranslation } from "react-i18next";
import {
  cloud,
  chart,
  notes,
  cashier,
  menu,
  billingIcon,
  performance,
  departments,
  whyImageOne,
  whyImageTwo,
  whyImageThree,
  whyImageFour,
} from "../../assets";

const Content = ({ data, reverse, imageOne, imageTwo }) => {
  const { i18n } = useTranslation();

  return (
    <div
      className={`${
        reverse ? "xl:flex-row-reverse" : "xl:flex-row"
      } flex flex-col items-center justify-between w-full gap-5`}
    >
      {/* image container */}
      <div className="relative">
        <div className="w-[270px] h-52 sm:w-[404px] sm:h-[260px] md:w-[504px] md:h-[360px] rounded-[30px] overflow-hidden">
          <img
            loading="lazy"
            src={imageOne}
            alt="image-one"
            className="object-cover rounded-[30px] w-full h-full"
          />
        </div>
        {/* right */}
        <div
          className={`${
            i18n.language == "ar"
              ? "-right-8 sm:right-[-52px] md:-right-[87px] xl:-right-[45px] 2xl:-right-[87px]"
              : "-left-8 sm:left-[-52px] md:-left-[87px] xl:-left-[45px] 2xl:-left-[87px]"
          } absolute w-24 overflow-hidden h-16 sm:w-[144px] sm:h-[104px] bottom-9  sm:bottom-[47px] md:w-[174px] md:h-[124px] md:bottom-9 rounded-[10px]`}
        >
          <img
            loading="lazy"
            src={imageTwo}
            alt="image-two"
            className="w-full h-full object-cover rounded-[10px]"
          />
        </div>
      </div>
      {/* card container */}
      <div className="grid grid-cols-1 md:grid-cols-2 w-full md:w-fit">
        {data.map(({ id, icon, tilte, text }) => {
          return (
            <div
              className={`${
                (id == 1 || id == 2) && "md:border-b border-[#EDEDED]"
              } ${
                (id == 1 || id == 3) &&
                (i18n.language == "ar"
                  ? "md:border-l border-[#EDEDED]"
                  : "md:border-r border-[#EDEDED]")
              } flex flex-col items-start px-3 py-6 gap-5 w-80`}
              key={id}
            >
              <div className="bg-[#E8E8E8] h-14 w-14 flex items-center justify-center rounded-lg p-2">
                <img loading="lazy" src={icon} alt="icon" />
              </div>
              <div className="text-start">
                <h3 className="text-2xl text-[#131313] font-medium">{tilte}</h3>
                <p className="text-[#62646C] text-base mt-1">{text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const WhyUs = () => {
  const { i18n, t } = useTranslation();

  const contentOneData = [
    {
      id: 1,
      icon: cloud,
      tilte: t("home.cloudSystem"),
      text: t("home.cloudSystemText"),
    },
    {
      id: 2,
      icon: cashier,
      tilte: t("home.cashier"),
      text: t("home.cashierText"),
    },
    {
      id: 3,
      icon: notes,
      tilte: t("home.reports"),
      text: t("home.reportsText"),
    },
    {
      id: 4,
      icon: chart,
      tilte: t("home.units"),
      text: t("home.unitsText"),
    },
  ];

  const contentTwoData = [
    {
      id: 1,
      icon: menu,
      tilte: t("home.conectMenu"),
      text: t("home.conectMenuText"),
    },
    {
      id: 2,
      icon: billingIcon,
      tilte: t("home.billing"),
      text: t("home.billingText"),
    },
    {
      id: 3,
      icon: performance,
      tilte: t("home.performance"),
      text: t("home.performanceText"),
    },
    {
      id: 4,
      icon: departments,
      tilte: t("home.tables"),
      text: t("home.tablesText"),
    },
  ];

  return (
    <section className="container text-center mt-20 lg:mt-32">
      <h2 className="main-heading">{t("home.whyUs")}</h2>
      <p className="text-lg md:text-xl font-medium text-[#222222] md:max-w-96 mx-auto mt-2">
        {t("home.anwser")}
      </p>
      <div className="flex flex-col gap-12 xl:gap-20 mt-20">
        <Content
          data={contentOneData}
          imageOne={whyImageOne}
          imageTwo={whyImageTwo}
        />
        <Content
          data={contentTwoData}
          reverse
          imageOne={whyImageThree}
          imageTwo={whyImageFour}
        />
      </div>
    </section>
  );
};

export default WhyUs;
