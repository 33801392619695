import { useEffect, useRef, useState } from "react";
import { packages, trueSvg, falseSvg } from "../assets";
import packagesData from "../packages.json";
import useScreenSize from "../hooks/useScreenSize";
import ContactUs from "../components/Layouts/ContactUs";
import EngineSeo from "../Engine/EngineSeo";
import { useTranslation } from "react-i18next";
const BASIC = packagesData[0];
const PREMIUM = packagesData[1];

const SEO_PACKAGES = {
  en: {
    title: "SkillTax - Packages | Explore Our POS System Solutions",
    description:
      "Visit SkillTax's Packages page to explore the various packages available for our POS system. Find the right solution for your restaurant management needs with our tailored options designed to suit different business requirements.",
    keywords:
      "Packages, SkillTax POS System, Restaurant Management Solutions, POS System Packages, SkillTax Pricing, POS Solutions, Restaurant Software Packages",
  },
  ar: {
    title: "SkillTax - باقات | استكشف حلول نظام نقاط البيع لدينا",
    description:
      "قم بزيارة صفحة الباقات الخاصة بسكيل تاكس لاستكشاف الخيارات المختلفة المتاحة لنظامنا. اعثر على الحل المناسب لاحتياجات إدارة المطاعم الخاصة بك مع خياراتنا المخصصة المصممة لتناسب متطلبات الأعمال المختلفة.",
    keywords:
      "الباقات, نظام سكيل تاكس, حلول إدارة المطاعم, باقات نظام نقاط البيع, تسعير سكيل تاكس, حلول نقاط البيع, باقات برامج المطاعم",
  },
};

const Packages = () => {
  const { t } = useTranslation();
  const { width } = useScreenSize();
  const [tabActive, setTabActive] = useState("monthly");

  const activeTabClasses =
    "bg-[#6C74E8] shadow-[0px_0px_18px_0px_#6C74E8A6] text-white";
  const nonActiveTabClasses = "bg-white text-[%6C74E8]";

  const [showPackage, setShowPackage] = useState({
    1: null,
    2: null,
  });

  const onShowPackage = (id) => {
    if (showPackage[id] == null) {
      setShowPackage((prev) => {
        return { ...prev, [id]: id };
      });
    } else {
      setShowPackage((prev) => {
        return { ...prev, [id]: null };
      });
    }
  };

  const [showFaqs, setShowFaqs] = useState({
    1: null,
    2: null,
    3: null,
  });

  const onShowFaqs = (id) => {
    if (showFaqs[id] == null) {
      setShowFaqs((prev) => {
        return { ...prev, [id]: id };
      });
    } else {
      setShowFaqs((prev) => {
        return { ...prev, [id]: null };
      });
    }
  };

  const bottomSectionRef = useRef(null);

  const scrollToContactUs = () => {
    bottomSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EngineSeo {...SEO_PACKAGES} />
      <div className="w-full py-7 h-full md:h-[500px] bg-[#5E5DDE]">
        <div className="container h-full">
          <div className="w-full flex-col-reverse md:flex-row gap-7 flex h-full items-center justify-between">
            <div className="w-full max-w-[600px]">
              <div className="bg-[#F8F8FF] w-fit mb-4 text-[#7078E7] text-sm h-[26px] px-2.5 flex items-center justify-center rounded-[90px]">
                <span className="block -mb-1 sm:mb-0">
                  {t("packages.forYou")}
                </span>
              </div>
              <h2 className="text-white font-bold text-xl lg:text-3xl">
                {t("packages.packagesForYou")}
              </h2>
              <p className="mt-2 text-base lg:text-lg text-[#CAD6FB] text-start">
                {t("packages.packagesForYouText")}
              </p>
              <button
                onClick={scrollToContactUs}
                className="mt-5 text-sm rounded-lg bg-[#6770F0] font-medium text-white h-[42px] w-[132px]"
              >
                <span className="block -mb-1 sm:mb-0">
                  {t("links.subscribe")}
                </span>
              </button>
            </div>
            <div className="w-full flex justify-center md:justify-end">
              <img loading="lazy" src={packages} alt="packages" />
            </div>
          </div>
        </div>
      </div>
      <section className="mt-8 lg:mt-14">
        <div className="container">
          <div className="text-center">
            <h3 className="font-medium text-[35px] sm:text-4xl md:text-5xl lg:text-6xl xl:text-[65px] xl:leading-[91px] text-[#131313]">
              {t("links.packages")}
            </h3>
            <p className="text-[#62646C] font-medium text-lg sm:text-xl">
              {t("packages.enjoy")}
            </p>
            {/* Tabs */}
            <div className="w-[220px] mt-8 mx-auto relative flex items-center h-[57px] gap-2.5 p-2 bg-white border border-[#E2E9FD] rounded-full">
              <button
                onClick={() => setTabActive("monthly")}
                className={`${
                  tabActive == "monthly"
                    ? activeTabClasses
                    : nonActiveTabClasses
                } flex-1 h-full rounded-[40px] text-lg font-medium transition-all duration-300`}
              >
                <span className="block -mb-1 sm:mb-0">
                  {t("packages.monthly")}
                </span>
              </button>
              <button
                onClick={() => setTabActive("yearly")}
                className={`${
                  tabActive == "yearly" ? activeTabClasses : nonActiveTabClasses
                } flex-1 h-full rounded-[40px] text-lg font-medium transition-all duration-300`}
              >
                <span className="block -mb-1 sm:mb-0">
                  {t("packages.yearly")}
                </span>
              </button>
            </div>
          </div>
          {/* Packages */}
          <div className="flex gap-7 lg:gap-0 flex-col lg:flex-row items-center mt-28 justify-center">
            {/* BASIC */}
            <div className="w-full border border-[#E2E9FD] lg:border-none sm:w-[370px] bg-white rounded-3xl shadow-[0px_60px_80px_0px_#C6C6C673] relative lg:-left-1 z-10">
              {/* Head */}
              <div className="p-5 pb-6 border-b border-[#E2E2E2 ]">
                <span className="bg-[#F8F8FF] block w-full font-medium text-lg text-[#7078E7] p-1 rounded-[5px]">
                  {t(BASIC.title)}
                </span>
                <div className="text-[131313] font-bold mt-4 flex items-end gap-2">
                  <span className="text-5xl sm:text-6xl">
                    {tabActive == "monthly"
                      ? BASIC?.price_per_month
                      : BASIC?.price_per_year}
                  </span>
                  <span className="text-2xl sm:text-3xl">
                    {t("packages.rs")}
                  </span>
                </div>
              </div>
              {/* Body */}
              <div className="p-2.5">
                <button
                  onClick={() => {
                    width < 1024
                      ? onShowPackage(BASIC.id)
                      : scrollToContactUs();
                  }}
                  className="bg-[#6770F0] mb-4 block w-full h-[48px] sm:h-[52px] rounded-[10px] text-base sm:text-lg font-medium text-white shadow-[0px_10px_34px_0px_#9FA0E8]"
                >
                  <span className="block -mb-1 sm:mb-0">
                    {width >= 1024
                      ? t("links.subscribe")
                      : showPackage[BASIC.id]
                      ? t("packages.hide")
                      : t("packages.show")}
                  </span>
                </button>
                <ul
                  className={`${
                    width < 1024 && (showPackage[BASIC.id] ? "block" : "hidden")
                  } duration-300 ease-in relative`}
                >
                  {BASIC?.advantages.map(({ id, title, include }) => {
                    return (
                      <li
                        key={id}
                        className="w-full flex items-center justify-between px-2.5 pb-2 py-4 border-b border-[#F3F3F3] text-[#131313] text-lg"
                      >
                        <span>{t(title)}</span>
                        <span>
                          <img
                            loading="lazy"
                            src={include ? trueSvg : falseSvg}
                            alt="mark"
                            className="w-5 h-5"
                          />
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {/* Line */}
            <div className="hidden lg:block w-16 h-[1100px] bg-gradient-to-b from-[#6365C0] to-[#F6F7FF] rounded-t-full relative"></div>
            {/* PREMIUM */}
            <div className="w-full border border-[#E2E9FD] lg:border-none sm:w-[370px] bg-white rounded-3xl shadow-[0px_60px_80px_0px_#C6C6C673] relative lg:left-1 z-10">
              {/* Head */}
              <div className="p-5 pb-6 border-b border-[#E2E2E2 ]">
                <span className="bg-[#F8F8FF] block w-full font-medium text-lg text-[#7078E7] p-1 rounded-[5px]">
                  {t(PREMIUM.title)}
                </span>
                <div className="text-[131313] font-bold mt-4 flex items-end gap-2">
                  <span className="text-5xl sm:text-6xl">
                    {tabActive == "monthly"
                      ? PREMIUM?.price_per_month
                      : PREMIUM?.price_per_year}
                  </span>
                  <span className="text-2xl sm:text-3xl">
                    {t("packages.rs")}
                  </span>
                </div>
              </div>
              {/* Body */}
              <div className="p-2.5">
                <button
                  onClick={() => {
                    width < 1024
                      ? onShowPackage(PREMIUM.id)
                      : scrollToContactUs();
                  }}
                  className="bg-[#6770F0] mb-4 block w-full h-[48px] sm:h-[52px] rounded-[10px] text-base sm:text-lg font-medium text-white shadow-[0px_10px_34px_0px_#9FA0E8]"
                >
                  <span className="block -mb-1 sm:mb-0">
                    {width >= 1024
                      ? t("links.subscribe")
                      : showPackage[PREMIUM.id]
                      ? t("packages.hide")
                      : t("packages.show")}
                  </span>
                </button>
                <ul
                  className={`${
                    width < 1024 &&
                    (showPackage[PREMIUM.id] ? "block" : "hidden")
                  } duration-300 ease-in relative`}
                >
                  {PREMIUM?.advantages.map(({ id, title, include }) => {
                    return (
                      <li
                        key={id}
                        className="w-full flex items-center justify-between px-2.5 pb-2 py-4 border-b border-[#F3F3F3] text-[#131313] text-lg"
                      >
                        <span>{t(title)}</span>
                        <span>
                          <img
                            loading="lazy"
                            src={include ? trueSvg : falseSvg}
                            alt="mark"
                            className="w-5 h-5"
                          />
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex items-center flex-col gap-14 lg:flex-row">
            <div className="relative max-w-[300px] text-center md:text-start md:max-w-[500px]">
              <span className="hidden md:block h-[70%] w-1 absolute bg-[#E2E9FD] rounded-full -right-4 overflow-hidden top-[50%] translate-y-[-50%]">
                <span className="w-full h-[45%] block bg-[#5E5DDE] rounded-full"></span>
              </span>
              <h4 className="text-2xl md:text-[40px] md:leading-[64px] font-bold text-[#131313]">
                {t("packages.faqs")}
              </h4>
            </div>
            {/* faqs */}
            <div className="flex flex-col gap-4">
              <div
                onClick={() => onShowFaqs(1)}
                className={`${
                  showFaqs[1]
                    ? "bg-[#6C74E8] shadow-[0px_14px_34px_0px_#C0C1F0] text-[#EFF4FE]"
                    : "bg-white text-[#131313]"
                } px-4 sm:px-5 py-4  border-[#CAD6FB] border rounded-2xl w-full sm:w-[600px] cursor-pointer select-none`}
              >
                <div className="w-full h-full flex justify-between items-center gap-3">
                  <span className="sm:text-lg font-semibold block">
                    {t("packages.qOne")}
                  </span>
                  <button
                    aria-label={showFaqs[1] ? "Collapse FAQ" : "Expand FAQ"}
                    className="block w-8"
                  >
                    {showFaqs[1] ? (
                      <div className="w-6 h-6 border-[#EFF4FE] border-[2px] rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="100%"
                          height="100%"
                          fill="#EFF4FE"
                        >
                          <path d="M19 13H5V11H19V13Z" />
                        </svg>
                      </div>
                    ) : (
                      <div className="w-6 h-6 border-[#4541C1] border-[2px] rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="100%"
                          height="100%"
                          fill="#4541C1"
                        >
                          <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                        </svg>
                      </div>
                    )}
                  </button>
                </div>
                <div
                  className={`${
                    showFaqs[1] ? "block" : "hidden"
                  } text-base text-white mt-4`}
                >
                  {t("packages.aOne")}
                </div>
              </div>
              <div
                onClick={() => onShowFaqs(2)}
                className={`${
                  showFaqs[2]
                    ? "bg-[#6C74E8] shadow-[0px_14px_34px_0px_#C0C1F0] text-[#EFF4FE]"
                    : "bg-white text-[#131313]"
                } px-4 sm:px-5 py-4  border-[#CAD6FB] border rounded-2xl w-full sm:w-[600px] cursor-pointer select-none `}
              >
                <div className="w-full h-full flex justify-between items-center gap-3">
                  <span className="sm:text-lg font-semibold block">
                    {t("packages.qTwo")}
                  </span>
                  <button
                    aria-label={showFaqs[2] ? "Collapse FAQ" : "Expand FAQ"}
                  >
                    {showFaqs[2] ? (
                      <div className="w-6 h-6 border-[#EFF4FE] border-[2px] rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="100%"
                          height="100%"
                          fill="#EFF4FE"
                        >
                          <path d="M19 13H5V11H19V13Z" />
                        </svg>
                      </div>
                    ) : (
                      <div className="w-6 h-6 border-[#4541C1] border-[2px] rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="100%"
                          height="100%"
                          fill="#4541C1"
                        >
                          <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                        </svg>
                      </div>
                    )}
                  </button>
                </div>
                <div
                  className={`${
                    showFaqs[2] ? "block" : "hidden"
                  } text-base text-white mt-4`}
                >
                  {t("packages.aTwo")}
                </div>
              </div>
              <div
                onClick={() => onShowFaqs(3)}
                className={`${
                  showFaqs[3]
                    ? "bg-[#6C74E8] shadow-[0px_14px_34px_0px_#C0C1F0] text-[#EFF4FE]"
                    : "bg-white text-[#131313]"
                } px-4 sm:px-5 py-4  border-[#CAD6FB] border rounded-2xl w-full sm:w-[600px] cursor-pointer select-none `}
              >
                <div className="w-full h-full flex justify-between items-center gap-3">
                  <span className="sm:text-lg font-semibold block">
                    {t("packages.qThree")}
                  </span>
                  <button
                    aria-label={showFaqs[3] ? "Collapse FAQ" : "Expand FAQ"}
                  >
                    {showFaqs[3] ? (
                      <div className="w-6 h-6 border-[#EFF4FE] border-[2px] rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="100%"
                          height="100%"
                          fill="#EFF4FE"
                        >
                          <path d="M19 13H5V11H19V13Z" />
                        </svg>
                      </div>
                    ) : (
                      <div className="w-6 h-6 border-[#4541C1] border-[2px] rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="100%"
                          height="100%"
                          fill="#4541C1"
                        >
                          <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                        </svg>
                      </div>
                    )}
                  </button>
                </div>
                <div
                  className={`${
                    showFaqs[3] ? "block" : "hidden"
                  } text-base text-white mt-4`}
                >
                  {t("packages.aThree")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div ref={bottomSectionRef}>
        <ContactUs />
      </div>
    </>
  );
};

export default Packages;
