import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import ar from "/public/locales/ar.json";
import en from "/public/locales/en.json";

i18n
  .use(Backend) // loads translations from your server
  .use(LanguageDetector) // detects the user language
  .use(initReactI18next) // passes i18n instance to react-i18next
  .init({
    fallbackLng: "ar",
    debug: true,
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
    detection: {
      // Order and from where user language should be detected
      order: ["localStorage", "cookie", "htmlTag", "path", "subdomain"],

      // Keys or params to lookup language from
      lookupLocalStorage: "i18nextLng",
      lookupCookie: "i18next",

      // Cache user language on
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)
    },
    resources: {
      ar: {
        translation: ar,
      },
      en: {
        translation: en,
      },
    },
  });

export default i18n;
