import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import router from "./router.jsx";
import i18n from "./i18n";
import "./global.css";
import { I18nextProvider } from "react-i18next";
import { AppProvider } from "./context/AppContext.jsx";
import { HelmetProvider } from "react-helmet-async";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AppProvider>
      <I18nextProvider i18n={i18n}>
        <HelmetProvider>
          <RouterProvider router={router} />
        </HelmetProvider>
      </I18nextProvider>
    </AppProvider>
  </React.StrictMode>
);
