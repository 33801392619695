import { useTranslation } from "react-i18next";
import { arrowTop, foodServicesImage1, foodServicesImage2 } from "../../assets";
const FoodServices = () => {
  const { t } = useTranslation();

  return (
    <section className="mt-20 lg:mt-32">
      <div className="container flex flex-col xl:flex-row justify-between gap-10">
        <div className="max-w-[516px] xl:max-w-[616px] mx-autod">
          <div className="flex flex-col gap-8 xl:gap-10">
            <h3 className="text-xl md:text-3xl font-bold md:leading-[45.93px]">
              {t("home.integration")}
            </h3>
            <div className="flex flex-col gap-2 border-b border-[#D7D7D7] pb-4">
              <img loading="lazy" src={arrowTop} alt="arrow" className="w-4" />
              <div className="text-2xl text-[#131313] font-medium">
                {t("links.restaurantSectors")}
              </div>
              <p className="text-[#62646C] text-base">
                {t("home.restaurantSectors")}
              </p>
            </div>
            <div className="flex flex-col gap-2 border-b border-[#D7D7D7] pb-4">
              <img
                loading="lazy"
                src={arrowTop}
                alt="arrow"
                className="w-4 rotate-90"
              />
              <div className="text-2xl text-[#131313] font-medium">
                {t("links.cafesSectors")}
              </div>
              <p className="text-[#62646C] text-base">
                {t("home.cafesSectors")}
              </p>
            </div>
          </div>
        </div>
        <div
          dir="rtl"
          className="bg-[#FFFFFF] rounded-[10px] p-5 flex gap-0 justify-center w-fit mx-auto"
        >
          <img
            loading="lazy"
            src={foodServicesImage1}
            alt="food-image"
            className="w-40 lg:w-full -ml-3 lg:-ml-[18px]"
          />
          <img
            loading="lazy"
            src={foodServicesImage2}
            alt="food-image"
            className="w-40 lg:w-full  -mr-3 lg:-mr-[18px]"
          />
        </div>
      </div>
    </section>
  );
};

export default FoodServices;
