import { useEffect, useRef, useState } from "react";
import {
  bePartHero,
  footerLogo,
  diversity,
  feature,
  system,
  partOne,
  partTwo,
  bePartForm,
  avatars,
  bannerRectangle,
} from "../assets";
import Field from "../components/Layouts/Field";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import EngineSeo from "../Engine/EngineSeo";
import { useTranslation } from "react-i18next";

const SEO_BEPART = {
  en: {
    title: "SkillTax - Join Us | Become a Partner in Our POS System Network",
    description:
      "Visit SkillTax's Become a Partner page to learn how you can join our network of distributors and benefit from offering our leading POS system. Discover partnership opportunities and how SkillTax can help grow your business.",
    keywords:
      "Join Us, SkillTax Partnership, Become a Partner, POS System Distributor, SkillTax Network, Business Growth Opportunities, POS System Partnership, Join SkillTax",
  },
  ar: {
    title: "SkillTax - كُن جزءًا منا | انضم إلى شبكة موزعي نظام نقاط البيع",
    description:
      "زر صفحة الانضمام إلى سكيل تاكس لتتعلم كيف يمكنك الانضمام إلى شبكة موزعينا والاستفادة من تقديم نظام نقاط البيع الرائد لدينا. اكتشف فرص الشراكة وكيف يمكن أن يساعد سكيل تاكس في نمو عملك.",
    keywords:
      "انضم إلينا, شراكة سكيل تاكس, كن شريكًا, موزع نظام نقاط البيع, شبكة سكيل تاكس, فرص نمو الأعمال, شراكة نظام نقاط البيع, انضم إلى سكيل تاكس",
  },
};

const BePart = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollContainerRef = useRef(null);
  const middleDivRef = useRef(null);

  useEffect(() => {
    if (scrollContainerRef.current && middleDivRef.current) {
      const containerWidth = scrollContainerRef.current.offsetWidth;
      const middleDivOffsetLeft = middleDivRef.current.offsetLeft;
      const middleDivWidth = middleDivRef.current.offsetWidth;
      const scrollPosition =
        middleDivOffsetLeft + middleDivWidth / 2 - containerWidth / 2;
      scrollContainerRef.current.scrollLeft = scrollPosition;
    }
  }, []);

  // Form Validation
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(t("forms.firstNameReq")),
    last_name: Yup.string().required(t("forms.lastNameReq")),
    business_name: Yup.string().required(t("forms.activityNameReq")),
    phone: Yup.string()
      .required(t("forms.phoneReq"))
      .matches(/^\d{9}$/, t("forms.phoneErr")),
    email: Yup.string()
      .email(t("forms.emailReq"))
      .required(t("forms.emailReq")),
    city: Yup.string().required(t("forms.cityReq")),
    branches_no: Yup.number()
      .required(t("forms.branchesReq"))
      .typeError(t("forms.branchesReq")),
    terms_conditions: Yup.boolean().oneOf([true], t("forms.termsReq")),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      business_name: "",
      phone: "",
      email: "",
      city: "",
      branches_no: "",
      terms_conditions: false,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmission = async (values) => {
    delete values["terms_conditions"];
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://dash.wajen.net/api/v2/distributors`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(values),
        }
      );
      console.log(response);
      if (!response.ok) {
        toast.error(t("forms.err"));
        return;
      }
      toast.success(t("forms.send"));
      reset({
        first_name: "",
        last_name: "",
        business_name: "",
        phone: "",
        email: "",
        city: "",
        branches_no: "",
        terms_conditions: false,
      });
    } catch (error) {
      toast.error(t("forms.err"));
    } finally {
      setIsLoading(false);
    }
  };

  // FAQS
  const [showFaqs, setShowFaqs] = useState({
    1: null,
    2: null,
    3: null,
  });

  const onShowFaqs = (id) => {
    if (showFaqs[id] == null) {
      setShowFaqs((prev) => {
        return { ...prev, [id]: id };
      });
    } else {
      setShowFaqs((prev) => {
        return { ...prev, [id]: null };
      });
    }
  };

  const data = [
    {
      title: "bePart.cardOne",
      text: "bePart.cardOneText",
      img: diversity,
    },
    {
      title: "bePart.cardTwo",
      text: "bePart.cardTwoText",
      img: feature,
    },
    {
      title: "bePart.cardThree",
      text: "bePart.cardThreeText",
      img: system,
    },
  ];

  return (
    <>
      <EngineSeo {...SEO_BEPART} />
      <section className="bg-[#6C74E8] w-full">
        {/* Hero */}
        <div className="w-full flex items-center flex-col md:flex-row pt-8 p-4 md:p-0 relative overflow-hidden">
          <div className="absolute -rotate-180 -top-7 md:right-[11%]">
            <img loading="lazy" src={bannerRectangle} alt="banner-rectangle" />
          </div>
          <div className="absolute -rotate-90 md:bottom-28 -right-[150px] ">
            <img loading="lazy" src={bannerRectangle} alt="banner-rectangle" />
          </div>
          <div className="flex-1 w-full flex items-center justify-center md:px-4">
            <div className="max-w-[570px] text-start mt-4 md:mt-0 text-[#EFEFEF]">
              <h3 className="font-medium flex-col-reverse lg:flex-row text-[40px] leading-[63.87px] flex items-center md:items-start lg:items-center gap-3">
                <p className="relative">
                  <span className="z-10 relative">{t("bePart.bePart")}</span>
                  <span className="absolute w-full h-6 bg-[#5E5DDE] left-0 top-[50%] translate-y-[-50%]"></span>
                </p>
                <span>
                  <img
                    loading="lazy"
                    src={footerLogo}
                    alt="logo"
                    className="w-24 lg:w-auto"
                  />
                </span>
              </h3>
              <p className="text-xl lg:text-2xl font-medium mt-3 text-center md:text-start">
                {t("bePart.bePartText")}
              </p>
              <p className="max-w-[370px] text-base lg:text-xl font-normal mt-2 lg:mt-3 text-center md:text-start">
                {t("bePart.bePartTextTwo")}
              </p>
            </div>
          </div>
          <div className="flex-1 flex items-center gap-1 justify-end ">
            <img
              loading="lazy"
              src={bePartHero}
              alt="be-part-hero-image"
              className="w-full object-cover max-w-full rounded-[15px] md:rounded-none mt-7 md:mt-0 h-[362px] lg:h-[500px]"
            />
          </div>
        </div>
        <div className="mt-14 lg:mt-20 w-full relative overflow-hidden">
          <div className="absolute -bottom-7 right-[27%] ">
            <img loading="lazy" src={bannerRectangle} alt="banner-rectangle" />
          </div>
          <div className="absolute -bottom-7 left-0 hidden md:block">
            <img loading="lazy" src={bannerRectangle} alt="banner-rectangle" />
          </div>
          <div
            ref={scrollContainerRef}
            className="w-full flex items-center xl:justify-center gap-4 md:gap-9 overflow-x-auto no-scrollbar md:scrollbar px-4 pb-10 lg:pb-20 z-[1]"
          >
            {data.map(({ title, text, img }, idx) => {
              return (
                <div
                  key={idx}
                  ref={idx == 1 ? middleDivRef : null}
                  className={`${
                    // shadow-[0px_30px_50px_0px_#00000040]
                    idx == 1 && ""
                  } flex-none w-[330px] md:w-[390px] h-[183px] md:h-[173px] bg-white rounded-[15px] p-4 md:p-5 gap-4`}
                >
                  <div className="flex items-center gap-2">
                    <div className="w-14 h-14 rounded-full bg-[#E2E9FD] flex items-center justify-center p-3 flex-shrink-0">
                      <img loading="lazy" src={img} alt="icon" />
                    </div>
                    <h4 className="text-normal text-2xl"> {t(title)}</h4>
                  </div>
                  <p className="text-[#62646C] text-[15px] w-full overflow-auto whitespace-normal mt-3">
                    {t(text)}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex items-center justify-between gap-6 flex-col-reverse md:flex-row">
            <div className="font-medium max-w-[400px]">
              <h5 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("bePart.programs")}
              </h5>
              <p className="text-[#62646C] text-xl mt-3">
                {t("bePart.programsText")}
              </p>
            </div>
            <div>
              <img
                loading="lazy"
                src={partOne}
                alt="part"
                className="w-[480px]"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col gap-6 md:gap-20 md:flex-row items-center justify-between">
            <div className="max-w-[500px]">
              <img loading="lazy" src={partTwo} alt="part" />
            </div>
            <div className="w-full lg:w-[370px] xl:w-[402px] font-medium ">
              <h5 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("bePart.support")}
              </h5>
              <p className="text-[#62646C] text-xl mt-3">
                {t("bePart.supportText")}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Form */}
      <section className="mt-20 lg:mt-32">
        <div className="container bg-white lg:bg-transparent ">
          <div className="bg-white w-full h-full flex items-center">
            <div className="flex-1 w-full h-full py-10 lg:px-10 2xl:px-20">
              <h5 className="text-[40px] font-medium flex-col-reverse items-start sm:flex-row lg:flex-col-reverse xl:flex-row text-[#101828] mb-10 text-center justify-center flex sm:items-center gap-6 sm:gap-3">
                <span>{t("bePart.joinUs")}</span>
                <span
                  className={`${i18n.language == "ar" ? "-mb-3" : "-mb-1"}`}
                >
                  <svg
                    width="158"
                    height="28"
                    viewBox="0 0 158 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_752_472)">
                      <path
                        d="M5.20906 26.5106C3.82656 25.9299 2.69094 25.1555 1.80218 24.1634C0.913435 23.1955 0.320937 22.1066 0 20.9451L4.41906 19.106C4.81406 20.2917 5.50531 21.2596 6.49281 21.9372C7.45562 22.6389 8.59125 22.9777 9.875 22.9777C11.1834 22.9777 12.1956 22.7357 12.8869 22.2276C13.5781 21.7436 13.9237 20.9934 13.9237 20.0013C13.9237 18.9366 13.5287 18.0655 12.7634 17.3879C11.9734 16.6862 10.665 16.0328 8.78875 15.3553C6.02375 14.3147 3.99937 13.2016 2.765 11.9675C1.53063 10.7334 0.888745 9.20891 0.888745 7.36985C0.888745 6.08734 1.23437 4.92583 1.92562 3.90951C2.61687 2.89319 3.60437 2.09466 4.91281 1.5139C6.19656 0.93314 7.72719 0.642761 9.50469 0.642761C11.5537 0.642761 13.3312 1.00574 14.8125 1.70749C16.2937 2.43343 17.5034 3.49816 18.3922 4.97425L14.4669 7.36985C13.8744 6.54711 13.1584 5.94218 12.3684 5.55501C11.5537 5.16784 10.6403 4.97425 9.57875 4.97425C8.44312 4.97425 7.57906 5.19203 6.93719 5.6518C6.29531 6.08737 5.97437 6.71652 5.97437 7.51506C5.97437 8.3378 6.39406 9.06372 7.23344 9.66868C8.07281 10.2736 9.38125 10.927 11.1587 11.6045C13.8991 12.6451 15.9234 13.8308 17.2566 15.2101C18.5897 16.5894 19.2562 18.2349 19.2562 20.1465C19.2562 21.4774 18.8859 22.6873 18.1206 23.8004C17.3553 24.8893 16.2691 25.7605 14.8619 26.4138C13.43 27.0672 11.7512 27.3818 9.82562 27.3818C8.14687 27.406 6.59156 27.0914 5.20906 26.5106Z"
                        fill="#5E5DDE"
                      />
                      <path
                        d="M29.7731 0.691162V27.406H24.0703V0.691162H29.7731ZM38.4384 5.82118H45.3756L37.8706 16.1054L45.9681 27.406H39.0309L31.3531 16.1054L38.4384 5.82118Z"
                        fill="#5E5DDE"
                      />
                      <path
                        d="M49.7208 0.691162V7.03109H56.3123V0.691162H49.7208ZM49.7208 10.685L45.9683 15.815H50.4614V27.3818H56.3123V10.685H49.7208Z"
                        fill="#5E5DDE"
                      />
                      <path
                        d="M64.3109 26.3412C63.2741 25.6395 62.4841 24.6957 61.9409 23.4858C61.3978 22.3001 61.1509 20.9692 61.1509 19.4931L61.1016 10.2252C61.1016 6.6439 61.1016 3.76429 61.1016 1.61066C61.1016 -0.542979 61.1016 4.19986 61.1016 0.666931H67.1006C67.1006 8.87011 67.1006 9.76545 67.1006 18.5252C67.1006 19.2511 67.1747 19.8803 67.3228 20.3643C67.4709 20.8482 67.6931 21.2354 68.0387 21.5258C68.3844 21.8161 68.8041 21.9614 69.2978 21.9614C69.915 21.9614 70.5075 21.8887 71.0753 21.7436L72.0134 26.8494C71.0753 27.2123 69.8162 27.4059 68.2116 27.4059C66.6562 27.4059 65.3478 27.043 64.3109 26.3412Z"
                        fill="#5E5DDE"
                      />
                      <path
                        d="M79.9877 26.3412C78.9508 25.6395 78.1608 24.6957 77.6177 23.4858C77.0746 22.3001 76.8277 20.9692 76.8277 19.4931L76.7783 10.2252C76.7783 6.6439 76.7783 3.76429 76.7783 1.61066C76.7783 -0.542979 76.7783 4.19986 76.7783 0.666931H82.7774C82.7774 8.87011 82.7774 9.76545 82.7774 18.5252C82.7774 19.2511 82.8514 19.8803 82.9996 20.3643C83.1477 20.8482 83.3699 21.2354 83.7155 21.5258C84.0611 21.8161 84.4808 21.9614 84.9746 21.9614C85.5918 21.9614 86.1843 21.8887 86.7521 21.7436L87.6902 26.8494C86.7521 27.2123 85.493 27.4059 83.8883 27.4059C82.333 27.4059 81.0246 27.043 79.9877 26.3412Z"
                        fill="#5E5DDE"
                      />
                      <path
                        d="M103.515 26.8494C102.675 27.2123 101.367 27.4059 99.6388 27.4059C97.4416 27.4059 95.7135 26.7284 94.4297 25.3733C93.146 24.0182 92.5041 22.2759 92.4547 20.1707L92.4053 16.4442C92.4053 15.1859 92.4053 14.1695 92.43 13.371C92.4547 12.5724 92.4547 11.5561 92.4547 10.3462H88.6528L92.4053 5.2162V0.715332H98.5031V5.1678H103.515V10.2978H98.5031V18.5252C98.5278 19.5899 98.7253 20.4127 99.0956 20.9934C99.466 21.5742 100.009 21.8888 100.75 21.913C101.441 21.913 102.033 21.8162 102.527 21.6468L103.515 26.8494Z"
                        fill="#5E5DDE"
                      />
                      <path
                        d="M122.178 5.3371C123.363 5.77266 124.203 6.30502 124.672 6.90998L124.573 5.62747V5.2161H130.72V27.3817H124.721V26.5589L124.869 25.228C124.4 25.8814 123.586 26.3895 122.401 26.7767C121.24 27.1639 120.031 27.3817 118.821 27.3817C116.5 27.3817 114.501 26.8735 112.773 25.8814C111.044 24.8892 109.711 23.5341 108.773 21.7919C107.81 20.0738 107.341 18.1379 107.341 15.9843C107.341 13.6855 107.835 11.677 108.798 9.98317C109.761 8.2651 111.119 6.95837 112.871 6.03884C114.624 5.11931 116.599 4.65955 118.821 4.65955C119.858 4.68374 120.993 4.90153 122.178 5.3371ZM122.006 21.2595C122.87 20.7755 123.536 20.098 124.055 19.1785C124.549 18.259 124.795 17.1942 124.795 16.0085C124.795 14.7744 124.549 13.7339 124.055 12.8385C123.561 11.9432 122.87 11.2657 122.006 10.8059C121.142 10.3219 120.204 10.1042 119.167 10.1042C118.179 10.1042 117.241 10.3219 116.352 10.7817C115.488 11.2415 114.772 11.919 114.254 12.7901C113.711 13.6855 113.464 14.7502 113.464 16.0085C113.464 17.2668 113.735 18.3315 114.254 19.2511C114.772 20.1464 115.488 20.8239 116.352 21.2837C117.216 21.7435 118.154 21.9613 119.167 21.9613C120.204 21.9855 121.142 21.7435 122.006 21.2595Z"
                        fill="#5E5DDE"
                      />
                      <path
                        d="M142.669 16.4683L134.571 5.82111H141.41L146.273 12.7176L151.137 5.82111H157.975L149.828 16.4683L157.975 27.3817H151.038L146.273 20.6788L141.459 27.3817H134.522L142.669 16.4683Z"
                        fill="#5E5DDE"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_752_472">
                        <rect
                          width="158"
                          height="26.86"
                          fill="white"
                          transform="translate(0 0.570068)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </h5>
              <div className="w-full flex items-center flex-col gap-1">
                <div className="w-full flex items-start gap-4 sm:gap-6">
                  <Field
                    name="first_name"
                    label={t("forms.firstName")}
                    control={control}
                    errors={errors}
                    isRequired
                  />
                  <Field
                    label={t("forms.lastName")}
                    name="last_name"
                    control={control}
                    errors={errors}
                    isRequired
                  />
                </div>
                <Field
                  label={t("forms.activityName")}
                  name="business_name"
                  control={control}
                  errors={errors}
                  isRequired
                />
                <Field
                  type="number"
                  label={t("forms.phone")}
                  name="phone"
                  control={control}
                  errors={errors}
                  isRequired
                  float="+966"
                  placeholder="553559038"
                />
                <Field
                  type="email"
                  label={t("forms.email")}
                  name="email"
                  control={control}
                  errors={errors}
                  isRequired
                />
                <Field
                  label={t("forms.city")}
                  name="city"
                  control={control}
                  errors={errors}
                  isRequired
                />
                <Field
                  type="number"
                  label={t("forms.branches")}
                  name="branches_no"
                  control={control}
                  errors={errors}
                  isRequired
                />
                <div className="mt-1">
                  <Field.CheckBox
                    control={control}
                    errors={errors}
                    name="terms_conditions"
                    isRequired
                  >
                    {t("forms.termsText")}
                    <Link className="underline" to="/privacies">
                      {" "}
                      {t("forms.terms")}
                    </Link>{" "}
                    {t("forms.termsApproved")}
                  </Field.CheckBox>
                </div>

                <Field.Button
                  onClick={handleSubmit(handleSubmission)}
                  isLoading={isLoading}
                >
                  {t("forms.sendData")}
                </Field.Button>
              </div>
            </div>
            <div className="flex-1 w-full h-full hidden lg:block relative">
              <div className="bg-[#5E5DDE] text-base font-medium text-white px-4 py-4 rounded-[30px] rounded-bl-none absolute right-14 top-24">
                {t("bePart.access")}
              </div>
              <div className="bg-[#5E5DDE] text-base font-medium text-white px-5 py-3 rounded-[30px] rounded-br-none absolute left-14 top-64">
                {t("bePart.reduce")}
              </div>
              <img
                loading="lazy"
                src={bePartForm}
                alt="form-image"
                className="object-cover w-full h-[972px] xl:h-[954px]"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="max-w-[800px] mx-auto">
            <h5 className="text-center text-[#131313] text-4xl mb-3 md:mb-7 font-medium">
              {t("bePart.faqs")}
            </h5>
            <div className="flex flex-col">
              <div
                onClick={() => onShowFaqs(1)}
                className={`text-[#131313] border-[#EAECF0] border-b w-full cursor-pointer select-none pt-6 pb-8 `}
              >
                <div className="w-full h-full flex justify-between items-start gap-3">
                  <span className="sm:text-lg font-medium block text-[#131313]">
                    {t("bePart.qOne")}
                  </span>
                  <button
                    aria-label={showFaqs[1] ? "Collapse FAQ" : "Expand FAQ"}
                    className="flex justify-center items-center w-8 sm:mt-1"
                  >
                    {showFaqs[1] ? (
                      <div className="w-6 h-6 border-[#5E5DDE] border-[2px] rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="100%"
                          height="100%"
                          fill="#5E5DDE"
                        >
                          <path d="M19 13H5V11H19V13Z" />
                        </svg>
                      </div>
                    ) : (
                      <div className="w-6 h-6 border-[#5E5DDE] border-[2px] rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="100%"
                          height="100%"
                          fill="#5E5DDE"
                        >
                          <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                        </svg>
                      </div>
                    )}
                  </button>
                </div>
                <div
                  className={`${
                    showFaqs[1] ? "block" : "hidden"
                  } sm:text-lg text-[#62646C] font-medium max-w-[700px] mt-1 pe-5 sm:pe-0 `}
                >
                  {t("bePart.aOne")}
                </div>
              </div>
              <div
                onClick={() => onShowFaqs(2)}
                className={`text-[#131313] border-[#EAECF0] border-b w-full cursor-pointer select-none pt-6 pb-8 `}
              >
                <div className="w-full h-full flex justify-between items-start gap-3">
                  <span className="sm:text-lg font-medium block text-[#131313]">
                    {t("bePart.qTwo")}
                  </span>
                  <button
                    aria-label={showFaqs[2] ? "Collapse FAQ" : "Expand FAQ"}
                    className="flex justify-center items-center w-8 sm:mt-1"
                  >
                    {showFaqs[2] ? (
                      <div className="w-6 h-6 border-[#5E5DDE] border-[2px] rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="100%"
                          height="100%"
                          fill="#5E5DDE"
                        >
                          <path d="M19 13H5V11H19V13Z" />
                        </svg>
                      </div>
                    ) : (
                      <div className="w-6 h-6 border-[#5E5DDE] border-[2px] rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="100%"
                          height="100%"
                          fill="#5E5DDE"
                        >
                          <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                        </svg>
                      </div>
                    )}
                  </button>
                </div>
                <div
                  className={`${
                    showFaqs[2] ? "block" : "hidden"
                  } sm:text-lg text-[#62646C] font-medium max-w-[700px] mt-1 pe-5 sm:pe-0 `}
                >
                  {t("bePart.aTwo")}
                </div>
              </div>
              <div
                onClick={() => onShowFaqs(3)}
                className={`text-[#131313] border-[#EAECF0] border-b w-full cursor-pointer select-none pt-6 pb-8 `}
              >
                <div className="w-full h-full flex justify-between items-start gap-3">
                  <span className="sm:text-lg font-medium block text-[#131313]">
                    {t("bePart.qThree")}
                  </span>
                  <button
                    aria-label={showFaqs[3] ? "Collapse FAQ" : "Expand FAQ"}
                    className="flex justify-center items-center w-8 sm:mt-1"
                  >
                    {showFaqs[3] ? (
                      <div className="w-6 h-6 border-[#5E5DDE] border-[2px] rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="100%"
                          height="100%"
                          fill="#5E5DDE"
                        >
                          <path d="M19 13H5V11H19V13Z" />
                        </svg>
                      </div>
                    ) : (
                      <div className="w-6 h-6 border-[#5E5DDE] border-[2px] rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="100%"
                          height="100%"
                          fill="#5E5DDE"
                        >
                          <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                        </svg>
                      </div>
                    )}
                  </button>
                </div>
                <div
                  className={`${
                    showFaqs[3] ? "block" : "hidden"
                  } sm:text-lg text-[#62646C] font-medium max-w-[700px] mt-1 pe-5 sm:pe-0 `}
                >
                  {t("bePart.aThree")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="mx-auto max-w-[1180px] bg-[#F9FAFB] py-8 px-2 border-[2px] border-[#E2E9FD] rounded-2xl">
            <div className="flex items-center flex-col gap-6">
              <div>
                <img src={avatars} alt="avatars" className="w-[120px]" />
              </div>
              <div className="flex flex-col gap-2 items-center">
                <h5 className="text-[#101828] text-xl font-medium">
                  {t("bePart.questions")}
                </h5>
                <p className="max-w-[768px] text-[#62646C] text-sm sm:text-lg text-center">
                  {t("bePart.help")}
                </p>
              </div>
              <Link to="/blogs#inquire">
                <button className="border rounded-full border-[#7F56D9] bg-[#5E5DDE] h-[42px] w-[174px] text-base font-medium text-white">
                  <span className="block -mb-1 sm:mb-0">
                    {t("bePart.inquire")}
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BePart;
