import {
  skilltaxGroup,
  homeArrow,
  homeArrows,
  todBill,
  menuBill,
  witterBill,
  especiallyBill,
  circle,
} from "../../assets";
import { useTranslation } from "react-i18next";

const HeroSection = () => {
  const { i18n, t } = useTranslation();

  return (
    <section className="container text-center pt-12 xl:pt-24">
      <h1 className="text-[#131313] font-medium">
        <p className="main-heading">
          <span>{t("home.mainTitle")}</span>
          <span className="relative overflow-hidden z-[-1] w-fit">
            <span> {t("home.best")}</span>
            <span
              className={`${
                i18n.language == "ar"
                  ? "left-[-12px]  xl:left-[-20px] -top-[5px] sm:top-[5px] xl:top-5"
                  : "left-0 top-2 sm:top-3 lg:top-5"
              } absolute z-[-1] `}
            >
              <svg
                className="max-w-full w-full"
                viewBox="0 0 224 91"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="animate-draw"
                  d="M163.516 13.41C163.516 13.41 96.516 2.15257 51.1558 18.132C50.0711 18.5142 49.0273 18.889 48.023 19.2565C30.194 25.7811 9.30817 36.7762 3.3045 54.7873V54.7873V54.7873C-5.95676 82.5711 45.7916 90.1048 75.0338 88.4896C89.4079 87.6956 108.413 85.8817 133.516 82.4101C169.127 77.4852 190.707 67.1478 201.35 60.6098C207.051 57.1079 211.902 52.3644 215.379 46.6486C219.101 40.5296 222.787 32.0356 220.29 25.0559C215.162 10.7186 197.736 9.23247 185.711 7.03939C168.585 3.91597 147.016 -0.0899963 89.516 3.40996"
                  stroke="#D8FF00"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
              </svg>
            </span>
          </span>
        </p>
        <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-[55px] mt-2 md:mt-4 xl:mt-8">
          {t("home.manage")}
          <span className="text-[#6C74E8]"> {t("home.restaurants")} </span>
          <> {t("home.and")} </>
          <span className="text-[#6C74E8]"> {t("home.Cafes")} </span>
        </p>
      </h1>
      <div className="relative my-7 xl:mb-8 xl:mt-11">
        <a href="#services">
          <button className="bg-white hover:scale-90 transition-all text-[#5867C2] rounded-[10px] h-[42px] w-[132px] font-bold text-xs xl:text-sm shadow-[0px_20px_60px_0px_#92A1F9]">
            <span className="-mb-1 sm:mb-0 block">{t("home.explore")}</span>
          </button>
        </a>
        <div className="hidden xl:block absolute left-[330px] top-[-16px]">
          <img loading="lazy" src={homeArrow} alt="arrow" />
        </div>
      </div>
      <div className="w-full flex justify-center rounded-[20px]">
        <img
          loading="lazy"
          className="w-full max-w-[992px] rounded-[20px] object-cover h-[204px] sm:h-60 md:h-80 lg:h-96 xl:h-[426px]"
          src={skilltaxGroup}
          alt="skilltax-group"
        />
      </div>
      <div className="animate-bounce	 flex justify-center my-7 xl:my-8">
        <img loading="lazy" src={homeArrows} alt="arrow-bottom" />
      </div>
      <div
        id="services"
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-center gap-[10px] py-7 lg:py-10 "
      >
        <div className="bg-white rounded-[10px] py-7 px-8 flex items-start flex-col justify-between flex-1 gap-6 relative">
          <img
            loading="lazy"
            src={circle}
            alt="icon-circle"
            className="hidden md:block absolute h-20 top-[-40px] z-[-1]"
          />
          <img loading="lazy" src={todBill} alt="bill-icon" className="w-8" />
          <p className="text-2xl font-medium text-[#222222]">
            {t("home.loyalty")}
          </p>
        </div>
        <div className="bg-white rounded-[10px] py-7 px-8 flex items-start flex-col justify-between flex-1 gap-6 shadow-[0px_30px_55px_0px_#686BFF33]">
          <img loading="lazy" src={menuBill} alt="bill-icon" className="w-8" />
          <p className="text-2xl font-medium text-[#222222]">
            {t("home.menu")}
          </p>
        </div>
        <div className="bg-white rounded-[10px] py-7 px-8 flex items-start flex-col justify-between flex-1 gap-6">
          <img
            loading="lazy"
            src={witterBill}
            alt="bill-icon"
            className="w-8"
          />
          <p className="text-2xl font-medium text-[#222222]">
            {t("home.witter")}
          </p>
        </div>
        <div className="bg-white rounded-[10px] py-7 px-8 flex items-start flex-col justify-between flex-1 gap-6 ">
          <img
            loading="lazy"
            src={especiallyBill}
            alt="bill-icon"
            className="w-8"
          />
          <p className="text-2xl font-medium text-[#222222]">
            {t("home.services")}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-2 md:gap-3 lg:gap-5 mt-6">
        <p className="text-xl md:text-2xl lg:text-3xl font-medium text-[#222222]">
          {t("home.enjoy")}
        </p>
        <p className="text-base md:text-lg lg:text-xl font-medium text-[#222222]">
          {t("home.organization")}
        </p>
      </div>
    </section>
  );
};

export default HeroSection;
