import { useContext, useEffect, useState } from "react";
import { faqsLogo, blog } from "../../assets";
import { Link, useLocation } from "react-router-dom";
import LogoLoading from "../../components/Layouts/LogoLoading";
import { useTranslation } from "react-i18next";
import Field from "../../components/Layouts/Field";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { AppContext } from "../../context/AppContext";
import EngineSeo from "../../Engine/EngineSeo";

const SEO_BLOG = {
  en: {
    title:
      "SkillTax Blog | Insights and Updates on POS Systems and Restaurant Management",
    description:
      "Welcome to the SkillTax Blog, where we share insights, updates, and expert tips on POS systems and restaurant management. Stay informed with our latest articles and industry news to enhance your business operations.",
    keywords:
      "SkillTax Blog, POS System Insights, Restaurant Management Tips, POS Updates, SkillTax Articles, Industry News, Business Operations Improvement, Restaurant Technology",
  },
  ar: {
    title: "مدونة سكيل تاكس | رؤى وتحديثات حول أنظمة نقاط البيع وإدارة المطاعم",
    description:
      "مرحبًا بك في مدونة سكيل تاكس، حيث نشارك الرؤى والتحديثات والنصائح المتخصصة حول أنظمة نقاط البيع وإدارة المطاعم. تابع أحدث مقالاتنا وأخبار الصناعة لتحسين عمليات عملك.",
    keywords:
      "مدونة سكيل تاكس, رؤى نظام نقاط البيع, نصائح إدارة المطاعم, تحديثات نقاط البيع, مقالات سكيل تاكس, أخبار الصناعة, تحسين عمليات العمل, تكنولوجيا المطاعم",
  },
};

const OurBlogs = () => {
  const { i18n, t } = useTranslation();
  const { blogDepartmentList, blogDepartmentListIsLoading } =
    useContext(AppContext);

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .required(t("forms.phoneReq"))
      .matches(
        /^\d{9}$/,
        i18n.language == "ar"
          ? "يجب أن يتكون رقم الهاتف من 9 أرقام"
          : "The phone number must consist of 9 digits"
      ),
    email: Yup.string()
      .required(t("forms.emailReq"))
      .email(t("forms.emailReq"))
      .typeError(t("forms.emailReq")),
    message: Yup.string().required(t("forms.msgReq")),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      phone: "",
      email: "",
      message: "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmission = async (values) => {
    try {
      setIsLoading(true);
      const response = await fetch(`https://dash.wajen.net/api/v2/inquiries`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        toast.error(t("forms.err"));
        return;
      }
      toast.success(t("forms.send"));
      reset({
        phone: "",
        email: "",
        message: "",
      });
    } catch (error) {
      toast.error(t("forms.err"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.hash == "#inquire") {
      const element = document.getElementById("inquire");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EngineSeo {...SEO_BLOG} />
      {blogDepartmentListIsLoading ? (
        <div className="w-full h-[60vh] flex items-center justify-center">
          <LogoLoading />
        </div>
      ) : (
        <>
          <section className="banner-container">
            <div className="w-full h-60 sm:h-64 lg:h-[350px] bg-[#5E5DDE] rounded-b-[20px] lg:rounded-[50px] px-4 py-8 lg:px-12 lg:py-4 relative lg:flex items-center">
              <div className="relative z-10">
                <h2 className="text-white font-medium text-5xl text-center lg:text-start">
                  {t("blogs.news")}
                </h2>
                <p className="text-xl text-[#D2D7FF] font-medium text-center lg:text-start lg:max-w-[500px] mt-4 lg:mt-2.5">
                  {t("blogs.newsText")}
                </p>
              </div>
              <div className="absolute w-full left-0 bottom-0 lg:left-4 z-0 hidden lg:block">
                <img loading="lazy" src={faqsLogo} alt="faqs-logo" />
              </div>
            </div>
          </section>
          <section className="mt-16 md:mt-28 w-full lg:w-[66%] mx-auto">
            <div className="container">
              <div className="grid sm:grid-cols-2 gap-5">
                {blogDepartmentList?.map(
                  ({ id, ar_department, en_department, image }) => {
                    return (
                      <Link
                        onClick={handleLinkClick}
                        key={id}
                        to={`/blogs/${id}`}
                      >
                        <div className="group p-3 w-full h-[200px] sm:h-[250px] border border-[#AABBF7] rounded-2xl overflow-hidden flex flex-col justify-between cursor-pointer transition-all duration-[400ms] hover:shadow-[0px_36.54px_53.15px_0px_#4646461A]">
                          <div className="relative rounded-2xl overflow-hidden">
                            <img
                              src={`https://dash.wajen.net${image}`}
                              alt={en_department}
                              loading="lazy"
                              className="w-full sm:h-[190px] object-cover rounded-2xl"
                            />
                            <div className="card-gradient absolute left-0 bottom-0 w-full h-[66%]">
                              <div className="absolute px-2 bottom-3 flex justify-between items-center w-full text-base font-medium text-white capitalize">
                                {i18n.language == "ar"
                                  ? ar_department
                                  : en_department}
                                <p className="block sm:hidden">
                                  <svg
                                    className={`${
                                      i18n.language == "ar"
                                        ? "rotate-90"
                                        : "rotate-[270deg]"
                                    }text-white transition-all `}
                                    width="14"
                                    height="14"
                                    viewBox="0 0 13 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12.2361 1.2204L1.43945 12.017M1.43945 12.017L11.8042 12.017M1.43945 12.017L1.43945 1.65226"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="hidden sm:flex justify-between items-center">
                            <h3 className="text-base font-medium text-[#131313] capitalize">
                              {i18n.language == "ar"
                                ? ar_department
                                : en_department}
                            </h3>
                            <p>
                              <svg
                                className={`${
                                  i18n.language == "ar"
                                    ? "group-hover:rotate-90"
                                    : "group-hover:rotate-180 rotate-[270deg]"
                                } text-[#222222] group-hover:text-[#6365C0] transition-all duration-[400ms]`}
                                width="14"
                                height="14"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.2361 1.2204L1.43945 12.017M1.43945 12.017L11.8042 12.017M1.43945 12.017L1.43945 1.65226"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                  }
                )}
              </div>
            </div>
          </section>
          <section id="inquire" className="pt-16 md:pt-36">
            <div className="container relative mb-6 md:mb-10">
              <h4 className="text-black font-medium text-4xl text-center">
                {t("blogs.inquiries")}
              </h4>
              <div className="hidden lg:block absolute left-0 -top-2 w-full z-[-1]">
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 158 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_752_472)">
                    <path
                      d="M5.20906 26.5106C3.82656 25.9299 2.69094 25.1555 1.80218 24.1634C0.913435 23.1955 0.320937 22.1066 0 20.9451L4.41906 19.106C4.81406 20.2917 5.50531 21.2596 6.49281 21.9372C7.45562 22.6389 8.59125 22.9777 9.875 22.9777C11.1834 22.9777 12.1956 22.7357 12.8869 22.2276C13.5781 21.7436 13.9237 20.9934 13.9237 20.0013C13.9237 18.9366 13.5287 18.0655 12.7634 17.3879C11.9734 16.6862 10.665 16.0328 8.78875 15.3553C6.02375 14.3147 3.99937 13.2016 2.765 11.9675C1.53063 10.7334 0.888745 9.20891 0.888745 7.36985C0.888745 6.08734 1.23437 4.92583 1.92562 3.90951C2.61687 2.89319 3.60437 2.09466 4.91281 1.5139C6.19656 0.93314 7.72719 0.642761 9.50469 0.642761C11.5537 0.642761 13.3312 1.00574 14.8125 1.70749C16.2937 2.43343 17.5034 3.49816 18.3922 4.97425L14.4669 7.36985C13.8744 6.54711 13.1584 5.94218 12.3684 5.55501C11.5537 5.16784 10.6403 4.97425 9.57875 4.97425C8.44312 4.97425 7.57906 5.19203 6.93719 5.6518C6.29531 6.08737 5.97437 6.71652 5.97437 7.51506C5.97437 8.3378 6.39406 9.06372 7.23344 9.66868C8.07281 10.2736 9.38125 10.927 11.1587 11.6045C13.8991 12.6451 15.9234 13.8308 17.2566 15.2101C18.5897 16.5894 19.2562 18.2349 19.2562 20.1465C19.2562 21.4774 18.8859 22.6873 18.1206 23.8004C17.3553 24.8893 16.2691 25.7605 14.8619 26.4138C13.43 27.0672 11.7512 27.3818 9.82562 27.3818C8.14687 27.406 6.59156 27.0914 5.20906 26.5106Z"
                      fill="#37387308"
                    />
                    <path
                      d="M29.7731 0.691162V27.406H24.0703V0.691162H29.7731ZM38.4384 5.82118H45.3756L37.8706 16.1054L45.9681 27.406H39.0309L31.3531 16.1054L38.4384 5.82118Z"
                      fill="#37387308"
                    />
                    <path
                      d="M49.7208 0.691162V7.03109H56.3123V0.691162H49.7208ZM49.7208 10.685L45.9683 15.815H50.4614V27.3818H56.3123V10.685H49.7208Z"
                      fill="#37387308"
                    />
                    <path
                      d="M64.3109 26.3412C63.2741 25.6395 62.4841 24.6957 61.9409 23.4858C61.3978 22.3001 61.1509 20.9692 61.1509 19.4931L61.1016 10.2252C61.1016 6.6439 61.1016 3.76429 61.1016 1.61066C61.1016 -0.542979 61.1016 4.19986 61.1016 0.666931H67.1006C67.1006 8.87011 67.1006 9.76545 67.1006 18.5252C67.1006 19.2511 67.1747 19.8803 67.3228 20.3643C67.4709 20.8482 67.6931 21.2354 68.0387 21.5258C68.3844 21.8161 68.8041 21.9614 69.2978 21.9614C69.915 21.9614 70.5075 21.8887 71.0753 21.7436L72.0134 26.8494C71.0753 27.2123 69.8162 27.4059 68.2116 27.4059C66.6562 27.4059 65.3478 27.043 64.3109 26.3412Z"
                      fill="#37387308"
                    />
                    <path
                      d="M79.9877 26.3412C78.9508 25.6395 78.1608 24.6957 77.6177 23.4858C77.0746 22.3001 76.8277 20.9692 76.8277 19.4931L76.7783 10.2252C76.7783 6.6439 76.7783 3.76429 76.7783 1.61066C76.7783 -0.542979 76.7783 4.19986 76.7783 0.666931H82.7774C82.7774 8.87011 82.7774 9.76545 82.7774 18.5252C82.7774 19.2511 82.8514 19.8803 82.9996 20.3643C83.1477 20.8482 83.3699 21.2354 83.7155 21.5258C84.0611 21.8161 84.4808 21.9614 84.9746 21.9614C85.5918 21.9614 86.1843 21.8887 86.7521 21.7436L87.6902 26.8494C86.7521 27.2123 85.493 27.4059 83.8883 27.4059C82.333 27.4059 81.0246 27.043 79.9877 26.3412Z"
                      fill="#37387308"
                    />
                    <path
                      d="M103.515 26.8494C102.675 27.2123 101.367 27.4059 99.6388 27.4059C97.4416 27.4059 95.7135 26.7284 94.4297 25.3733C93.146 24.0182 92.5041 22.2759 92.4547 20.1707L92.4053 16.4442C92.4053 15.1859 92.4053 14.1695 92.43 13.371C92.4547 12.5724 92.4547 11.5561 92.4547 10.3462H88.6528L92.4053 5.2162V0.715332H98.5031V5.1678H103.515V10.2978H98.5031V18.5252C98.5278 19.5899 98.7253 20.4127 99.0956 20.9934C99.466 21.5742 100.009 21.8888 100.75 21.913C101.441 21.913 102.033 21.8162 102.527 21.6468L103.515 26.8494Z"
                      fill="#37387308"
                    />
                    <path
                      d="M122.178 5.3371C123.363 5.77266 124.203 6.30502 124.672 6.90998L124.573 5.62747V5.2161H130.72V27.3817H124.721V26.5589L124.869 25.228C124.4 25.8814 123.586 26.3895 122.401 26.7767C121.24 27.1639 120.031 27.3817 118.821 27.3817C116.5 27.3817 114.501 26.8735 112.773 25.8814C111.044 24.8892 109.711 23.5341 108.773 21.7919C107.81 20.0738 107.341 18.1379 107.341 15.9843C107.341 13.6855 107.835 11.677 108.798 9.98317C109.761 8.2651 111.119 6.95837 112.871 6.03884C114.624 5.11931 116.599 4.65955 118.821 4.65955C119.858 4.68374 120.993 4.90153 122.178 5.3371ZM122.006 21.2595C122.87 20.7755 123.536 20.098 124.055 19.1785C124.549 18.259 124.795 17.1942 124.795 16.0085C124.795 14.7744 124.549 13.7339 124.055 12.8385C123.561 11.9432 122.87 11.2657 122.006 10.8059C121.142 10.3219 120.204 10.1042 119.167 10.1042C118.179 10.1042 117.241 10.3219 116.352 10.7817C115.488 11.2415 114.772 11.919 114.254 12.7901C113.711 13.6855 113.464 14.7502 113.464 16.0085C113.464 17.2668 113.735 18.3315 114.254 19.2511C114.772 20.1464 115.488 20.8239 116.352 21.2837C117.216 21.7435 118.154 21.9613 119.167 21.9613C120.204 21.9855 121.142 21.7435 122.006 21.2595Z"
                      fill="#37387308"
                    />
                    <path
                      d="M142.669 16.4683L134.571 5.82111H141.41L146.273 12.7176L151.137 5.82111H157.975L149.828 16.4683L157.975 27.3817H151.038L146.273 20.6788L141.459 27.3817H134.522L142.669 16.4683Z"
                      fill="#37387308"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_752_472">
                      <rect
                        width="158"
                        height="26.86"
                        fill="white"
                        transform="translate(0 0.570068)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="w-full lg:w-[66%] mx-auto">
              <div className="container">
                <div className="w-full mx-auto bg-white shadow-[0px_44px_54px_0px_#90909026] p-5 rounded-[20px]">
                  <div className="w-full h-[525px] flex gap-5 lg:gap-7">
                    <div className="w-full flex items-center flex-col gap-1">
                      <Field
                        type="number"
                        label={t("forms.phone")}
                        name="phone"
                        control={control}
                        errors={errors}
                        isRequired
                        float="+966"
                        placeholder="553559038"
                      />
                      <Field
                        type="email"
                        label={t("forms.email")}
                        name="email"
                        control={control}
                        errors={errors}
                        isRequired
                      />
                      <Field
                        type="textarea"
                        label={t("forms.inquirie")}
                        name="message"
                        control={control}
                        errors={errors}
                        isRequired
                        rows={7}
                      />
                      <Field.Button
                        onClick={handleSubmit(handleSubmission)}
                        isLoading={isLoading}
                      >
                        {t("forms.sendData")}
                      </Field.Button>
                    </div>
                    <div className="hidden lg:block lg:w-[40%] xl:w-[30%] relative flex-shrink-0">
                      <div className="inquirie-gradient w-full h-[40%] bg-green-400 left-0 bottom-0 absolute flex items-end justify-end px-2 py-5 rounded-b-[20px]">
                        <p className="underline text-[#EFF4FE] font-medium text-center">
                          {t("blogs.contactUs")}
                        </p>
                      </div>
                      <img
                        className="object-cover w-full h-full rounded-[20px]"
                        alt="form-image"
                        loading="lazy"
                        src={blog}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default OurBlogs;
