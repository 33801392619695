import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Root from "./Root";
import Page404 from "./pages/404";
import AboutUs from "./pages/AboutUs";
import RestaurantSector from "./pages/sectors/RestaurantSector";
import CafesSector from "./pages/sectors/CafesSector";
import FoodTruckSector from "./pages/sectors/FoodTruckSector";
import Connectivity from "./pages/sectors/Connectivity";
import Packages from "./pages/Packages";
import Privacies from "./pages/Privacies";
import Faqs from "./pages/Faqs";
import CustomerJourney from "./pages/CustomerJourney";
import Menu from "./pages/services/Menu";
import Tod from "./pages/services/Tod";
import Special from "./pages/services/Special";
import Waiter from "./pages/services/Waiter";
import Ordering from "./pages/services/Ordering";
import Tables from "./pages/services/Tables";
import BePart from "./pages/BePart";
import Referrals from "./pages/Referrals";
import Contact from "./pages/Contact";
import OurBlogs from "./pages/blogs/index";
import Blogs from "./pages/blogs/Blogs";
import Blog from "./pages/blogs/Blog";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Page404 />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "about-us",
        element: <AboutUs />,
      },
      {
        path: "sectors/restaurant-sector",
        element: <RestaurantSector />,
      },
      {
        path: "sectors/cafes-sector",
        element: <CafesSector />,
      },
      {
        path: "sectors/foodtruck-sector",
        element: <FoodTruckSector />,
      },
      {
        path: "sectors/connectivity-sector",
        element: <Connectivity />,
      },
      {
        path: "packages",
        element: <Packages />,
      },
      {
        path: "privacies",
        element: <Privacies />,
      },
      {
        path: "faqs",
        element: <Faqs />,
      },
      {
        path: "customer-journey",
        element: <CustomerJourney />,
      },
      {
        path: "services/menu",
        element: <Menu />,
      },
      {
        path: "services/tod",
        element: <Tod />,
      },
      {
        path: "services/waiter",
        element: <Waiter />,
      },
      {
        path: "services/special",
        element: <Special />,
      },

      {
        path: "services/restaurant-ordering",
        element: <Ordering />,
      },
      {
        path: "services/booking-tables",
        element: <Tables />,
      },
      {
        path: "be-part",
        element: <BePart />,
      },
      {
        path: "referrals",
        element: <Referrals />,
      },
      {
        path: "contact-us",
        element: <Contact />,
      },
      {
        path: "blogs",
        element: <OurBlogs />,
      },
      {
        path: "blogs/:id",
        element: <Blogs />,
      },
      {
        path: "blog/:id",
        element: <Blog />,
      },
    ],
  },
]);

export default router;
