import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { SocialIcons } from "./Footer";
import { contactUsImage } from "../../assets";
import Field from "./Field";
import { AppContext } from "../../context/AppContext";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { i18n, t } = useTranslation();
  const { cities } = useContext(AppContext);

  // Form Validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("forms.nameReq")),
    activity_type: Yup.string().required(t("forms.typeReq")),
    phone: Yup.string()
      .required(t("forms.phoneReq"))
      .matches(
        /^\d{10}$/,
        i18n.language == "ar"
          ? "يجب أن يتكون رقم الهاتف من 10 أرقام"
          : "The phone number must consist of 10 digits"
      ),
    email: Yup.string()
      .email(t("forms.emailReq"))
      .typeError(t("forms.emailReq"))
      .nullable(),
    city_id: Yup.string()
      .required(t("forms.cityReq"))
      .typeError(t("forms.cityReq")),
    activity_name: Yup.string().required(t("forms.msgReq")),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      activity_type: "",
      phone: "",
      email: "",
      city_id: cities[0]?.value || "",
      activity_name: "",
    },
  });

  useEffect(() => {
    if (cities[0]) {
      setValue("city_id", cities[0]?.value);
    }
  }, [cities, setValue]);

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmission = async (values) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://backend.skilltax.sa/api/v1/questions`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        toast.error(t("forms.err"));
        return;
      }
      toast.success(t("forms.send"));
      reset({
        name: "",
        activity_type: "",
        phone: "",
        email: "",
        city_id: cities[0]?.value || "",
        activity_name: "",
      });
    } catch (error) {
      toast.error(t("forms.err"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="w-full h-full flex items-center flex-col-reverse md:flex-row">
            <div
              className={`${
                i18n.language == "ar"
                  ? "rounded-3xl md:rounded-r-3xl md:rounded-none"
                  : "rounded-3xl md:rounded-l-3xl md:rounded-none"
              } flex-1 md:bg-white w-full h-full py-8 md:px-8`}
            >
              <div className="w-full flex items-center flex-col gap-1">
                <Field
                  name="name"
                  label={t("forms.name")}
                  control={control}
                  errors={errors}
                  isRequired
                />
                <Field
                  label={t("forms.type")}
                  name="activity_type"
                  control={control}
                  errors={errors}
                  isRequired
                />
                <Field
                  type="number"
                  label={t("forms.phone")}
                  name="phone"
                  control={control}
                  errors={errors}
                  isRequired
                  //   float="+966"
                  // placeholder="553559038"
                />
                <Field
                  type="email"
                  label={t("forms.email")}
                  name="email"
                  control={control}
                  errors={errors}
                />
                <Field
                  type="select"
                  options={cities}
                  label={t("forms.city")}
                  name="city_id"
                  control={control}
                  errors={errors}
                  isRequired
                />
                <Field
                  type="textarea"
                  label={t("forms.msg")}
                  name="activity_name"
                  control={control}
                  errors={errors}
                  isRequired
                />

                <Field.Button
                  onClick={handleSubmit(handleSubmission)}
                  isLoading={isLoading}
                >
                  {t("links.subscribe")}
                </Field.Button>
              </div>
            </div>
            <div className="flex-1 w-full">
              <div className="w-full md:w-fit relative">
                <div
                  className={`${
                    i18n.language == "ar"
                      ? "rounded-3xl md:rounded-l-3xl md:rounded-none"
                      : "rounded-3xl md:rounded-r-3xl md:rounded-none"
                  } w-full h-full absolute left-0 top-0 z-10 bg-gradient-to-b from-transparent to-[#010101]`}
                ></div>
                <div className="absolute bottom-4 md:bottom-6 left-[50%] translate-x-[-50%] z-20">
                  <SocialIcons />
                </div>
                <div className="absolute top-4 left-[50%] translate-x-[-50%] z-20">
                  <div className="text-white font-bold text-2xl lg:text-3xl">
                    {t("links.contactUs")}
                  </div>
                </div>
                <img
                  loading="lazy"
                  className={`${
                    i18n.language == "ar"
                      ? "rounded-3xl md:rounded-l-3xl md:rounded-none"
                      : "rounded-3xl md:rounded-r-3xl md:rounded-none"
                  } max-w-full  h-32 w-full md:h-[861.23px] object-cover`}
                  src={contactUsImage}
                  alt="contact-us"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
