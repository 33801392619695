import { useEffect } from "react";
import {
  menuOne,
  todTwo,
  behaviortod,
  clientstod,
  dedicationstod,
} from "../../assets";
import Points from "../../components/Home/Points";
import EngineSeo from "../../Engine/EngineSeo";
import { useTranslation } from "react-i18next";
const SEO_SERVICES = {
  en: {
    title:
      "SkillTax - Loyalty Program | Enhance Customer Retention with Our POS System",
    description:
      "Explore the SkillTax Loyalty Program page to learn how our POS system's loyalty features can boost customer retention and engagement. Discover the benefits of implementing a loyalty program to reward customers and drive repeat business.",
    keywords:
      "Loyalty Program, SkillTax POS System, Customer Retention Solutions, POS Loyalty Features, SkillTax Services, Reward Program, Customer Engagement, Loyalty Program Benefits",
  },
  ar: {
    title:
      "SkillTax - برنامج الولاء | تعزيز الاحتفاظ بالعملاء من خلال نظام نقاط البيع لدينا",
    description:
      "استكشف صفحة برنامج الولاء الخاصة بسكيل تاكس لتتعرف على كيفية تحسين الاحتفاظ بالعملاء والمشاركة من خلال ميزات الولاء في نظام نقاط البيع لدينا. اكتشف فوائد تنفيذ برنامج ولاء لمكافأة العملاء وتعزيز الأعمال المتكررة.",
    keywords:
      "برنامج الولاء, نظام سكيل تاكس, حلول الاحتفاظ بالعملاء, ميزات الولاء في نقاط البيع, خدمات سكيل تاكس, برنامج المكافآت, مشاركة العملاء, فوائد برنامج الولاء",
  },
};

const Tod = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <EngineSeo {...SEO_SERVICES} />
      <section className="bg-white">
        <div className="flex items-center flex-col-reverse md:flex-row pt-8 p-4 md:p-0">
          <div className="flex-1 w-full flex items-center md:justify-center md:px-4">
            <div className="max-w-[570px] text-start mt-4 md:mt-0">
              <h3 className="text-[40px] leading-[56px] xl:text-[70px] xl:leading-[98px] text-[#131313] font-medium">
                {t("links.todServices")}
              </h3>
              <p className="text-[#62646C] text-xl xl:text-2xl text-start md:mt-2">
                {t("todServices.mainText")}
              </p>
            </div>
          </div>
          <div className="flex-1 flex items-center gap-1 justify-end">
            <div className="rounded-[20px] md:rounded-none overflow-hidden">
              <img
                loading="lazy"
                src={todTwo}
                alt="tod"
                className="w-full object-cover max-w-full"
              />
            </div>
            <div className="hidden sm:block rounded-[20px] md:rounded-none overflow-hidden">
              <img
                loading="lazy"
                src={menuOne}
                alt="menu"
                className="w-full object-cover max-w-full"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex items-center justify-between gap-6 flex-col-reverse md:flex-row">
            <div className="font-medium max-w-[500px]">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("todServices.dedications")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("todServices.dedicationsText")}
              </p>
            </div>
            <div>
              <img loading="lazy" src={dedicationstod} alt="tod" />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col gap-6 md:gap-20 md:flex-row items-center justify-between">
            <div className="max-w-[500px]">
              <img loading="lazy" src={clientstod} alt="clients-tod" />
            </div>
            <div className="w-full lg:w-[370px] xl:w-[512px] font-medium ">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("todServices.clients")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("todServices.clientsText")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex items-center justify-between gap-6 flex-col-reverse md:flex-row">
            <div className="font-medium max-w-[500px]">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("todServices.follow")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("todServices.followText")}
              </p>
              <p className="text-[#62646C] text-xl relative ps-4 mt-2">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("todServices.followTextTwo")}
              </p>
            </div>
            <div>
              <img loading="lazy" src={behaviortod} alt="behavior-tod" />
            </div>
          </div>
        </div>
      </section>
      <Points showShadow />
    </>
  );
};

export default Tod;
