import { useEffect } from "react";
import {
  specialOne,
  tablesTwo,
  easy,
  improvement,
  tablePaymentOne,
  tablePaymentTwo,
  tablePayments,
} from "../../assets";
import Points from "../../components/Home/Points";
import EngineSeo from "../../Engine/EngineSeo";
import { useTranslation } from "react-i18next";
const SEO_SERVICES = {
  en: {
    title:
      "SkillTax - Table Reservation | Efficient Booking Solutions for Restaurants",
    description:
      "Explore the SkillTax Table Reservation service page to see how our POS system supports efficient table booking. Learn about the features that streamline reservation management and enhance customer experience in restaurants.",
    keywords:
      "Table Reservation, SkillTax POS System, Restaurant Booking Solutions, Efficient Table Booking, POS Integration, SkillTax Services, Reservation Management, Restaurant Reservation System",
  },
  ar: {
    title: "SkillTax - حجز الطاولات | حلول حجز فعّالة للمطاعم",
    description:
      "استكشف صفحة خدمة حجز الطاولات الخاصة بسكيل تاكس لتتعرف على كيفية دعم نظامنا لحجوزات الطاولات بكفاءة. تعرف على الميزات التي تبسط إدارة الحجز وتعزز تجربة العملاء في المطاعم.",
    keywords:
      "خدمة حجز الطاولات, نظام سكيل تاكس, حلول حجز المطاعم, حجز الطاولات الفعّال, تكامل نقاط البيع, خدمات سكيل تاكس, إدارة الحجز, نظام حجز الطاولات",
  },
};

const Tables = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EngineSeo {...SEO_SERVICES} />
      <section className="bg-white">
        <div className="flex items-center flex-col-reverse md:flex-row pt-8 p-4 md:p-0">
          <div className="flex-1 w-full flex items-center md:justify-center md:px-4">
            <div className="max-w-[570px] text-start mt-4 md:mt-0">
              <h3 className="text-[40px] leading-[56px] xl:text-[70px] xl:leading-[98px] text-[#131313] font-medium">
                {t("links.bookingServices")}
              </h3>
              <p className="text-[#62646C] text-xl xl:text-2xl text-start md:mt-2">
                {t("bookingServices.mainText")}
              </p>
            </div>
          </div>
          <div className="flex-1 flex items-center gap-1 justify-end">
            <div className="rounded-[20px] md:rounded-none overflow-hidden">
              <img
                loading="lazy"
                src={tablesTwo}
                alt="tables"
                className="w-full object-cover max-w-full"
              />
            </div>
            <div className="hidden sm:block rounded-[20px] md:rounded-none overflow-hidden">
              <img
                loading="lazy"
                src={specialOne}
                alt="special"
                className="w-full object-cover max-w-full"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex items-center justify-between gap-6 flex-col-reverse md:flex-row">
            <div className="font-medium max-w-[500px]">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("bookingServices.booking")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("bookingServices.bookingText")}
              </p>
              <p className="text-[#62646C] text-xl relative ps-4 mt-2">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("bookingServices.bookingTextTwo")}
              </p>
            </div>
            <div>
              <img loading="lazy" src={improvement} alt="improvement" />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col gap-6 md:gap-20 md:flex-row items-center justify-between">
            <div className="max-w-[500px]">
              <img loading="lazy" src={easy} alt="easy" />
            </div>
            <div className="w-full lg:w-[370px] xl:w-[512px] font-medium ">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("bookingServices.ease")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("bookingServices.easeText")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex items-center justify-between gap-6 flex-col-reverse md:flex-row">
            <div className="font-medium max-w-[500px]">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("bookingServices.payment")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("bookingServices.paymentText")}
              </p>
            </div>
            <div className="max-w-[500px] h-fit w-fit bg-white rounded-[10px] p-5 flex gap-3 relative">
              <div>
                <div
                  className={`${
                    i18n.language == "ar"
                      ? "right-[calc(20%+32px)] "
                      : "left-[calc(20%+32px)] "
                  } text-white bg-[#6C74E8] rounded-[10px] lg:w-[80%] py-3 text-xl text-center mb-3 relative`}
                >
                  {t("bookingServices.flexibility")}
                </div>
                <img
                  loading="lazy"
                  src={tablePaymentTwo}
                  alt="tableP-paymentT"
                />
              </div>
              <div>
                <img
                  loading="lazy"
                  src={tablePaymentOne}
                  alt="tableP-paymentT"
                />
                <img
                  loading="lazy"
                  src={tablePayments}
                  alt="payments"
                  className="mt-3 mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Points showShadow />
    </>
  );
};

export default Tables;
