import { useEffect } from "react";
import ContactUs from "../components/Layouts/ContactUs";
import EngineSeo from "../Engine/EngineSeo";
import {
  aboutArrow,
  aboutIPad,
  aboutLogo,
  dash,
  inventory,
  productsPage,
} from "../assets";
import { useTranslation } from "react-i18next";

const SEO_ABOUT_US = {
  en: {
    title: "SkillTax - About Us | The Leading POS System for Restaurants",
    description:
      "Discover SkillTax's About Us page, where we introduce our premier POS system designed to streamline restaurant management and point of sale operations. Learn more about how SkillTax can enhance your business efficiency.",
    keywords:
      "About Us, SkillTax POS System, Restaurant Management Solutions, Point of Sale System, SkillTax Overview, Restaurant POS Software, SkillTax Features",
  },
  ar: {
    title: "SkillTax - عنا | النظام الرائد لإدارة نقاط البيع للمطاعم",
    description:
      "اكتشف صفحة عنا الخاصة بنظام سكيل تاكس، حيث نقدم نظام نقاط البيع الرائد لدينا المصمم لتبسيط إدارة المطاعم وعمليات نقاط البيع. تعرف على كيفية تحسين سكيل تاكس لكفاءة عملك.",
    keywords:
      "عنا, نظام سكيل تاكس, حلول إدارة المطاعم, نظام نقاط البيع, نظرة عامة على سكيل تاكس, برنامج نقاط البيع للمطاعم, ميزات سكيل تاكس",
  },
};

const AboutUs = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EngineSeo {...SEO_ABOUT_US} />
      <div className="w-full h-96 md:h-[500px] bg-[#5E5DDE] relative -z-[1]">
        <img
          loading="lazy"
          src={aboutLogo}
          alt="logo"
          className="absolute w-full sm:w-[80%] left-0 bottom-0"
        />
        <div className="container flex h-full items-center">
          <div className="max-w-[700px]">
            <h1 className="text-[#EFF4FE] font-medium text-[48px] md:text-[70px]">
              {t("aboutUs.about")}
            </h1>
            <p className="mt-2 text-base sm:text-xl text-[#EFF4FE] text-center sm:text-start">
              {t("aboutUs.aboutText")}
            </p>
          </div>
        </div>
      </div>
      <section className="mt-24 lg:mt-32">
        <div className="container">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col lg:flex-row  gap-5">
              <div className="bg-white p-5 rounded-[20px] flex-[1.5]">
                <div className="flex items-center gap-2">
                  <div className="bg-[#CAD6FB] w-10 h-10 rounded-lg flex items-center justify-center">
                    <span className="w-[18px] h-[18px] rounded-full bg-[#5E5DDE] block border-2 border-[#EFF4FE]"></span>
                  </div>
                  <h2 className="text-2xl font-medium text-[#131313]">
                    {t("aboutUs.target")}
                  </h2>
                </div>
                <p className="text-xl text-[#62646C] mt-4">
                  {t("aboutUs.targetText")}
                </p>
              </div>
              <div className="bg-white p-5 rounded-[20px] flex-1">
                <div className="flex items-center gap-2">
                  <div className="bg-[#CAD6FB] w-10 h-10 rounded-lg flex items-center justify-center">
                    <span className="w-[18px] h-[18px] rounded-full bg-[#5E5DDE] block border-2 border-[#EFF4FE]"></span>
                  </div>
                  <h2 className="text-2xl font-medium text-[#131313]">
                    {t("aboutUs.message")}
                  </h2>
                </div>
                <p className="text-xl text-[#62646C] mt-4">
                  {t("aboutUs.messageText")}
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-5">
              <div className="bg-white p-5 rounded-[20px] flex-1">
                <div className="flex items-center gap-2">
                  <div className="bg-[#CAD6FB] w-10 h-10 rounded-lg flex items-center justify-center">
                    <span className="w-[18px] h-[18px] rounded-full bg-[#5E5DDE] block border-2 border-[#EFF4FE]"></span>
                  </div>
                  <h2 className="text-2xl font-medium text-[#131313]">
                    {t("aboutUs.vision")}
                  </h2>
                </div>
                <p className="text-xl text-[#62646C] mt-4">
                  {t("aboutUs.visionText")}
                </p>
              </div>
              <div className="bg-white p-5 rounded-[20px] flex-[2]">
                <div className="flex items-center gap-2">
                  <div className="bg-[#CAD6FB] w-10 h-10 rounded-lg flex items-center justify-center">
                    <span className="w-[18px] h-[18px] rounded-full bg-[#5E5DDE] block border-2 border-[#EFF4FE]"></span>
                  </div>
                  <h2 className="text-2xl font-medium text-[#131313]">
                    {t("aboutUs.values")}
                  </h2>
                </div>
                <div className="text-xl text-[#62646C] mt-4 flex flex-col gap-4">
                  <p className="flex items-start flex-col sm:flex-row gap-5">
                    <span className="flex-1">- {t("aboutUs.valuesOne")}</span>
                    <span className="flex-1">- {t("aboutUs.valuesFour")}</span>
                  </p>
                  <p className="flex items-start flex-col sm:flex-row  gap-5">
                    <span className="flex-1">- {t("aboutUs.valuesTwo")}</span>
                    <span className="flex-1">- {t("aboutUs.valuesFive")}</span>
                  </p>
                  <p className="flex items-start flex-col sm:flex-row  gap-5">
                    <span className="flex-1">- {t("aboutUs.valuesThree")}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex gap-6 justify-between items-center flex-col-reverse lg:flex-row">
            <div className="flex-1">
              <h3 className="text-black text-2xl md:text-[40px] md:leading-[48px] font-medium">
                {t("aboutUs.summary")}
              </h3>
              <p className="text-base max-w-[355px] mt-2 md:mt-5 text-black font-normal">
                {t("aboutUs.summaryText")}
              </p>
            </div>
            <div className="flex-1 flex justify-end">
              <img
                loading="lazy"
                src={dash}
                alt="dash"
                className="max-w-full w-[480px]"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex gap-6 justify-between items-center flex-col lg:flex-row">
            <div className="flex-1 flex justify-start">
              <div className="relative w-fit">
                <div className="absolute w-[90%] h-[120%] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-[20px]"></div>
                <img
                  loading="lazy"
                  src={productsPage}
                  alt="products-page"
                  className="max-w-full w-[480px] z-10 relative"
                />
              </div>
            </div>
            <div className="flex-1 flex flex-col items-end lg:me-12 mt-8 lg:mt-0">
              <div className="max-w-[420px]">
                <h3 className="text-black text-2xl md:text-[40px] md:leading-[48px] font-medium">
                  {t("aboutUs.management")}
                </h3>
                <p className="text-base mt-2 md:mt-5 text-black font-normal">
                  - {t("aboutUs.managementTextOne")}
                </p>
                <p className="text-base mt-1 md:mt-3 text-black font-normal">
                  - {t("aboutUs.managementTextTwo")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex gap-6 justify-between items-center flex-col-reverse lg:flex-row">
            <div className="flex-1 max-w-[407px]">
              <h3 className="text-black text-2xl md:text-[40px] md:leading-[48px] font-medium">
                {t("aboutUs.control")}
              </h3>
              <p className="text-base mt-2 md:mt-5 text-black font-normal">
                - {t("aboutUs.controlTextOne")}
              </p>
              <p className="text-base mt-1 md:mt-3 text-black font-normal">
                - {t("aboutUs.controlTextTwo")}
              </p>
              <p className="text-base mt-1 md:mt-3 text-black font-normal">
                - {t("aboutUs.controlTextThree")}
              </p>
            </div>
            <div className="flex-1 flex justify-end">
              <div className="w-fit relative">
                <div
                  className={`${
                    i18n.language == "ar" ? "right-[4px]" : "left-[4px]"
                  } inventory-gradient absolute top-4 flex flex-col font-medium text-[#F6F7FF] rounded-full w-[300px] py-2.5 px-8`}
                >
                  <p className="text-xl">{t("aboutUs.esay")}</p>
                  <p className="flex items-center gap-3">
                    <span
                      className={`${
                        i18n.language == "en" ? "rotate-180" : ""
                      } -mb-1 -mr-1`}
                    >
                      <img loading="lazy" src={aboutArrow} alt="arrow" />
                    </span>
                    <span className="text-2xl">{t("links.skilltax")}</span>
                  </p>
                </div>
                <img
                  loading="lazy"
                  src={inventory}
                  alt="inventory-page"
                  className="max-w-full w-[250px] sm:w-[430px]"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex gap-6 justify-between items-center flex-col lg:flex-row">
            <div className="flex-1 flex justify-start">
              <div className="relative w-fit">
                <img
                  loading="lazy"
                  src={aboutIPad}
                  alt="about-ipad"
                  className="max-w-full w-[430px] z-10 relative"
                />
              </div>
            </div>
            <div className="flex-1 flex flex-col items-end lg:me-12 mt-8 lg:mt-0">
              <div className="max-w-[400px]">
                <h3 className="text-black text-2xl md:text-[40px] md:leading-[48px] font-medium">
                  {t("aboutUs.taxReturns")}
                </h3>
                <p className="text-base mt-2 md:mt-5 text-black font-normal">
                  - {t("aboutUs.taxReturnsTextOne")}
                </p>
                <p className="text-base mt-1 md:mt-3 text-black font-normal">
                  - {t("aboutUs.taxReturnsTextTwo")}
                </p>
                <p className="text-base mt-1 md:mt-3 text-black font-normal">
                  - {t("aboutUs.taxReturnsTextThree")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
};

export default AboutUs;
