import { useContext, useEffect, useState } from "react";
import { questions, faqsLogo } from "../assets";
import LogoLoading from "../components/Layouts/LogoLoading";
import { AppContext } from "../context/AppContext";
import EngineSeo from "../Engine/EngineSeo";
import { useTranslation } from "react-i18next";

const SEO_FAQS = {
  en: {
    title: "SkillTax - FAQs | Answers to Common Questions About Our POS System",
    description:
      "Explore the SkillTax FAQs page to find answers to the most common questions about our POS system for restaurant management. Get detailed information on features, setup, and troubleshooting to help you make the most of SkillTax.",
    keywords:
      "FAQs, SkillTax POS System, Common Questions, POS System Features, Restaurant Management FAQs, SkillTax Support, System Setup, Troubleshooting",
  },
  ar: {
    title:
      "SkillTax - الأسئلة الشائعة | إجابات للأسئلة الشائعة حول نظام نقاط البيع",
    description:
      "استكشف صفحة الأسئلة الشائعة الخاصة بسكيل تاكس للعثور على إجابات لأكثر الأسئلة شيوعًا حول نظامنا لإدارة المطاعم. احصل على معلومات تفصيلية حول الميزات، الإعداد، وحلول المشاكل لمساعدتك على الاستفادة القصوى من سكيل تاكس.",
    keywords:
      "الأسئلة الشائعة, نظام سكيل تاكس, الأسئلة الشائعة عن نظام نقاط البيع, ميزات نظام نقاط البيع, إدارة المطاعم, دعم سكيل تاكس, إعداد النظام, حلول المشاكل",
  },
};

const Faqs = () => {
  const { i18n, t } = useTranslation();
  const { fqs, fqsIsLoading } = useContext(AppContext);
  const [showFaqs, setShowFaqs] = useState({});

  useEffect(() => {
    if (fqs.length > 0) {
      let data = {};
      fqs.forEach(({ id }) => {
        data[id] = null;
      });
      setShowFaqs(data);
    }
  }, [fqs]);

  const onShowFaqs = (id) => {
    if (showFaqs[id] == null) {
      setShowFaqs((prev) => {
        return { ...prev, [id]: id };
      });
    } else {
      setShowFaqs((prev) => {
        return { ...prev, [id]: null };
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EngineSeo {...SEO_FAQS} />
      <div className="w-full h-full mt-2 sm:mt-4 lg:mt-16">
        {fqsIsLoading ? (
          <div className="w-full h-[55vh] flex items-center justify-center">
            <LogoLoading />
          </div>
        ) : (
          <div className="banner-container">
            <div className="w-full h-44 sm:h-64 lg:h-[350px] bg-[#5E5DDE] lg:rounded-[50px] px-4 py-8 lg:px-12 lg:py-4 relative lg:flex items-center">
              <h3 className="text-white relative z-30 font-medium text-3xl lg:text-5xl text-center lg:text-start lg:bottom-8">
                {t("links.faqs")}
              </h3>
              <img
                loading="lazy"
                src={questions}
                alt="questions"
                className={`${
                  i18n.language == "ar" ? "left-8" : "right-8"
                } hidden lg:block absolute -top-[69px] z-[2]`}
              />
              <img
                loading="lazy"
                src={faqsLogo}
                className="block absolute w-full left-0 bottom-0 lg:left-8 z-[1]"
                alt="faqs-logo"
              />
            </div>
          </div>
        )}
      </div>
      <section className="mt-16 md:mt-28 w-full">
        <div className="container">
          <div className="flex flex-col gap-4 justify-center items-center mx-auto w-full max-w-5xl">
            {fqs.map(
              ({ id, ar_question, en_question, ar_answer, en_answer }) => {
                return (
                  <div
                    onClick={() => {
                      onShowFaqs(id);
                    }}
                    key={id}
                    className={`${
                      showFaqs[id]
                        ? "bg-[#6C74E8] shadow-[0px_14px_34px_0px_#C0C1F0] text-[#EFF4FE]"
                        : "bg-white text-[#131313]"
                    } px-4 sm:px-5 py-4  border-[#CAD6FB] border rounded-2xl w-full cursor-pointer select-none `}
                  >
                    <div className="w-full h-full flex justify-between items-center gap-3">
                      <span className="sm:text-lg font-semibold block">
                        {i18n.language == "ar" ? ar_question : en_question}
                      </span>
                      <button
                        aria-label={
                          showFaqs[id] ? "Collapse FAQ" : "Expand FAQ"
                        }
                        className="cursor-pointer"
                      >
                        {showFaqs[id] ? (
                          <div className="w-6 h-6 border-[#EFF4FE] border-[2px] rounded-full">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="100%"
                              height="100%"
                              fill="#EFF4FE"
                            >
                              <path d="M19 13H5V11H19V13Z" />
                            </svg>
                          </div>
                        ) : (
                          <div className="w-6 h-6 border-[#4541C1] border-[2px] rounded-full">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="100%"
                              height="100%"
                              fill="#4541C1"
                            >
                              <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                            </svg>
                          </div>
                        )}
                      </button>
                    </div>
                    <div
                      className={`${
                        showFaqs[id] ? "block" : "hidden"
                      } text-base text-white mt-4`}
                    >
                      {i18n.language == "ar" ? ar_answer : en_answer}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqs;
