import { useEffect } from "react";
import {
  menuOne,
  menuTwo,
  menuQrCode,
  flexibility,
  visitsMenu,
} from "../../assets";
import Points from "../../components/Home/Points";
import EngineSeo from "../../Engine/EngineSeo";
import { useTranslation } from "react-i18next";
const SEO_SERVICES = {
  en: {
    title:
      "SkillTax - Electronic Menu | Streamlined Digital Menu Solutions for Your POS System",
    description:
      "Explore the SkillTax Electronic Menu service page to discover how our digital menu solutions integrate with our POS system. Learn how our electronic menu can enhance customer experience and simplify menu management.",
    keywords:
      "Electronic Menu, SkillTax POS System, Digital Menu Solutions, POS Integration, SkillTax Services, Menu Management, Restaurant Digital Menu, Electronic Menu Benefits",
  },
  ar: {
    title:
      "SkillTax - المنيو الإلكتروني | حلول المنيو الرقمي المتكاملة لنظام نقاط البيع",
    description:
      "استكشف صفحة خدمة المنيو الإلكتروني الخاصة بسكيل تاكس لتتعرف على كيفية تكامل حلول المنيو الرقمي مع نظام نقاط البيع لدينا. تعرف على كيفية تحسين تجربة العملاء وتبسيط إدارة المنيو باستخدام المنيو الإلكتروني.",
    keywords:
      "خدمة المنيو الإلكتروني, نظام سكيل تاكس, حلول المنيو الرقمي, تكامل نقاط البيع, خدمات سكيل تاكس, إدارة المنيو, المنيو الرقمي للمطاعم, فوائد المنيو الإلكتروني",
  },
};

const Menu = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EngineSeo {...SEO_SERVICES} />
      <section className="bg-white">
        <div className="flex items-center flex-col-reverse md:flex-row pt-8 p-4 md:p-0">
          <div className="flex-1 w-full flex items-center md:justify-center md:px-4">
            <div className="max-w-[570px] text-start mt-4 md:mt-0">
              <h3 className="text-[40px] leading-[56px] xl:text-[70px] xl:leading-[98px] text-[#131313] font-medium">
                {t("links.menuServices")}
              </h3>
              <p className="text-[#62646C] text-xl xl:text-2xl text-start md:mt-2">
                {t("menuServices.mainText")}
              </p>
            </div>
          </div>
          <div className="flex-1 flex items-center gap-1 justify-end">
            <div className="rounded-[20px] md:rounded-none overflow-hidden">
              <img
                loading="lazy"
                src={menuTwo}
                alt="menu"
                className="w-full object-cover max-w-full"
              />
            </div>
            <div className="hidden sm:block rounded-[20px] md:rounded-none overflow-hidden">
              <img
                loading="lazy"
                src={menuOne}
                alt="menu"
                className="w-full object-cover max-w-full"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex items-center justify-between gap-6 flex-col-reverse md:flex-row">
            <div className="font-medium max-w-[500px]">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("menuServices.integration")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("menuServices.integrationText")}
              </p>
            </div>
            <div>
              <img loading="lazy" src={menuQrCode} alt="menu-qr" />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col gap-6 md:gap-20 md:flex-row items-center justify-between">
            <div className="max-w-[500px]">
              <img loading="lazy" src={flexibility} alt="flexibility" />
            </div>
            <div className="w-full lg:w-[370px] xl:w-[512px] font-medium ">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("menuServices.flexibility")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("menuServices.flexibilityText")}
              </p>
              <p className="text-[#62646C] text-xl relative ps-4 mt-2">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("menuServices.flexibilityTextTwo")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex items-center justify-between gap-6 flex-col-reverse md:flex-row">
            <div className="font-medium max-w-[500px]">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("menuServices.analysis")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("menuServices.analysisText")}
              </p>
              <p className="text-[#62646C] text-xl relative ps-4 mt-2">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("menuServices.analysisTextTwo")}
              </p>
            </div>
            <div>
              <img loading="lazy" src={visitsMenu} alt="visits-menu" />
            </div>
          </div>
        </div>
      </section>
      <Points showShadow />
    </>
  );
};

export default Menu;
