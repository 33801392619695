import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const EngineSeo = ({ ar, en }) => {
  const { i18n } = useTranslation();
  return (
    <Helmet>
      <title>{i18n.language == "ar" ? ar.title : en.title}</title>
      <meta
        name="title"
        content={i18n.language == "ar" ? ar.title : en.title}
      />
      <meta
        name="description"
        content={i18n.language == "ar" ? ar.description : en.description}
      />
      <meta
        name="keywords"
        content={i18n.language == "ar" ? ar.keywords : en.keywords}
      />
      <meta
        property="og:title"
        content={i18n.language == "ar" ? ar.title : en.title}
      />
      <meta
        property="og:description"
        content={i18n.language == "ar" ? ar.description : en.description}
      />
      {/* <!-- twitter --> */}
      <meta
        name="twitter:title"
        content={i18n.language == "ar" ? ar.title : en.title}
      />
      <meta
        name="twitter:description"
        content={i18n.language == "ar" ? ar.description : en.description}
      />
    </Helmet>
  );
};

export default EngineSeo;
