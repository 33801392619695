import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import LogoLoading from "../../components/Layouts/LogoLoading";
import EngineSeo from "../../Engine/EngineSeo";
import DOMPurify from "dompurify";

const SEO_ARTICLE = (article) => ({
  en: {
    title: `SkillTax Blog | ${article?.en_title || "Blog"}`,
    description:
      article?.en_details ||
      "Explore detailed insights and updates on POS systems and restaurant management. Stay informed with our latest articles.",
    keywords:
      "SkillTax Blog, POS Systems, Restaurant Management, Business Insights",
  },
  ar: {
    title: `مدونة سكيل تاكس | ${article?.ar_title || "مدونة"}`,
    description:
      article?.ar_details ||
      "استكشف رؤى وتحديثات مفصلة حول أنظمة نقاط البيع وإدارة المطاعم. تابع أحدث مقالاتنا.",
    keywords: "مدونة سكيل تاكس, أنظمة نقاط البيع, إدارة المطاعم, رؤى الأعمال",
  },
});

const Blog = () => {
  const { id } = useParams();
  const { i18n, t } = useTranslation();
  const { formatDate, blogDepartmentList } = useContext(AppContext);
  const navigate = useNavigate();

  // BLOG
  const [blog, setBlog] = useState({});
  const [blogIsLoading, setBlogIsLoading] = useState(false);
  const [blogError, setBlogError] = useState(null);

  const fetchblog = async () => {
    try {
      setBlogIsLoading(true);
      let response = await fetch(`https://dash.wajen.net/api/v2/blog/${id}`);
      let data = await response.json();
      if (Object.keys(data).length == 0) {
        navigate("/404");
        return;
      }
      setBlog(data);
    } catch (err) {
      setBlogError(err);
      navigate("/404");
    } finally {
      setBlogIsLoading(false);
    }
  };

  useEffect(() => {
    fetchblog();
  }, [id]);

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EngineSeo {...SEO_ARTICLE(blog)} />
      {blogIsLoading ? (
        <div className="h-[70vh] flex items-center ju justify-center">
          <LogoLoading />
        </div>
      ) : (
        <>
          <section className="w-full">
            <div className="container">
              <div className="w-full h-[240px] sm:h-[620px] bg-[#EFF4FE] sm:py-4 sm:px-6 rounded-[20px] relative">
                <img
                  className="w-full h-full object-cover rounded-[20px]"
                  src={blog?.image && `https://dash.wajen.net${blog?.image}`}
                  alt={blog?.en_title}
                />
                <div className="absolute bottom-4 sm:bottom-8 px-4">
                  <h3 className="text-2xl sm:text-3xl text-white font-medium capitalize">
                    {i18n.language == "ar" ? blog?.ar_title : blog?.en_title}
                  </h3>
                  <p className="sm:text-lg text-white mt-1">
                    {formatDate(blog?.created_at)}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="mt-8 sm:mt-12">
            <div className="container">
              <h4 className="text-2xl sm:text-3xl lg:text-[40px] lg:leading-[44px] font-medium mb-3 sm:mb-6 capitalize">
                {i18n.language == "ar" ? blog?.ar_title : blog?.en_title}
              </h4>
              <p className="text-[#62646C] font-medium text-lg leading-8">
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      i18n.language == "ar"
                        ? blog?.ar_details
                        : blog?.en_details
                    ),
                  }}
                  className="text-lg"
                />
              </p>
            </div>
          </section>
          <section className="mt-12 sm:mt-24">
            <div className="container">
              <h3 className="font-medium text-[#131313] text-[40px] leading-[56px] mb-6">
                {t("blogs.otherNews")}
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 items-center justify-center">
                {blogDepartmentList?.map(
                  ({ id, ar_department, en_department, image }) => {
                    return (
                      <Link
                        onClick={handleLinkClick}
                        key={id}
                        to={`/blogs/${id}`}
                      >
                        <div className="group p-2 w-full h-[200px] sm:h-[220px] border border-[#AABBF7] rounded-2xl overflow-hidden flex flex-col justify-between cursor-pointer transition-all duration-[400ms] hover:shadow-[0px_36.54px_53.15px_0px_#4646461A]">
                          <div className="relative rounded-2xl overflow-hidden">
                            <img
                              src={`https://dash.wajen.net${image}`}
                              alt={en_department}
                              loading="lazy"
                              className="w-full h-full sm:h-[180px] object-cover rounded-2xl"
                            />
                            <div className="card-gradient absolute left-0 bottom-0 w-full h-[66%]"></div>
                          </div>
                          <div className="flex justify-between items-center mt-2">
                            <h3 className="text-base font-medium text-[#131313] capitalize">
                              {i18n.language == "ar"
                                ? ar_department
                                : en_department}
                            </h3>
                            <p>
                              <svg
                                className="text-[#222222] group-hover:text-[#6365C0] group-hover:rotate-90 transition-all duration-[400ms]"
                                width="14"
                                height="14"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.2361 1.2204L1.43945 12.017M1.43945 12.017L11.8042 12.017M1.43945 12.017L1.43945 1.65226"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                  }
                )}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Blog;
