import { useEffect } from "react";
import {
  specialOne,
  specialTwo,
  customizationSpecial,
  goalsSpecial,
  costsSpecial,
} from "../../assets";
import Points from "../../components/Home/Points";
import EngineSeo from "../../Engine/EngineSeo";
import { useTranslation } from "react-i18next";
const SEO_SERVICES = {
  en: {
    title:
      "SkillTax - Special Services | Customized POS Solutions for Unique Needs",
    description:
      "Explore the SkillTax Special Services page to discover how our customized POS solutions can address unique needs and specific requirements. Learn about our tailored services designed to provide exceptional support and functionality for diverse business scenarios.",
    keywords:
      "Special Services, SkillTax POS System, Customized POS Solutions, Tailored Services, SkillTax Features, Unique Business Needs, POS System Customization, Specialized POS Support",
  },
  ar: {
    title: "SkillTax - خدمات خاصة | حلول نقاط البيع المخصصة للاحتياجات الفريدة",
    description:
      "استكشف صفحة الخدمات الخاصة بسكيل تاكس لتكتشف كيف يمكن أن تلبي حلولنا المخصصة نقاط البيع احتياجاتك الفريدة ومتطلباتك الخاصة. تعرف على خدماتنا المصممة لتوفير دعم استثنائي ووظائف مخصصة لمجموعة متنوعة من سيناريوهات الأعمال.",
    keywords:
      "خدمات خاصة, نظام سكيل تاكس, حلول نقاط البيع المخصصة, خدمات مصممة, ميزات سكيل تاكس, احتياجات الأعمال الفريدة, تخصيص نظام نقاط البيع, دعم نقاط البيع المتخصص",
  },
};

const Special = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EngineSeo {...SEO_SERVICES} />
      <section className="bg-white">
        <div className="flex items-center flex-col-reverse md:flex-row pt-8 p-4 md:p-0">
          <div className="flex-1 w-full flex items-center md:justify-center md:px-4">
            <div className="max-w-[570px] text-start mt-4 md:mt-0">
              <h3 className="text-[40px] leading-[56px] xl:text-[70px] xl:leading-[98px] text-[#131313] font-medium">
                {t("links.specialServices")}
              </h3>
              <p className="text-[#62646C] text-xl xl:text-2xl text-start md:mt-2">
                {t("specialServices.mainText")}
              </p>
            </div>
          </div>
          <div className="flex-1 flex items-center gap-1 justify-end">
            <div className="rounded-[20px] md:rounded-none overflow-hidden">
              <img
                loading="lazy"
                src={specialTwo}
                alt="special"
                className="w-full object-cover max-w-full"
              />
            </div>
            <div className="hidden sm:block rounded-[20px] md:rounded-none overflow-hidden">
              <img
                loading="lazy"
                src={specialOne}
                alt="special"
                className="w-full object-cover max-w-full"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex items-center justify-between gap-6 flex-col-reverse md:flex-row">
            <div className="font-medium max-w-[500px]">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("specialServices.customization")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("specialServices.customizationText")}
              </p>
            </div>
            <div>
              <img
                loading="lazy"
                src={customizationSpecial}
                alt="customization-special"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col gap-6 md:gap-20 md:flex-row items-center justify-between">
            <div className="max-w-[500px]">
              <img loading="lazy" src={goalsSpecial} alt="goals-special" />
            </div>
            <div className="w-full lg:w-[370px] xl:w-[512px] font-medium ">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("specialServices.investigation")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("specialServices.investigationText")}
              </p>
              <p className="text-[#62646C] text-xl relative ps-4 mt-2">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("specialServices.investigationTextTwo")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex items-center justify-between gap-6 flex-col-reverse md:flex-row">
            <div className="font-medium max-w-[500px]">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("specialServices.costs")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("specialServices.costsText")}
              </p>
            </div>
            <div>
              <img loading="lazy" src={costsSpecial} alt="costs-special" />
            </div>
          </div>
        </div>
      </section>
      <Points showShadow />
    </>
  );
};

export default Special;
