import { useEffect } from "react";
import {
  bannerRectangle,
  payments,
  tod,
  restaurantBanner,
  restaurantBanner2,
  restaurantBanner3,
  restaurantCshier,
  connectivity,
  restaurantInventory,
} from "../../assets";
import Badge from "../../components/Layouts/Badge";
import EngineSeo from "../../Engine/EngineSeo";
import { useTranslation } from "react-i18next";

const SEO_SECTOR = {
  en: {
    title:
      "SkillTax - Restaurant Sector | Comprehensive POS Solutions for Dining Establishments",
    description:
      "Discover the SkillTax Restaurant Sector page to learn how our POS system caters to the needs of dining establishments. Explore our comprehensive solutions designed to enhance restaurant management and streamline operations.",
    keywords:
      "Restaurant Sector, SkillTax POS System, Restaurant Management Solutions, POS for Restaurants, SkillTax Features, Dining Establishment Management, POS Solutions for Restaurants, Restaurant Software",
  },
  ar: {
    title: "SkillTax - قطاع المطاعم | حلول نقاط البيع الشاملة للمطاعم",
    description:
      "اكتشف صفحة قطاع المطاعم الخاصة بسكيل تاكس لتتعرف على كيفية تلبية نظامنا لاحتياجات المنشآت الغذائية. استعرض حلولنا الشاملة المصممة لتعزيز إدارة المطاعم وتبسيط العمليات.",
    keywords:
      "قطاع المطاعم, نظام سكيل تاكس, حلول إدارة المطاعم, نقاط البيع للمطاعم, ميزات سكيل تاكس, إدارة المنشآت الغذائية, حلول نقاط البيع للمطاعم, برنامج إدارة المطاعم",
  },
};

const RestaurantSector = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EngineSeo {...SEO_SECTOR} />
      <section className="w-full">
        <div className="container">
          <div className="py-10 md:py-12 lg:py-20 text-center relative -z-[1]">
            <div className="shadow-[0px_0px_330px_30px_#5E5DDE] sm:shadow-[0px_0px_290px_60px_#5E5DDE] left-[50%] translate-x-[-50%] absolute -z-[1]"></div>
            <h3 className="main-heading mx-auto">
              {t("links.restaurantSectors")}
            </h3>
          </div>
          <div className="flex flex-col md:flex-row items-center w-full md:h-[450px] bg-[#4541C1] overflow-hidden rounded-xl">
            <div className="md:flex-[1.7]">
              <img
                loading="lazy"
                className="max-w-full w-full md:h-[450px] relative z-10 object-cover"
                src={restaurantBanner}
                alt="banner"
              />
            </div>
            <div className="flex-1 py-8 md:py-0 px-3 md:px-0  !ps-3 lg:!ps-5 text-white relative h-[450px] flex flex-col justify-center">
              <div className="hidden lg:block absolute top-0 -right-10">
                <img
                  loading="lazy"
                  src={bannerRectangle}
                  alt="banner-rectangle"
                />
              </div>
              <div className="absolute top-20 -left-[136px] rotate-90">
                <img
                  loading="lazy"
                  src={bannerRectangle}
                  alt="banner-rectangle"
                />
              </div>
              <h4 className="text-2xl font-medium lg:text-[35px] lg:leading-[50px] text-[#E2E9FD]">
                {t("links.restaurantSectors")}
              </h4>
              <p className="text-lg lg:text-2xl mt-3">
                {t("restaurantSectors.mainText")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col-reverse md:flex-row gap-10 items-center justify-between">
            <div className="max-w-[500px]">
              <Badge>{t("heading.integration")}</Badge>
              <h5 className="text-black font-medium text-2xl lg:text-3xl">
                {t("restaurantSectors.tod")}
              </h5>
              <p className="text-[#62646C] font-medium text-xl lf:text-2xl mt-2">
                {t("restaurantSectors.todText")}
              </p>
            </div>
            <div>
              <img loading="lazy" src={tod} alt="tod" />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col md:gap-20 md:flex-row items-center justify-between">
            <div className="max-w-[500px]">
              <img loading="lazy" src={payments} alt="payments" />
            </div>
            <div className="w-full lg:w-[370px] xl:w-[512px] md:pb-28">
              <Badge>{t("heading.flexibility")}</Badge>
              <h5 className="text-black font-medium text-2xl lg:text-3xl">
                {t("restaurantSectors.payments")}
              </h5>
              <p className="text-[#62646C] font-medium text-xl lf:text-2xl mt-2">
                {t("restaurantSectors.paymentsText")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="banner-container mx-auto">
          <div className="flex flex-col lg:flex-row items-center w-full lg:h-[613px] bg-[#4541C1] p-[10px] lg:p-0 overflow-hidden md:rounded-xl">
            <div className="flex-1 flex flex-col text-white relative h-[613px]">
              <div className="flex flex-col justify-center flex-[1.2] py-10">
                <div className="absolute -top-10 mlg:top-0 -right-10">
                  <img
                    loading="lazy"
                    src={bannerRectangle}
                    alt="banner-rectangle"
                  />
                </div>
                <div className="hidden lg:block absolute top-20 -left-[136px] rotate-90">
                  <img
                    loading="lazy"
                    src={bannerRectangle}
                    alt="banner-rectangle"
                  />
                </div>
                <p className="text-2xl font-medium lg:text-[35px] lg:leading-[50px] text-[#E2E9FD] px-4">
                  {t("restaurantSectors.secondaryText")}
                </p>
              </div>
              <div className="flex-1">
                <img
                  loading="lazy"
                  className="max-w-full w-full relative z-10 object-cover h-[220px] lg:h-full rounded-[10px] md:rounded-none"
                  src={restaurantBanner3}
                  alt="banner"
                />
              </div>
            </div>
            <div className="hidden lg:block lg:flex-1">
              <img
                loading="lazy"
                className="max-w-full w-full lg:h-[613px] relative z-10 object-cover"
                src={restaurantBanner2}
                alt="banner"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col-reverse md:flex-row gap-10 items-center justify-between">
            <div className="max-w-[500px]">
              <Badge>{t("heading.organization")}</Badge>
              <h5 className="text-black font-medium text-2xl lg:text-3xl">
                {t("restaurantSectors.organization")}
              </h5>
              <p className="text-[#62646C] font-medium text-xl lf:text-2xl mt-2">
                {t("restaurantSectors.organizationText")}
              </p>
            </div>
            <div>
              <img loading="lazy" src={restaurantCshier} alt="cashier" />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col md:gap-20 md:flex-row items-center justify-between">
            <div className="max-w-[500px]">
              <img
                loading="lazy"
                src={restaurantInventory}
                alt="restaurant-inventory"
              />
            </div>
            <div className="w-full lg:w-[420px] xl:w-[444px]">
              <Badge>{t("heading.follow")}</Badge>
              <h5 className="text-black font-medium text-2xl lg:text-3xl">
                {t("restaurantSectors.follow")}
              </h5>
              <p className="text-[#62646C] font-medium text-xl lf:text-2xl mt-2">
                - {t("restaurantSectors.followText")}
              </p>
              <p className="text-[#62646C] font-medium text-xl lf:text-2xl mt-2">
                - {t("restaurantSectors.followTextTwo")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col-reverse md:flex-row gap-10 items-center justify-between">
            <div className="max-w-[500px]">
              <Badge>{t("heading.integration")}</Badge>
              <h5 className="text-black font-medium text-2xl lg:text-3xl">
                {t("restaurantSectors.delivery")}
              </h5>
              <p className="text-[#62646C] font-medium text-xl lf:text-2xl mt-2">
                {t("restaurantSectors.deliveryText")}
              </p>
            </div>
            <div>
              <img loading="lazy" src={connectivity} alt="connectivity" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RestaurantSector;
