import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Field = ({
  label,
  name,
  placeholder,
  type = "text",
  float,
  control,
  errors,
  options = [],
  isRequired,
  height,
  rows = 5,
}) => {
  const { i18n } = useTranslation();
  const error = errors && errors[name]?.message;

  return (
    <div className="w-full relative">
      <label htmlFor={name} className="text-[#344054] text-base">
        {label} {isRequired && <span className="text-red-600 text-xl">*</span>}
      </label>
      {type != "select" && type != "textarea" && control && (
        <div className="relative">
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <>
                <input
                  style={height && { height: height }}
                  {...field}
                  id={name}
                  min="1"
                  type={type}
                  placeholder={placeholder || label}
                  className={`${
                    error ? "border-red-600" : "border-[#D0D5DD]"
                  } w-full h-[48px] border px-4 rounded-xl outline-none mt-2 text-base shadow-[0px_1px_2px_0px_#1018280]`}
                />
              </>
            )}
          />
          {float && (
            <button
              type="button"
              className="bg-[#5E5DDE] text-sm font-medium px-3 py-1 text-white rounded-full absolute top-[19px] cursor-auto"
              style={{
                left: i18n.language === "ar" ? "12px" : "auto",
                right: i18n.language === "ar" ? "auto" : "12px",
              }}
            >
              <span className="block -mb-1 sm:mb-0 w-full h-full">{float}</span>
            </button>
          )}
        </div>
      )}

      {type == "select" && control && (
        <div className="relative h-fit">
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <select
                id={name}
                {...field}
                placeholder={placeholder || label}
                className={`${
                  error ? "border-red-600" : "border-[#D0D5DD]"
                } select-arrow relative w-full h-[48px] border  px-4 rounded-xl outline-none mt-2 text-base shadow-[0px_1px_2px_0px_#1018280] `}
              >
                {options?.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            )}
          />
          <div
            // ${isOpen ? "rotate-90" : "-rotate-90"}
            className={` ${i18n.language == "ar" ? "left-4" : "right-4"} 
              absolute  top-[26px] transition-all duration-300 -rotate-90`}
          >
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 13L1 7L7 1"
                stroke="#6C74E8"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      )}

      {type == "textarea" && (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <textarea
              {...field}
              id={name}
              rows={rows}
              type={type}
              placeholder={placeholder || label}
              className={`${
                error ? "border-red-600" : "border-[#D0D5DD]"
              } w-full border py-2 px-4 rounded-xl outline-none mt-2 text-base shadow-[0px_1px_2px_0px_#1018280] `}
            ></textarea>
          )}
        />
      )}

      <p className="text-red-600 text-sm h-5">{error}</p>
    </div>
  );
};

const Button = ({ onClick, isLoading, children, disabled = false, icon }) => {
  const { i18n } = useTranslation();

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${isLoading && "bg-opacity-60 cursor-auto"} ${
        disabled
          ? "bg-opacity-60 cursor-not-allowed"
          : "hover:shadow-[0px_12.03px_40.89px_0px_#9FA0E8CC]"
      }  transition-all w-full flex items-center justify-center gap-2 mt-1 bg-[#5E5DDE] rounded-xl h-[52px] text-white font-medium `}
    >
      <span className="-mb-1.5 sm:mb-0">{children}</span>
      {icon && !isLoading && (
        <img
          alt="icon"
          className={i18n.language == "en" && "rotate-180"}
          src={icon}
        />
      )}
      {isLoading && <span className="loader"></span>}
    </button>
  );
};

const CheckBox = ({
  children,
  name,
  control,
  errors,
  isRequired,
  className,
}) => {
  const error = errors && errors[name]?.message;

  return (
    <>
      <div
        className={`
          ${className}  w-full flex items-start gap-2 lg:gap-3 text-black `}
      >
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <div className="inline-flex items-center">
              <label
                className="relative flex cursor-pointer items-center rounded-full"
                htmlFor={name}
              >
                <input
                  type="checkbox"
                  className="before:content[''] peer relative h-6 w-6 border-[#6C74E8] cursor-pointer appearance-none rounded-md border transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#6C74E8] checked:bg-[#6C74E8] hover:before:opacity-10"
                  id={name}
                  checked={field.value || false}
                  {...field}
                  name={name}
                  aria-label={name}
                />
                <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </label>
            </div>
          )}
        />
        <p className="text-black text-base -mt-0.5">{children}</p>
      </div>
      {(isRequired || error) && (
        <p className="text-red-600 text-sm h-5 -mt-1 self-start">{error}</p>
      )}
    </>
  );
};

Field.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(["text", "select", "number", "email", "textarea"]),
  float: PropTypes.string,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  isRequired: PropTypes.bool,
};

Button.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.string,
  disabled: PropTypes.bool,
};

CheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.string,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  isRequired: PropTypes.bool,
};

Field.Button = Button;
Field.CheckBox = CheckBox;
export default Field;
