import { useTranslation } from "react-i18next";
import { pointsOne, whyImageOne, zigLines, pointsTwo } from "../../assets";
import { Link } from "react-router-dom";

const Points = ({ showShadow = false }) => {
  const { i18n, t } = useTranslation();
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="mt-20 lg:mt-32">
      <div className="container">
        <div className="lg:border border-[#8897F1] rounded-[20px] p-4 bg-[#EFF4FE] relative">
          {showShadow && (
            <div className="w-[96%] hidden lg:block h-full bg-[#CAD6FB] rounded-[20px] -top-4 left-[50%] translate-x-[-50%] absolute -z-[1]"></div>
          )}
          <div className="hidden lg:block">
            <img
              loading="lazy"
              src={zigLines}
              alt="line"
              className=" absolute z-0 top-0 right-3"
            />
          </div>
          <div className="flex justify-between gap-10 flex-col-reverse relative lg:flex-row z-10">
            <div className="text-black font-medium flex flex-col gap-3 lg:gap-4 lg:ps-12 items-center lg:items-start justify-center">
              <h4 className="text-2xl lg:text-[40px] lg:leading-[44px]">
                {t("home.points")}
              </h4>
              <p className="text-center lg:text-start lg:max-w-[330px] text-base lg:text-xl">
                {t("home.pointsText")}
              </p>
              <Link to="/packages" onClick={handleLinkClick}>
                <button className="bg-[#6C74E8] h-[52px] w-[226px] rounded-[10px] text-white transition-all hover:shadow-[0px_12.03px_40.89px_0px_#9FA0E8CC]">
                  <span className="block -mb-1.5 sm:mb-0">
                    {t("links.subscribe")}
                  </span>
                </button>
              </Link>
            </div>
            <div className="flex gap-1 lg:gap-3">
              <div className="flex flex-col gap-1 lg:gap-3 justify-end items-end relative flex-1">
                <div
                  className={`${
                    i18n.language == "ar"
                      ? "left-0 right-auto "
                      : "left-auto right-0"
                  }    
                rounded-full shadow-[0px_0px_40px_70px_#848fe9] absolute top-[160px] z-[1] w-auto`}
                ></div>
                <img
                  loading="lazy"
                  className="w-[50%] min-w-28 md:min-w-60 lg:min-w-[auto] h-20 md:h-40 lg:w-[212px] lg:h-[136px] object-cover rounded-[20px] z-10"
                  src={whyImageOne}
                  alt="points-one"
                />
                <img
                  loading="lazy"
                  className="w-full h-28 md:h-44 lg:w-[280px] lg:h-[180px] object-cover rounded-[20px] z-10"
                  src={pointsTwo}
                  alt="points-two"
                />
              </div>
              <div className="z-10 flex-1">
                <img
                  loading="lazy"
                  className="w-full h-56 lg:w-[280px] md:h-96 lg:h-[364px] object-cover rounded-[20px]"
                  src={pointsOne}
                  alt="points-three"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Points;
