import { Link, NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  phone,
  langage,
  logo,
  restaurantBanner,
  cafesBanner,
  foodBanner,
  connectivityPage,
  menuTwo,
  todTwo,
  waiterTwo,
  specialTwo,
  orderingTwo,
  tablesTwo,
  buttonArrow,
  contactUsImage,
  bePartHero,
  referralsHero,
} from "../../assets/index";
// import Marquee from "react-marquee-slider";
// import useScreenSize from "../../hooks/useScreenSize";

const ButtonAuthGroup = ({ closeDrawer = () => {} }) => {
  const { i18n, t } = useTranslation();

  return (
    <Link onClick={closeDrawer} to="/packages" className="w-fit h-fit">
      <button
        className={`${
          i18n.language == "ar" ? "flex-row" : "flex-row-reverse"
        } bg-[#5E5DDE] hover:scale-90 transition-all border border-[#5E5DDE] rounded-[10px] font-medium text-white h-[42px] w-[132px] flex items-center justify-center gap-2.5`}
      >
        <span>
          <img src={buttonArrow} alt="arrow" className="w-1.5 rotate-180" />
        </span>
        <span className="block -mb-1.5 sm:mb-0">{t("links.subscribe")}</span>
      </button>
    </Link>
  );
};

// const TopMarquee = ({ text = "links.trialVersion" }) => {
//   const { i18n, t } = useTranslation();
//   const { width } = useScreenSize();
//   const [velocity, setVelocity] = useState(12); // Initial velocity

//   const velocityHandler = () => {
//     if (width < 768) {
//       setVelocity(4); // Higher velocity for mobile screens
//     } else {
//       setVelocity(i18n.language === "ar" ? 12 : 24);
//     }
//   };

//   useEffect(() => {
//     velocityHandler();
//   }, [width, i18n.language]);

//   return (
//     <div
//       className="bg-black text-sm text-white h-10"
//       onMouseEnter={() => setVelocity(0)}
//       onMouseLeave={velocityHandler}
//     >
//       <Marquee
//         direction={i18n.dir()}
//         velocity={velocity}
//         paused={velocity === 0 ? "true" : "false"}
//       >
//         {/* Marquee content */}
//         {[...Array(16)].map((_, index) => (
//           <div key={index} className="mx-6 w-full h-10 flex items-center">
//             <a
//               aria-label="Contact us via WhatsApp"
//               href="https://wa.me/+966553559038"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="min-w-10 min-h-10 flex items-center justify-center w-full h-full text-sm"
//             >
//               {t(text)}
//             </a>
//           </div>
//         ))}
//       </Marquee>
//     </div>
//   );
// };

const SwitchLang = ({ isBlogPath }) => {
  const { i18n } = useTranslation();
  const handleChangeLanguage = () => {
    if (i18n.language == "ar") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("ar");
    }
  };
  return (
    <button
      onClick={handleChangeLanguage}
      className={`${
        isBlogPath ? "bg-[#EFF4FE] border border-[#5B5AD1]" : "bg-white"
      } flex items-center justify-center gap-2 h-fit p-1 px-2 rounded-full flex-shrink-0 hover:scale-90 transition-all`}
    >
      <p className="h-fit text-[#5b5ad1] text-base uppercase -mb-2 sm:mb-0">
        {i18n.language == "ar" ? "EN" : "AR"}
      </p>
      <img
        loading="lazy"
        src={langage}
        alt="langage"
        className="w-[23px] h-[23px]"
      />
    </button>
  );
};

const TopHeader = () => {
  return (
    // h-[60px]
    <div className="w-full h-[42px] md:h-[52px] bg-[#5E5DDE] px-4 md:px-[50px] flex items-center justify-between text-white">
      <div className="flex items-center gap-[6px]">
        <img loading="lazy" src={phone} alt="phone" />
        <p className="text-[19px] -mb-2 sm:mb-0" dir="ltr">
          +966 55 355 9038
        </p>
      </div>
      <SwitchLang />
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const MainHeader = ({ setIsOpen, isBlogsPath, isBlogPath }) => {
  const { i18n, t } = useTranslation();
  // Sectors
  const [isHoveredSectors, setIsHoveredSectors] = useState(false);
  const handleMouseEnterSectors = () => {
    setIsHoveredSectors(true);
  };
  const handleMouseLeaveSectors = () => {
    setIsHoveredSectors(false);
  };
  const handleClickSectors = () => {
    setIsHoveredSectors(false);
  };

  //Service
  const [isHoveredService, setIsHoveredService] = useState(false);
  const handleMouseEnterService = () => {
    setIsHoveredService(true);
  };
  const handleMouseLeaveService = () => {
    setIsHoveredService(false);
  };
  const handleClickService = () => {
    setIsHoveredService(false);
  };

  //Community
  const [isHoveredCommunity, setIsHoveredCommunity] = useState(false);
  const handleMouseEnterCommunity = () => {
    setIsHoveredCommunity(true);
  };
  const handleMouseLeaveCommunity = () => {
    setIsHoveredCommunity(false);
  };
  const handleClickCommunity = () => {
    setIsHoveredCommunity(false);
  };

  return (
    <div className="w-full py-4 xl:py-8 xl:pb-11 px-4 md:px-[50px] flex items-center justify-between relative">
      <Link to="/">
        <img
          loading="lazy"
          src={logo}
          alt="Skilltax Logo"
          className="w-28 md:w-36 xl:w-[158px]"
        />
      </Link>
      <ul
        className={`${isBlogsPath ? "text-white" : "text-[#131313]"} ${
          i18n.language == "ar"
            ? "left-[50%] translate-x-[-40%] 2xl:translate-x-[-50%]"
            : "right-[50%] translate-x-[50%]"
        }  hidden whitespace-nowrap xl:flex gap-5 text-base font-medium cursor-pointer absolute z-40 `}
      >
        <li className="hover:text-[#4541C1] flex items-center">
          <NavLink
            className={({ isActive }) =>
              `${isActive ? "text-[#4541C1] py-2" : ""} py-2`
            }
            to="/"
          >
            {t("links.home")}
          </NavLink>
        </li>
        <li className="hover:text-[#4541C1]  flex items-center">
          <NavLink
            className={({ isActive }) =>
              `${isActive ? "text-[#4541C1] py-2" : ""} py-2`
            }
            to="/about-us"
          >
            {t("links.aboutUs")}
          </NavLink>
        </li>
        <li
          onMouseEnter={handleMouseEnterSectors}
          onMouseLeave={handleMouseLeaveSectors}
          className="hover:text-[#4541C1] relative py-2 group flex items-center gap-1"
        >
          <span className="shrink-0 transition-[transform] duration-[400ms]  group-hover:-rotate-180">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          <span>{t("links.ourSectors")}</span>
          {isHoveredSectors && (
            <div className="absolute z-50 bg-white w-64 rounded-[10px] right-0 top-10 hidden group-hover:flex cursor-default shadow-[0px_34px_64px_0px_#00000026]">
              <ul className="p-1.5 flex flex-col gap-2 w-full">
                <li className="p-1.5 flex items-center w-full gap-2 cursor-pointer hover:bg-[#EFF4FE] rounded-[10px]">
                  <Link
                    to="/sectors/restaurant-sector"
                    className="flex items-center w-full gap-2"
                    onClick={handleClickSectors}
                  >
                    <div className="w-10 h-10 rounded-[10px] bg-[#D9D9D9] overflow-hidden flex-shrink-0">
                      <img
                        loading="lazy"
                        src={restaurantBanner}
                        className="w-full h-full object-cover"
                        alt="link"
                      />
                    </div>
                    <p className="text-[#131313] text-base font-medium">
                      {t("links.restaurantSectors")}
                    </p>
                  </Link>
                </li>
                <li className="p-1.5 flex items-center w-full gap-2 cursor-pointer hover:bg-[#EFF4FE] rounded-[10px]">
                  <Link
                    to="/sectors/cafes-sector"
                    className="flex items-center w-full gap-2"
                    onClick={handleClickSectors}
                  >
                    <div className="w-10 h-10 rounded-[10px] bg-[#D9D9D9] overflow-hidden flex-shrink-0">
                      <img
                        loading="lazy"
                        src={cafesBanner}
                        className="w-full h-full object-cover"
                        alt="link"
                      />
                    </div>
                    <p className="text-[#131313] text-base font-medium">
                      {t("links.cafesSectors")}
                    </p>
                  </Link>
                </li>
                <li className="p-1.5 flex items-center w-full gap-2 cursor-pointer hover:bg-[#EFF4FE] rounded-[10px]">
                  <Link
                    to="/sectors/foodtruck-sector"
                    className="flex items-center w-full gap-2"
                    onClick={handleClickSectors}
                  >
                    <div className="w-10 h-10 rounded-[10px] bg-[#D9D9D9] overflow-hidden flex-shrink-0">
                      <img
                        loading="lazy"
                        src={foodBanner}
                        className="w-full h-full object-cover"
                        alt="link"
                      />
                    </div>
                    <p className="text-[#131313] text-base font-medium">
                      {t("links.foodtruckSectors")}
                    </p>
                  </Link>
                </li>
                <li className="p-1.5 flex items-center w-full gap-2 cursor-pointer hover:bg-[#EFF4FE] rounded-[10px] whitespace-normal ">
                  <Link
                    to="/sectors/connectivity-sector"
                    className="flex items-center w-full gap-2"
                    onClick={handleClickSectors}
                  >
                    <div className="w-10 h-10 rounded-[10px] bg-[#D9D9D9] overflow-hidden flex-shrink-0">
                      <img
                        loading="lazy"
                        src={connectivityPage}
                        className="w-full h-full object-cover"
                        alt="link"
                      />
                    </div>
                    <p className="text-[#131313] text-base font-medium ">
                      {t("links.connectivitySectors")}
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </li>
        <li
          onMouseEnter={handleMouseEnterService}
          onMouseLeave={handleMouseLeaveService}
          className="hover:text-[#4541C1] relative py-2 group flex items-center gap-1"
        >
          <span className="shrink-0 transition-[transform] duration-[400ms] group-hover:-rotate-180">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          <span>{t("links.ourServices")}</span>
          {isHoveredService && (
            <div className="absolute z-50 bg-white w-64 rounded-[10px] right-0 top-10 hidden group-hover:flex cursor-default shadow-[0px_34px_64px_0px_#00000026]">
              <ul className="p-1.5 flex flex-col gap-2 w-full">
                <li className="p-1.5 flex items-center w-full gap-2 cursor-pointer hover:bg-[#EFF4FE] rounded-[10px]">
                  <Link
                    to="/services/menu"
                    className="flex items-center w-full gap-2"
                    onClick={handleClickService}
                  >
                    <div className="w-10 h-10 rounded-[10px] bg-[#D9D9D9] overflow-hidden flex-shrink-0">
                      <img
                        loading="lazy"
                        src={menuTwo}
                        className="w-full h-full object-cover"
                        alt="link"
                      />
                    </div>
                    <p className="text-[#131313] text-base font-medium">
                      {t("links.menuServices")}
                    </p>
                  </Link>
                </li>
                <li className="p-1.5 flex items-center w-full gap-2 cursor-pointer hover:bg-[#EFF4FE] rounded-[10px]">
                  <Link
                    to="/services/tod"
                    className="flex items-center w-full gap-2"
                    onClick={handleClickService}
                  >
                    <div className="w-10 h-10 rounded-[10px] bg-[#D9D9D9] overflow-hidden flex-shrink-0">
                      <img
                        loading="lazy"
                        src={todTwo}
                        className="w-full h-full object-cover"
                        alt="link"
                      />
                    </div>
                    <p className="text-[#131313] text-base font-medium">
                      {t("links.todServices")}
                    </p>
                  </Link>
                </li>
                <li className="p-1.5 flex items-center w-full gap-2 cursor-pointer hover:bg-[#EFF4FE] rounded-[10px]">
                  <Link
                    to="/services/waiter"
                    className="flex items-center w-full gap-2"
                    onClick={handleClickService}
                  >
                    <div className="w-10 h-10 rounded-[10px] bg-[#D9D9D9] overflow-hidden flex-shrink-0">
                      <img
                        loading="lazy"
                        src={waiterTwo}
                        className="w-full h-full object-cover"
                        alt="link"
                      />
                    </div>
                    <p className="text-[#131313] text-base font-medium">
                      {t("links.waiterServices")}
                    </p>
                  </Link>
                </li>
                <li className="p-1.5 flex items-center w-full gap-2 cursor-pointer hover:bg-[#EFF4FE] rounded-[10px]">
                  <Link
                    to="/services/special"
                    className="flex items-center w-full gap-2"
                    onClick={handleClickService}
                  >
                    <div className="w-10 h-10 rounded-[10px] bg-[#D9D9D9] overflow-hidden flex-shrink-0">
                      <img
                        loading="lazy"
                        src={specialTwo}
                        className="w-full h-full object-cover"
                        alt="link"
                      />
                    </div>
                    <p className="text-[#131313] text-base font-medium">
                      {t("links.specialServices")}
                    </p>
                  </Link>
                </li>
                <li className="p-1.5 flex items-center w-full gap-2 cursor-pointer hover:bg-[#EFF4FE] rounded-[10px]">
                  <Link
                    to="/services/restaurant-ordering"
                    className="flex items-center w-full gap-2"
                    onClick={handleClickService}
                  >
                    <div className="w-10 h-10 rounded-[10px] bg-[#D9D9D9] overflow-hidden flex-shrink-0">
                      <img
                        loading="lazy"
                        src={orderingTwo}
                        className="w-full h-full object-cover"
                        alt="link"
                      />
                    </div>
                    <p className="text-[#131313] text-base font-medium">
                      {t("links.orderingServices")}
                    </p>
                  </Link>
                </li>
                <li className="p-1.5 flex items-center w-full gap-2 cursor-pointer hover:bg-[#EFF4FE] rounded-[10px]">
                  <Link
                    to="/services/booking-tables"
                    className="flex items-center w-full gap-2"
                    onClick={handleClickService}
                  >
                    <div className="w-10 h-10 rounded-[10px] bg-[#D9D9D9] overflow-hidden flex-shrink-0">
                      <img
                        loading="lazy"
                        src={tablesTwo}
                        className="w-full h-full object-cover"
                        alt="link"
                      />
                    </div>
                    <p className="text-[#131313] text-base font-medium">
                      {t("links.bookingServices")}
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </li>
        <li className="hover:text-[#4541C1]  flex items-center">
          <NavLink
            className={({ isActive }) =>
              `${isActive ? "text-[#4541C1] py-2" : ""} py-2`
            }
            to="/customer-journey"
          >
            {t("links.ourJourney")}
          </NavLink>
        </li>
        <li className="hover:text-[#4541C1] flex items-center">
          <NavLink
            className={({ isActive }) =>
              `${isActive ? "text-[#4541C1] py-2" : ""} py-2`
            }
            to="/packages"
          >
            {t("links.packages")}
          </NavLink>
        </li>
        <li
          onMouseEnter={handleMouseEnterCommunity}
          onMouseLeave={handleMouseLeaveCommunity}
          className="hover:text-[#4541C1] relative py-2 group flex items-center gap-1"
        >
          <span className="shrink-0 transition-[transform] duration-[400ms] group-hover:-rotate-180">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          <span>{t("links.ourCommunity")}</span>
          {isHoveredCommunity && (
            <div className="absolute z-50 bg-white w-64 rounded-[10px] right-0 top-10 hidden group-hover:flex cursor-default shadow-[0px_34px_64px_0px_#00000026]">
              <ul className="p-1.5 flex flex-col gap-2 w-full">
                <li className="p-1.5 flex items-center w-full gap-2 cursor-pointer hover:bg-[#EFF4FE] rounded-[10px]">
                  <Link
                    to="/blogs"
                    className="flex items-center w-full gap-2"
                    onClick={handleClickCommunity}
                  >
                    <div className="w-10 h-10 rounded-[10px] bg-[#D9D9D9] overflow-hidden flex-shrink-0">
                      <img
                        loading="lazy"
                        src="https://backend.skilltax.sa/public/images/skilltax_frontend.jpeg"
                        className="w-full h-full object-cover"
                        alt="link"
                      />
                    </div>
                    <p className="text-[#131313] text-base font-medium">
                      {t("links.blog")}
                    </p>
                  </Link>
                </li>
                <li className="p-1.5 flex items-center w-full gap-2 cursor-pointer hover:bg-[#EFF4FE] rounded-[10px]">
                  <Link
                    to="/contact-us"
                    className="flex items-center w-full gap-2"
                    onClick={handleClickCommunity}
                  >
                    <div className="w-10 h-10 rounded-[10px] bg-[#D9D9D9] overflow-hidden flex-shrink-0">
                      <img
                        loading="lazy"
                        src={contactUsImage}
                        className="w-full h-full object-cover"
                        alt="link"
                      />
                    </div>
                    <p className="text-[#131313] text-base font-medium">
                      {t("links.contactUs")}
                    </p>
                  </Link>
                </li>
                <li className="p-1.5 flex items-center w-full gap-2 cursor-pointer hover:bg-[#EFF4FE] rounded-[10px]">
                  <Link
                    to="/be-part"
                    className="flex items-center w-full gap-2"
                    onClick={handleClickCommunity}
                  >
                    <div className="w-10 h-10 rounded-[10px] bg-[#D9D9D9] overflow-hidden flex-shrink-0">
                      <img
                        loading="lazy"
                        src={bePartHero}
                        className="w-full h-full object-cover"
                        alt="link"
                      />
                    </div>
                    <p className="text-[#131313] text-base font-medium">
                      {t("links.bePart")}
                    </p>
                  </Link>
                </li>
                <li className="p-1.5 flex items-center w-full gap-2 cursor-pointer hover:bg-[#EFF4FE] rounded-[10px]">
                  <Link
                    to="/referrals"
                    className="flex items-center w-full gap-2"
                    onClick={handleClickCommunity}
                  >
                    <div className="w-10 h-10 rounded-[10px] bg-[#D9D9D9] overflow-hidden flex-shrink-0">
                      <img
                        loading="lazy"
                        src={referralsHero}
                        className="w-full h-full object-cover"
                        alt="link"
                      />
                    </div>
                    <p className="text-[#131313] text-base font-medium">
                      {t("links.referrals")}
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </li>
      </ul>
      <div className="flex items-center gap-[8px] w-fit text-[15px]">
        <button
          aria-label="open sidebar"
          onClick={() => setIsOpen(true)}
          className={`${
            isBlogsPath ? "border-white" : "border-[#E2E9FD]"
          } w-[38px] p-1.5  border  rounded-md flex items-center justify-center xl:hidden`}
        >
          <span>
            <svg
              width="21"
              height="19"
              viewBox="0 0 21 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.16663 1.16663H19.8333"
                stroke={isBlogsPath ? "white" : "#5E5DDE"}
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.16663 9.33325H19.8333"
                stroke={isBlogsPath ? "white" : "#5E5DDE"}
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.16663 17.5H19.8333"
                stroke={isBlogsPath ? "white" : "#5E5DDE"}
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </button>
        <div className="hidden xl:flex items-center gap-2.5">
          <ButtonAuthGroup />
          {(isBlogsPath || isBlogPath) && (
            <SwitchLang isBlogPath={isBlogPath} />
          )}
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const Drawer = ({ isOpen, setIsOpen, isBlogsPath, isBlogPath }) => {
  const { i18n, t } = useTranslation();

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <main
      className={`${
        i18n.language == "ar"
          ? isOpen
            ? "transition-opacity opacity-100 duration-500 translate-x-0"
            : "transition-all delay-500 opacity-0 translate-x-full"
          : isOpen
          ? "transition-opacity opacity-100 duration-500 translate-x-0"
          : "transition-all delay-500 opacity-0 translate-x-full"
      } block xl:hidden fixed overflow-hidden z-50 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out`}
    >
      <div
        className={`${
          i18n.language == "ar"
            ? isOpen
              ? "translate-x-0 overflow-auto"
              : "translate-x-[-100%]"
            : isOpen
            ? "translate-x-0 overflow-auto"
            : "translate-x-[100%]"
        } ${
          i18n.language == "ar" ? "left-0" : "right-0"
        } w-[80%] max-w-96  absolute bg-white min-h-screen h-full delay-400 duration-500 ease-in-out transition-all transform p-5 pb-16 !z-[1] `}
      >
        <div className="flex items-center relative justify-between">
          <button
            onClick={closeDrawer}
            type="button"
            className="text-black bg-transparent hover:bg-[#5e5dde] hover:text-white rounded-lg text-sm w-8 h-8 flex items-center justify-center"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close menu</span>
          </button>
          <div className="absolute left-[50%] top-1 translate-x-[-50%]">
            <Link to="/">
              <img
                loading="lazy"
                src={logo}
                alt="Skilltax Logo"
                className="w-32"
              />
            </Link>
          </div>
        </div>
        <div className="mt-8 mb-6 flex items-center w-full justify-center gap-4">
          <ButtonAuthGroup closeDrawer={closeDrawer} />
          {(isBlogsPath || isBlogPath) && (
            <SwitchLang isBlogPath={isBlogPath} />
          )}
        </div>
        <ul className="flex flex-col text-base font-medium text-[#131313] -mt-5">
          <li>
            <NavLink
              onClick={closeDrawer}
              className={({ isActive }) =>
                `${isActive && "text-[#4541C1]"}
                w-full h-full block border-b border-[#F5F5F5] pb-2 pt-4`
              }
              to="/"
            >
              {t("links.home")}
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={closeDrawer}
              className={({ isActive }) =>
                `${isActive && "text-[#4541C1]"}
                " w-full h-full block border-b border-[#F5F5F5] pb-2 pt-4`
              }
              to="/about-us"
            >
              {t("links.aboutUs")}
            </NavLink>
          </li>
          <li>
            <details className="group [&_summary::-webkit-details-marker]:hidden">
              <summary className="border-b group-open:border-0 border-[#F5F5F5] pb-2 cursor-pointer flex items-center justify-between w-full h-full pt-4">
                <span className="text-base font-medium text-[#131313]">
                  {t("links.ourSectors")}
                </span>

                <span className="shrink-0 transition duration-300 group-open:-rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <ul className="mt-2 text-sm  border-[#F5F5F5] ">
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive && "text-[#4541C1]"}
                      " w-full h-full block border-b border-[#F5F5F5] pb-3 pt-4 px-4`
                    }
                    to="/sectors/restaurant-sector"
                    onClick={closeDrawer}
                  >
                    {t("links.restaurantSectors")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive && "text-[#4541C1]"}
                      " w-full h-full block border-b border-[#F5F5F5] pb-3 pt-4 px-4`
                    }
                    to="/sectors/cafes-sector"
                    onClick={closeDrawer}
                  >
                    {t("links.cafesSectors")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive && "text-[#4541C1]"}
                      " w-full h-full block border-b border-[#F5F5F5] pb-3 pt-4 px-4`
                    }
                    to="/sectors/foodtruck-sector"
                    onClick={closeDrawer}
                  >
                    {t("links.foodtruckSectors")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive && "text-[#4541C1]"}
                      " w-full h-full block border-b border-[#F5F5F5] pb-3 pt-4 px-4`
                    }
                    to="/sectors/connectivity-sector"
                    onClick={closeDrawer}
                  >
                    {t("links.connectivitySectors")}
                  </NavLink>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <details className="group [&_summary::-webkit-details-marker]:hidden">
              <summary className="border-b group-open:border-0 border-[#F5F5F5] pb-2 cursor-pointer flex items-center justify-between pt-4">
                <span className="text-base font-medium text-[#131313]">
                  {t("links.ourServices")}
                </span>

                <span className="shrink-0 transition duration-300 group-open:-rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </summary>
              <ul className="mt-2 text-sm border-[#F5F5F5]">
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive && "text-[#4541C1]"}
                      " w-full h-full block border-b border-[#F5F5F5] pb-3 pt-4 px-4`
                    }
                    to="/services/menu"
                    onClick={closeDrawer}
                  >
                    {t("links.menuServices")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive && "text-[#4541C1]"}
                      " w-full h-full block border-b border-[#F5F5F5] pb-3 pt-4 px-4`
                    }
                    to="/services/tod"
                    onClick={closeDrawer}
                  >
                    {t("links.todServices")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive && "text-[#4541C1]"}
                      " w-full h-full block border-b border-[#F5F5F5] pb-3 pt-4 px-4`
                    }
                    to="/services/waiter"
                    onClick={closeDrawer}
                  >
                    {t("links.waiterServices")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive && "text-[#4541C1]"}
                      " w-full h-full block border-b border-[#F5F5F5] pb-3 pt-4 px-4`
                    }
                    to="/services/special"
                    onClick={closeDrawer}
                  >
                    {t("links.specialServices")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive && "text-[#4541C1]"}
                      " w-full h-full block border-b border-[#F5F5F5] pb-3 pt-4 px-4`
                    }
                    to="/services/restaurant-ordering"
                    onClick={closeDrawer}
                  >
                    {t("links.orderingServices")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive && "text-[#4541C1]"}
                      " w-full h-full block border-b border-[#F5F5F5] pb-3 pt-4 px-4`
                    }
                    to="/services/booking-tables"
                    onClick={closeDrawer}
                  >
                    {t("links.bookingServices")}
                  </NavLink>
                </li>
              </ul>
            </details>
          </li>

          <li>
            <NavLink
              onClick={closeDrawer}
              className={({ isActive }) =>
                `${isActive && "text-[#4541C1]"}
                " w-full h-full block border-b border-[#F5F5F5] pb-2 pt-4`
              }
              to="/customer-journey"
            >
              {t("links.ourJourney")}
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={closeDrawer}
              className={({ isActive }) =>
                `${isActive && "text-[#4541C1]"}
                " w-full h-full block border-b border-[#F5F5F5] pb-2 pt-4`
              }
              to="/packages"
            >
              {t("links.packages")}
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={closeDrawer}
              className={({ isActive }) =>
                `${isActive && "text-[#4541C1]"}
                " w-full h-full block border-b border-[#F5F5F5] pb-2 pt-4`
              }
              to="/contact-us"
            >
              {t("links.contactUs")}
            </NavLink>
          </li>

          <li>
            <details className="group [&_summary::-webkit-details-marker]:hidden">
              <summary className="border-b group-open:border-0 border-[#F5F5F5] pb-2 cursor-pointer flex items-center justify-between pt-4">
                <span className="text-base font-medium text-[#131313]">
                  {t("links.ourCommunity")}
                </span>

                <span className="shrink-0 transition duration-300 group-open:-rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </summary>
              <ul className="mt-2 text-sm border-[#F5F5F5]">
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive && "text-[#4541C1]"}
                      " w-full h-full block border-b border-[#F5F5F5] pb-3 pt-4 px-4`
                    }
                    to="/blogs"
                    onClick={closeDrawer}
                  >
                    {t("links.blog")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive && "text-[#4541C1]"}
                      " w-full h-full block border-b border-[#F5F5F5] pb-3 pt-4 px-4`
                    }
                    to="/contact-us"
                    onClick={closeDrawer}
                  >
                    {t("links.contactUs")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive && "text-[#4541C1]"}
                      " w-full h-full block border-b border-[#F5F5F5] pb-3 pt-4 px-4`
                    }
                    to="/be-part"
                    onClick={closeDrawer}
                  >
                    {t("links.bePart")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive && "text-[#4541C1]"}
                      " w-full h-full block border-b border-[#F5F5F5] pb-3 pt-4 px-4`
                    }
                    to="/referrals"
                    onClick={closeDrawer}
                  >
                    {t("links.referrals")}
                  </NavLink>
                </li>
              </ul>
            </details>
          </li>
        </ul>
      </div>
      <div
        className=" w-screen h-full cursor-pointer "
        onClick={() => {
          setIsOpen(false);
        }}
      ></div>
    </main>
  );
};

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const isBlogsPath = /^\/blogs\/\d+$/.test(pathname) || false;
  const isBlogPath = /^\/blog\/\d+$/.test(pathname) || false;
  return (
    <>
      {!isBlogsPath && !isBlogPath && (
        <>
          {/* <TopMarquee text={"links.trialVersion"} /> */}
          <TopHeader />
        </>
      )}
      <MainHeader
        isBlogsPath={isBlogsPath}
        isBlogPath={isBlogPath}
        setIsOpen={setIsOpen}
      />
      <Drawer
        isBlogsPath={isBlogsPath}
        isBlogPath={isBlogPath}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default Header;
