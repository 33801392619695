import { useTranslation } from "react-i18next";
import { sent } from "../../assets";

const Sent = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-28 rounded-[15px] border border-[#13B601] shadow-[0px_25px_60px_0px_#C1C1C140] flex items-center p-5 gap-3">
      <div className="self-start w-16 h-16 flex-s">
        <img src={sent} alt="sent" loading="lazy" className="w-full" />
      </div>
      <div>
        <h5 className="text-xl font-medium text-[#131313]">
          {t("forms.send")}
        </h5>
        <p className="text-[#62646C] text-base">{t("forms.thanks")}</p>
      </div>
    </div>
  );
};

export default Sent;
