import { useEffect } from "react";
import {
  orderingOne,
  orderingTwo,
  orderingCustomers,
  whyImageTwo,
  esayOrderTwo,
  ordersSales,
} from "../../assets";
import Points from "../../components/Home/Points";
import EngineSeo from "../../Engine/EngineSeo";
import { useTranslation } from "react-i18next";
const SEO_SERVICES = {
  en: {
    title:
      "SkillTax - Order from Restaurant | Efficient Ordering Solutions Integrated with POS",
    description:
      "Explore the SkillTax Order from Restaurant service page to learn how our ordering solutions integrate seamlessly with our POS system. Discover how our system streamlines the ordering process, enhancing customer experience and operational efficiency.",
    keywords:
      "Order from Restaurant, SkillTax POS System, Ordering Solutions, POS Integration, SkillTax Services, Restaurant Ordering System, Efficient Ordering, Customer Experience Enhancement",
  },
  ar: {
    title:
      "SkillTax - الطلب من المطعم | حلول طلب فعّالة متكاملة مع نظام نقاط البيع",
    description:
      "استكشف صفحة خدمة الطلب من المطعم الخاصة بسكيل تاكس لتتعرف على كيفية تكامل حلول الطلب مع نظام نقاط البيع لدينا. اكتشف كيف يعزز نظامنا عملية الطلب، مما يحسن تجربة العملاء وكفاءة العمليات.",
    keywords:
      "خدمة الطلب من المطعم, نظام سكيل تاكس, حلول الطلب, تكامل نقاط البيع, خدمات سكيل تاكس, نظام طلب المطعم, طلب فعّال, تحسين تجربة العملاء",
  },
};

const Ordering = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EngineSeo {...SEO_SERVICES} />
      <section className="bg-white">
        <div className="flex items-center flex-col-reverse md:flex-row pt-8 p-4 md:p-0">
          <div className="flex-1 w-full flex items-center md:justify-center md:px-4">
            <div className="max-w-[570px] text-start mt-4 md:mt-0">
              <h3 className="text-[40px] leading-[56px] xl:text-[70px] xl:leading-[78px] text-[#131313] font-medium">
                {t("links.orderingServices")}
              </h3>
              <p className="text-[#62646C] text-xl xl:text-2xl text-start md:mt-2">
                {t("orderingServices.mainText")}
              </p>
            </div>
          </div>
          <div className="flex-1 flex items-center gap-1 justify-end">
            <div className="rounded-[20px] md:rounded-none overflow-hidden">
              <img
                loading="lazy"
                src={orderingTwo}
                alt="ordering"
                className="w-full object-cover max-w-full"
              />
            </div>
            <div className="hidden sm:block rounded-[20px] md:rounded-none overflow-hidden">
              <img
                loading="lazy"
                src={orderingOne}
                alt="ordering"
                className="w-full object-cover max-w-full"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex items-center justify-between gap-6 flex-col-reverse md:flex-row">
            <div className="font-medium max-w-[620px]">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("orderingServices.relationship")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("orderingServices.relationshipText")}
              </p>
              <p className="text-[#62646C] text-xl relative ps-4 mt-2">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("orderingServices.relationshipTextTwo")}
              </p>
            </div>
            <div>
              <img loading="lazy" src={orderingCustomers} alt="customers" />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col gap-10 md:gap-20 md:flex-row items-center justify-between">
            <div className="max-w-[500px] h-fit w-fit bg-white rounded-[10px] p-5 flex gap-2 relative">
              <div>
                <img loading="lazy" src={esayOrderTwo} alt="esay-order" />
              </div>
              <div>
                <ul className="h-[60%] mb-2 rounded-xl bg-[#5E5DDE] px-2 md:px-1 lg:px-2 py-3 flex flex-col font-medium text-white text-lg md:text-base lg:text-2xl">
                  <li className="px-2 flex-1 flex items-center justify-start">
                    {t("orderingServices.ease")}
                  </li>
                  <li className="bg-white bg-opacity-10 px-2 flex-1 flex items-center justify-start rounded-[10px]">
                    {t("orderingServices.interaction")}
                  </li>
                  <li className="px-2 flex-1 flex items-center justify-start">
                    {t("orderingServices.flexibility")}
                  </li>
                </ul>
                <img
                  loading="lazy"
                  src={whyImageTwo}
                  alt="why"
                  className="h-[50%] overflow-hidden rounded-[10px] object-cover"
                />
              </div>
            </div>
            <div className="w-full lg:w-[370px] xl:w-[520px] font-medium ">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("orderingServices.ordering")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3 xl:w-[400px]">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("orderingServices.orderingText")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex items-center justify-between gap-0 flex-col-reverse md:flex-row">
            <div className="font-medium max-w-[500px]">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("orderingServices.increase")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("orderingServices.increaseText")}
              </p>
              <p className="text-[#62646C] text-xl relative ps-4 mt-2">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("orderingServices.increaseTextTwo")}
              </p>
            </div>
            <div>
              <img loading="lazy" src={ordersSales} alt="orders-sales" />
            </div>
          </div>
        </div>
      </section>
      <Points showShadow />
    </>
  );
};

export default Ordering;
