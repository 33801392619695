import { useTranslation } from "react-i18next";
import { contactUsIcons, contanctMsg } from "../assets";
import ContactUs from "../components/Layouts/ContactUs";
import EngineSeo from "../Engine/EngineSeo";

const SEO_CONTACT = {
  en: {
    title: "SkillTax - Contact Us | Get in Touch with Our Support Team",
    description:
      "Visit SkillTax's Contact Us page to reach out to our support team for assistance. Whether you have questions, need support, or want to provide feedback, we are here to help you with all your inquiries.",
    keywords:
      "Contact Us, SkillTax Support, Customer Support, Reach Out, Get in Touch, Support Team, SkillTax Assistance, Contact SkillTax",
  },
  ar: {
    title: "SkillTax - تواصل معنا | تواصل مع فريق الدعم لدينا",
    description:
      "قم بزيارة صفحة تواصل معنا الخاصة بسكيل تاكس للوصول إلى فريق الدعم لدينا للحصول على المساعدة. سواء كان لديك استفسارات، تحتاج إلى دعم، أو ترغب في تقديم ملاحظات، نحن هنا لمساعدتك في جميع استفساراتك.",
    keywords:
      "تواصل معنا, دعم سكيل تاكس, خدمة العملاء, تواصل, اتصل بنا, فريق الدعم, مساعدة سكيل تاكس, الاتصال بسكيل تاكس",
  },
};

const Contact = () => {
  const { t } = useTranslation();

  return (
    <>
      <EngineSeo {...SEO_CONTACT} />
      <div className="w-full h-full mt-2 sm:mt-4 lg:mt-16">
        <div className="banner-container">
          <div className="w-full h-44 sm:h-64 lg:h-[300px] bg-[#EFF4FE] lg:rounded-[30px] px-4 py-8 lg:px-36 lg:py-4 relative justify-between flex items-center overflow-hidden">
            <div className="absolute -left-24 z-0">
              <img loading="lazy" src={contactUsIcons} alt="icons" />
            </div>
            <div className="hidden lg:block absolute -right-60 z-0">
              <img loading="lazy" src={contactUsIcons} alt="icons" />
            </div>
            <div className="relative z-10">
              <h3 className="text-3xl text-black font-bold mb-2">
                {t("contactUs.contactUsNow")}
              </h3>
              <p className="text-[#62646C] text-lg max-w-[440px]">
                {t("contactUs.text")}
              </p>
            </div>
            <div className="bg-[#5E5DDE] rounded-3xl w-48 h-48 hidden items-center justify-center relative z-10 lg:flex">
              <img src={contanctMsg} alt="massage" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
      <ContactUs />
    </>
  );
};

export default Contact;
