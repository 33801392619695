import { useEffect } from "react";
import {
  waiterOne,
  waiterTwo,
  waitersGroup1,
  waitersGroup2,
  waiterLine,
  waiterInterconnection,
  waiterMask,
} from "../../assets";
import Points from "../../components/Home/Points";
import EngineSeo from "../../Engine/EngineSeo";
import { useTranslation } from "react-i18next";
const SEO_SERVICES = {
  en: {
    title:
      "SkillTax - Waiter System | Streamlined Service Management for Restaurants",
    description:
      "Explore the SkillTax Waiter System page to learn how our POS system enhances service management with dedicated waiter features. Discover how our system supports efficient order taking and improves restaurant operations.",
    keywords:
      "Waiter System, SkillTax POS System, Restaurant Service Management, POS for Waiters, SkillTax Features, Order Taking Solutions, Efficient Service Management, Restaurant POS System",
  },
  ar: {
    title: "SkillTax - نظام الويتر | إدارة الخدمة بشكل سلس للمطاعم",
    description:
      "استكشف صفحة نظام الويتر الخاصة بسكيل تاكس لتتعرف على كيفية تحسين إدارة الخدمة من خلال ميزات الويتر المخصصة في نظام نقاط البيع لدينا. اكتشف كيف يدعم نظامنا عملية أخذ الطلبات بكفاءة ويحسن عمليات المطاعم.",
    keywords:
      "نظام الويتر, نظام سكيل تاكس, إدارة خدمة المطاعم, نقاط البيع للويتر, ميزات سكيل تاكس, حلول أخذ الطلبات, إدارة الخدمة بكفاءة, نظام نقاط البيع للمطاعم",
  },
};

const Waiter = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EngineSeo {...SEO_SERVICES} />
      <section className="bg-white">
        <div className="flex items-center flex-col-reverse md:flex-row pt-8 p-4 md:p-0">
          <div className="flex-1 w-full flex items-center md:justify-center md:px-4">
            <div className="max-w-[570px] text-start mt-4 md:mt-0">
              <h3 className="text-[40px] leading-[56px] xl:text-[70px] xl:leading-[98px] text-[#131313] font-medium">
                {t("links.waiterServices")}
              </h3>
              <p className="text-[#62646C] text-xl xl:text-2xl text-start md:mt-2">
                {t("waiterServices.mainText")}
              </p>
            </div>
          </div>
          <div className="flex-1 flex items-center gap-1 justify-end">
            <div className="rounded-[20px] md:rounded-none overflow-hidden">
              <img
                loading="lazy"
                src={waiterTwo}
                alt="waiter"
                className="w-full object-cover max-w-full"
              />
            </div>
            <div className="hidden sm:block rounded-[20px] md:rounded-none overflow-hidden">
              <img
                loading="lazy"
                src={waiterOne}
                alt="waiter"
                className="w-full object-cover max-w-full"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex items-center justify-between gap-6 flex-col-reverse md:flex-row">
            <div className="font-medium max-w-[590px]">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("waiterServices.processing")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("waiterServices.processingText")}
              </p>
              <p className="text-[#62646C] text-xl relative ps-4 mt-2">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("waiterServices.processingTextTwo")}
              </p>
            </div>
            <div
              dir="rtl"
              className="bg-white rounded-[10px] px-2 py-3 gap-4 flex items-center h-full"
            >
              <div className="w-fit">
                <div
                  className={`${
                    i18n.language == "ar"
                      ? "lg:text-[40px] lg:leading-[48px]"
                      : "text-xl"
                  } py-5 max-w-[224px] font-medium px-1 rounded-[20px] bg-[#6C74E8] border-[3px] border-[#E2E9FD] text-3xl  text-[#F6F7FF] flex items-center justify-center mb-[10px]`}
                >
                  {t("waiterServices.performance")}
                </div>
                <img loading="lazy" src={waitersGroup2} alt="waiter" />
              </div>
              <div className="w-fit relative">
                <img loading="lazy" src={waitersGroup1} alt="waiter" />
                <span className="absolute bottom-8 lg:bottom-10 -right-24">
                  <img loading="lazy" src={waiterLine} alt="line" />
                </span>
                <div
                  className={`${
                    i18n.language == "ar" ? "lg:text-3xl" : "text-2xl"
                  } py-1 lg:py-2 font-medium px-3 text-2xl  text-[#131313] rounded-[20px] bg-[#6C74E8] bg-opacity-15 border-[3px] border-[#E2E9FD] flex items-center justify-start mt-[10px]`}
                >
                  {t("waiterServices.ease")}
                  <br />
                  {t("waiterServices.smooth")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col gap-6 md:gap-20 md:flex-row items-center justify-between">
            <div className="max-w-[500px] py-2 px-4 w-fit relative bg-white rounded-[10px]">
              <span className="absolute text-3xl md:text-xl lg:text-[40px] lg:leading-[48px] text-white right-[32px] top-[32px] lg:top-[52px] font-medium">
                {t("waiterServices.interconnection")}
              </span>
              <img
                loading="lazy"
                src={waiterInterconnection}
                alt="waiter-interconnection"
              />
            </div>
            <div className="w-full lg:w-[370px] xl:w-[520px] font-medium">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("waiterServices.promotion")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("waiterServices.promotionText")}
              </p>
              <p className="text-[#62646C] text-xl relative ps-4 mt-2">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("waiterServices.promotionTextTwo")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex items-center justify-between gap-6 flex-col-reverse md:flex-row">
            <div className="font-medium max-w-[520px]">
              <h4 className="text-[#131313] text-2xl lg:text-[40px] lg:leading-[48px]">
                {t("waiterServices.restaurant")}
              </h4>
              <p className="text-[#62646C] text-xl relative ps-4 mt-3">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("waiterServices.restaurantText")}
              </p>
              <p className="text-[#62646C] text-xl relative ps-4 mt-2">
                <span
                  className={`${
                    i18n.language == "ar" ? "right-0" : "left-0"
                  } absolute block w-1.5 h-1.5 bg-[#62646C] rounded-full top-3.5`}
                ></span>
                {t("waiterServices.restaurantTextTwo")}
              </p>
            </div>
            <div className="relative w-fit">
              <h4
                className={`${
                  i18n.language == "ar" ? "left-[20%]" : "left-[7%]"
                } text-3xl lg:text-[40px] lg:leading-[48px] absolute text-white font-medium top-[14.5%]`}
              >
                {t("waiterServices.excellence")}
              </h4>
              <img loading="lazy" src={waiterMask} alt="waiter" />
            </div>
          </div>
        </div>
      </section>
      <Points showShadow />
    </>
  );
};

export default Waiter;
