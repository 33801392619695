import { useEffect } from "react";
import {
  connectivityPage,
  connectivityBilling,
  zakatAuthority,
} from "../../assets";
import EngineSeo from "../../Engine/EngineSeo";
import { useTranslation } from "react-i18next";
const SEO_SECTOR = {
  en: {
    title:
      "SkillTax - Integration with Service Providers | Seamless POS System Connectivity",
    description:
      "Visit the SkillTax Integration with Service Providers page to learn how our POS system integrates seamlessly with various service providers. Discover the benefits of our integration solutions and how they enhance your restaurant and point of sale operations.",
    keywords:
      "Integration with Service Providers, SkillTax POS System, POS System Integration, Service Provider Connectivity, SkillTax Integration Solutions, Restaurant Management Integration, POS Connectivity",
  },
  ar: {
    title:
      "SkillTax - الرّبط مع مُزوّدي الخدمات | تواصل سلس مع نظام نقاط البيع",
    description:
      "زر صفحة الرّبط مع مُزوّدي الخدمات الخاصة بسكيل تاكس لتتعلم كيف يندمج نظامنا بسلاسة مع مختلف مُزوّدي الخدمات. اكتشف فوائد حلول التكامل لدينا وكيف تعزز عمليات إدارة المطاعم ونقاط البيع.",
    keywords:
      "الرّبط مع مُزوّدي الخدمات, نظام سكيل تاكس, تكامل نظام نقاط البيع, تواصل مع مُزوّدي الخدمات, حلول تكامل سكيل تاكس, تكامل إدارة المطاعم, اتصال نظام نقاط البيع",
  },
};

const Connectivity = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EngineSeo {...SEO_SECTOR} />
      <section className="w-full">
        <div className="container">
          <div className="py-10 md:py-12 lg:py-20 text-center relative -z-[1]">
            <div className="shadow-[0px_0px_330px_30px_#5E5DDE] sm:shadow-[0px_0px_290px_60px_#5E5DDE]  left-[50%] translate-x-[-50%] absolute -z-[1]"></div>
            <h3 className="main-heading mx-auto">
              {t("links.connectivitySectors")}
            </h3>
          </div>
          <div className="flex items-center gap-24 flex-col">
            <div className="flex flex-col-reverse p-[10px] md:p-0 md:flex-row items-center w-full md:h-[400px] bg-white overflow-hidden rounded-xl">
              <div className="flex-[1.4] py-8 md:py-0 px-3 md:px-0  !ps-3 lg:!ps-5 relative h-[400px] flex flex-col justify-center">
                <h4 className="text-2xl font-bold lg:text-3xl l text-[#131313]">
                  {t("connectivitySectors.integration")}
                </h4>
                <p className="text-lg lg:text-2xl mt-3 max-w-[580px] text-[#62646C]">
                  {t("connectivitySectors.integrationText")}
                </p>
              </div>
              <div className="md:flex-1">
                <img
                  loading="lazy"
                  className="max-w-full w-full rounded-xl md:rounded-none md:h-[400px] relative z-10 object-cover"
                  src={connectivityPage}
                  alt="banner"
                />
              </div>
            </div>
            <div className="flex flex-col-reverse p-[10px] md:p-0 md:flex-row-reverse items-center w-full md:h-[400px] bg-white overflow-hidden rounded-xl">
              <div className="flex-[1.4] py-8 md:py-0 px-3 md:px-0  !ps-3 lg:!ps-5 relative h-[400px] flex flex-col justify-center">
                <h4 className="text-2xl font-bold lg:text-3xl l text-[#131313]">
                  {t("connectivitySectors.billing")}
                </h4>
                <p className="text-lg lg:text-2xl mt-3 max-w-[580px] text-[#62646C]">
                  {t("connectivitySectors.billingText")}
                </p>
              </div>
              <div className="md:flex-1 h-full bg-[#FAFAFA]">
                <img
                  loading="lazy"
                  className="max-w-full w-full rounded-xl md:rounded-none md:h-[400px] relative z-10 object-cover mt-2"
                  src={connectivityBilling}
                  alt="banner"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-10 md:-mb-28 md:mt-44">
        <div className="container">
          <div className="flex items-center justify-center flex-col-reverse md:flex-col relative">
            <div className="shadow-[0px_0px_750px_70px_#6c74e8b8] sm:shadow-[0px_0px_750px_160px_#6c74e8b8] top-24 left-[50%] translate-x-[-50%] absolute -z-[1]"></div>
            <h5 className="max-w-[262px] -ml-2 md:-ml-16 md:max-w-[430px] font-medium text-black text-xl md:text-3xl md:leading-[45px] text-center">
              {t("connectivitySectors.zakat")}
            </h5>
            <p className="-mb-14 md:mb-0 md:-mt-28">
              <img
                loading="lazy"
                src={zakatAuthority}
                alt="zakat"
                className="object-cover mx-auto w-full"
              />
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Connectivity;
