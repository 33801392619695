import { useEffect } from "react";
import {
  bannerRectangle,
  foodBanner,
  foodBanner2,
  foodBanner3,
  charts,
  foodConnectivity,
  foodtod,
  foodPayments,
  footPhone,
} from "../../assets";
import Badge from "../../components/Layouts/Badge";
import EngineSeo from "../../Engine/EngineSeo";
import { useTranslation } from "react-i18next";

const SEO_SECTOR = {
  en: {
    title:
      "SkillTax - Food Truck Sector | Optimized POS Solutions for Mobile Food Services",
    description:
      "Explore the SkillTax Food Truck Sector page to see how our POS system is tailored for mobile food services. Learn about the features and benefits designed specifically for food trucks to enhance efficiency and manage sales on the go.",
    keywords:
      "Food Truck Sector, SkillTax POS System, Mobile Food Service Solutions, POS for Food Trucks, SkillTax Features, Food Truck Management, Mobile POS System, Food Truck Sales Management",
  },
  ar: {
    title:
      "SkillTax - قطاع الفُود ترك | حلول نقاط البيع المخصصة لخدمات الطعام المتنقلة",
    description:
      "استكشف صفحة قطاع الفُود ترك الخاصة بسكيل تاكس لتتعرف على كيفية تخصيص نظامنا لخدمات الطعام المتنقلة. تعرف على الميزات والفوائد المصممة خصيصًا للفُود ترك لتعزيز الكفاءة وإدارة المبيعات أثناء التنقل.",
    keywords:
      "قطاع الفُود ترك, نظام سكيل تاكس, حلول خدمة الطعام المتنقلة, نقاط البيع للفُود ترك, ميزات سكيل تاكس, إدارة الفُود ترك, نظام نقاط البيع المتنقل, إدارة مبيعات الفُود ترك",
  },
};

const FoodTruckSector = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EngineSeo {...SEO_SECTOR} />
      <section className="w-full">
        <div className="container">
          <div className="py-10 md:py-12 lg:py-20 text-center relative -z-[1]">
            <div className="shadow-[0px_0px_330px_30px_#5E5DDE] sm:shadow-[0px_0px_290px_60px_#5E5DDE] left-[50%] translate-x-[-50%] absolute -z-[1]"></div>
            <h3 className="main-heading mx-auto">
              {t("links.foodtruckSectors")}
            </h3>
          </div>
          <div className="flex flex-col md:flex-row items-center w-full md:h-[450px] bg-[#4541C1] overflow-hidden rounded-xl">
            <div className="md:flex-1">
              <img
                loading="lazy"
                className="max-w-full w-full md:h-[450px] relative z-10 object-cover"
                src={foodBanner}
                alt="banner"
              />
            </div>
            <div className="flex-1 py-8 md:py-0 px-3 md:px-0  !ps-3 lg:!ps-5 text-white relative h-[450px] flex flex-col justify-center">
              <div className="hidden lg:block absolute top-0 -right-10">
                <img
                  loading="lazy"
                  src={bannerRectangle}
                  alt="banner-rectangle"
                />
              </div>
              <div className="absolute top-20 -left-[136px] rotate-90">
                <img
                  loading="lazy"
                  src={bannerRectangle}
                  alt="banner-rectangle"
                />
              </div>
              <h4 className="text-2xl font-medium lg:text-[35px] lg:leading-[50px] text-[#E2E9FD]">
                {t("links.foodtruckSectors")}
              </h4>
              <p className="text-lg lg:text-2xl mt-3 max-w-[450px]">
                {t("foodtruckSectors.mainText")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col-reverse md:flex-row items-center justify-between">
            <div className="max-w-[500px]">
              <Badge>{t("heading.payments")}</Badge>
              <h5 className="text-black font-medium text-2xl lg:text-3xl">
                {t("foodtruckSectors.payments")}
              </h5>
              <p className="text-[#62646C] font-medium text-xl lf:text-2xl mt-2">
                {t("foodtruckSectors.paymentsText")}
              </p>
            </div>
            <div>
              <img loading="lazy" src={foodPayments} alt="tod" />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col md:gap-20 md:flex-row items-center justify-between">
            <div className="max-w-[500px]">
              <img loading="lazy" src={footPhone} alt="food-phone" />
            </div>
            <div className="w-full lg:w-[370px] xl:w-[512px] md:pb-28">
              <Badge>{t("heading.management")}</Badge>
              <h5 className="text-black font-medium text-2xl lg:text-3xl">
                {t("foodtruckSectors.management")}
              </h5>
              <p className="text-[#62646C] font-medium text-xl lf:text-2xl mt-2">
                {t("foodtruckSectors.managementText")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="banner-container mx-auto">
          <div className="flex flex-col lg:flex-row items-center w-full lg:h-[613px] bg-[#4541C1] p-[10px] lg:p-0 overflow-hidden md:rounded-xl">
            <div className="flex-1 flex flex-col text-white relative h-[613px]">
              <div className="flex flex-col justify-center flex-[1.2] py-10">
                <div className="absolute -top-10 mlg:top-0 -right-10">
                  <img
                    loading="lazy"
                    src={bannerRectangle}
                    alt="banner-rectangle"
                  />
                </div>
                <div className="hidden lg:block absolute top-20 -left-[136px] rotate-90">
                  <img
                    loading="lazy"
                    src={bannerRectangle}
                    alt="banner-rectangle"
                  />
                </div>
                <p className="text-2xl font-medium lg:text-[30px] lg:leading-[50px] text-[#E2E9FD] px-4">
                  {t("foodtruckSectors.secondaryText")}
                </p>
                <p className="text-xl font-medium  text-[#CAD6FB] mt-2 px-4">
                  {t("foodtruckSectors.thirdText")}
                </p>
              </div>
              <div className="flex-1">
                <img
                  loading="lazy"
                  className="max-w-full w-full relative z-10 object-cover h-[220px] lg:h-full rounded-[10px] md:rounded-none"
                  src={foodBanner3}
                  alt="banner"
                />
              </div>
            </div>
            <div className="hidden lg:block lg:flex-1">
              <img
                loading="lazy"
                className="max-w-full w-full lg:h-[613px] relative z-10 object-cover"
                src={foodBanner2}
                alt="banner"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col-reverse md:flex-row gap-5 items-center justify-between">
            <div className="max-w-[500px]">
              <Badge> {t("heading.follow")}</Badge>
              <h5 className="text-black font-medium text-2xl lg:text-3xl">
                {t("foodtruckSectors.commercial")}
              </h5>
              <p className="text-[#62646C] font-medium text-xl lf:text-2xl mt-2">
                {t("foodtruckSectors.commercialText")}
              </p>
            </div>
            <div>
              <img loading="lazy" src={charts} alt="charts" />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col gap-10 md:flex-row items-center justify-between">
            <div className="max-w-[500px] md:-mr-6">
              <img loading="lazy" src={foodtod} alt="food-tod" />
            </div>
            <div className="w-full lg:w-[420px] xl:w-[444px]">
              <Badge>{t("heading.integration")}</Badge>
              <h5 className="text-black font-medium text-2xl lg:text-3xl">
                {t("restaurantSectors.tod")}
              </h5>
              <p className="text-[#62646C] font-medium text-xl lf:text-2xl mt-2">
                {t("restaurantSectors.todText")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <div className="flex flex-col-reverse md:flex-row gap-10 items-center justify-between">
            <div className="max-w-[500px]">
              <Badge>{t("heading.integration")}</Badge>
              <h5 className="text-black font-medium text-2xl lg:text-3xl">
                {t("restaurantSectors.delivery")}
              </h5>
              <p className="text-[#62646C] font-medium text-xl lf:text-2xl mt-2">
                {t("restaurantSectors.deliveryText")}
              </p>
            </div>
            <div>
              <img
                loading="lazy"
                src={foodConnectivity}
                alt="food-connectivity"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FoodTruckSector;
