import { Outlet } from "react-router-dom";
import Header from "./components/Layouts/Header";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "./context/AppContext";
import { lazy, Suspense } from "react";
const Footer = lazy(() => import("./components/Layouts/Footer"));
const Toaster = lazy(() =>
  import("react-hot-toast").then((module) => ({ default: module.Toaster }))
);

const App = () => {
  const { i18n } = useTranslation();
  const { fetchFqs, fetchPrivacies, fetchCities, fetchblogDepartmentList } =
    useContext(AppContext);

  useEffect(() => {
    // Fetch data in parallel
    Promise.all([
      fetchFqs(),
      fetchPrivacies(),
      fetchCities(),
      fetchblogDepartmentList(),
    ])
      .then(() => {
        console.log("All data fetched");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const updateHtmlLangDir = () => {
      const currentLanguage = i18n.language;

      document.documentElement.setAttribute(
        // i18n.dir()
        "dir",
        i18n.language == "ar" ? "rtl" : "ltr"
      );
      document.documentElement.setAttribute("lang", currentLanguage);
    };

    i18n.on("languageChanged", updateHtmlLangDir);

    // Initial call to set the attributes
    updateHtmlLangDir();

    return () => {
      i18n.off("languageChanged", updateHtmlLangDir);
    };
  }, []);

  return (
    <>
      <Suspense fallback={<div>Loading Toaster...</div>}>
        <Toaster
          position={i18n.language == "ar" ? "top-left" : "top-right"}
          reverseOrder={false}
        />
      </Suspense>
      <a
        aria-label="Contact us via WhatsApp"
        href="https://wa.me/+966553559038"
        target="_blank"
        className={`bg-[#25d366] z-[999] group  w-12 h-12 p-[10px] pb-3 pl-3 sm:w-14 sm:h-14 rounded-full flex items-center justify-center fixed right-7 bottom-7 sm:bottom-10 sm:right-10 transition-all duration-300 ease-in`}
      >
        <svg
          className=" group-hover:scale-110 transition-all"
          width="40"
          height="39"
          viewBox="0 0 49 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.055 6.98406C38.8436 4.76149 36.2096 2.99928 33.3066 1.8002C30.4036 0.601123 27.2897 -0.0108133 24.1465 8.19315e-05C10.9766 8.19315e-05 0.242953 10.68 0.242953 23.784C0.242953 27.984 1.3525 32.064 3.42687 35.664L0.0499878 47.9999L12.7133 44.688C16.2108 46.5839 20.1424 47.5919 24.1465 47.5919C37.3163 47.5919 48.05 36.912 48.05 23.808C48.05 17.448 45.5656 11.472 41.055 6.98406ZM24.1465 43.56C20.5766 43.56 17.0791 42.6 14.0158 40.8L13.2922 40.368L5.76657 42.336L7.76858 35.04L7.28617 34.296C5.30284 31.1447 4.24972 27.5022 4.24697 23.784C4.24697 12.888 13.1716 4.00807 24.1223 4.00807C29.4289 4.00807 34.4218 6.07206 38.1605 9.81605C40.0118 11.6496 41.4788 13.8304 42.4767 16.2323C43.4745 18.6341 43.9833 21.2091 43.9736 23.808C44.0218 34.704 35.0972 43.56 24.1465 43.56ZM35.049 28.776C34.446 28.488 31.5032 27.048 30.9726 26.832C30.4178 26.64 30.0319 26.544 29.6218 27.12C29.2118 27.72 28.0781 29.064 27.7404 29.448C27.4027 29.856 27.0409 29.904 26.4379 29.592C25.8349 29.304 23.9053 28.656 21.6379 26.64C19.853 25.056 18.6711 23.112 18.3093 22.512C17.9716 21.912 18.261 21.6 18.5746 21.288C18.8399 21.024 19.1776 20.592 19.4671 20.256C19.7565 19.92 19.8771 19.656 20.0701 19.272C20.2631 18.864 20.1666 18.528 20.0218 18.24C19.8771 17.952 18.6711 15.024 18.1887 13.824C17.7063 12.672 17.1997 12.816 16.8379 12.792H15.6801C15.2701 12.792 14.643 12.936 14.0882 13.536C13.5575 14.136 12.0138 15.576 12.0138 18.504C12.0138 21.432 14.1605 24.264 14.45 24.648C14.7394 25.056 18.6711 31.056 24.653 33.624C26.0761 34.248 27.1857 34.608 28.054 34.872C29.4771 35.328 30.7796 35.256 31.8168 35.112C32.9746 34.944 35.3625 33.672 35.845 32.28C36.3515 30.888 36.3515 29.712 36.1826 29.448C36.0138 29.184 35.652 29.064 35.049 28.776Z"
            fill="white"
          />
        </svg>
      </a>
      <Header />
      <Outlet />
      <Suspense fallback={<div>Loading...</div>}>
        <Footer />
      </Suspense>
    </>
  );
};

export default App;
