import FoodServices from "../components/Home/FoodServices";
import HeroSection from "../components/Home/HeroSection";
import How from "../components/Home/How";
import WhyUs from "../components/Home/WhyUs";
import Points from "../components/Home/Points";
import Technology from "../components/Home/Technology";
import EngineSeo from "../Engine/EngineSeo";
import { useEffect } from "react";
import ContactUs from "../components/Layouts/ContactUs";

const SEO_HOME = {
  en: {
    title: "SkillTax - The Best POS System",
    description:
      "Facilitate and organize the management of restaurants and sales effectively and simply. This system allows the management of all aspects of the restaurant with ease.",
    keywords:
      "best POS system, cashier app, complete restaurant system, POS for restaurants, best POS software, advanced cashier app, easy-to-use POS system, advanced point of sale, restaurant management system, cashier for restaurants and cafes, POS, point of sale, restaurant management",
  },
  ar: {
    title: "SkillTax - سكيل تاكس نظام الكاشير الأمثل",
    description:
      "تسهيل وتنظيم عملية إدارة المطاعم والمبيعات بطريقة فعّالة وبسيطة. يتيح هذا النظام إدارة جميع جوانب المطعم بكل يسر وسهولة.",
    keywords:
      "أفضل سيستم كاشير, تطبيق كاشير للمطاعم, نظام مطعم متكامل, نقاط بيع للمطاعم, أفضل نظام نقاط بيع, تطبيق كاشير متطور, نظام كاشير سهل الاستخدام, نقاط بيع متقدمة, نظام إدارة المطاعم, كاشير للمطاعم والكافيهات, كاشير, نقاط بيع, إدارة مطاعم",
  },
};

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EngineSeo {...SEO_HOME} />
      <HeroSection />
      <WhyUs />
      <Technology />
      <FoodServices />
      <How />
      <Points />
      <ContactUs />
    </>
  );
};

export default Home;
