import { Link } from "react-router-dom";
import { footerLogo, footerPyments, footerZakat } from "../../assets";
import { useTranslation } from "react-i18next";

export const SocialIcons = () => {
  return (
    <ul className="flex gap-4">
      <li className="relative group w-fit">
        <a
          href="https://x.com/skilltax"
          aria-label="Contact us via X"
          rel="noreferrer"
          target="_blank"
          className="w-[38px] group h-[38px] rounded-[10px] border-1 border-[#ffffff40] bg-[#fff] bg-opacity-15 flex items-center justify-center"
        >
          <span className="sr-only ">Twitter</span>
          <svg
            className="group-hover:text-black text-white transition-all"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 19.311L8.5484 11.7626M8.5484 11.7626L1 1.31104H6L11.4516 8.85944M8.5484 11.7626L14 19.311H19L11.4516 8.85944M19 1.31104L11.4516 8.85944"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      </li>
      <li className="relative group w-fit">
        <a
          href="https://www.instagram.com/skilltax.sa"
          aria-label="Contact us via Instagram"
          rel="noreferrer"
          target="_blank"
          className="w-[38px] group h-[38px] rounded-[10px] border-1 border-[#ffffff40] bg-[#fff] bg-opacity-15 flex items-center justify-center"
        >
          <span className="sr-only ">Twitter</span>
          <svg
            className="text-white transition-all"
            width="25"
            height="23"
            viewBox="0 0 22 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop
                  offset="0%"
                  style={{ stopColor: "#fd1d1d", stopOpacity: 1 }}
                />
                <stop
                  offset="50%"
                  style={{ stopColor: "#fcb045", stopOpacity: 1 }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: "#833ab4", stopOpacity: 1 }}
                />
              </linearGradient>
            </defs>
            <path
              d="M1.5 10.311C1.5 5.8327 1.5 3.59353 2.89124 2.20228C4.28249 0.811035 6.52166 0.811035 11 0.811035C15.4783 0.811035 17.7175 0.811035 19.1088 2.20228C20.5 3.59353 20.5 5.8327 20.5 10.311C20.5 14.7893 20.5 17.0285 19.1088 18.4198C17.7175 19.811 15.4783 19.811 11 19.811C6.52166 19.811 4.28249 19.811 2.89124 18.4198C1.5 17.0285 1.5 14.7893 1.5 10.311Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinejoin="round"
              className="group-hover:stroke-[url(#grad)]"
            />
            <path
              d="M15.3815 10.311C15.3815 12.8999 13.3334 14.9985 10.807 14.9985C8.28052 14.9985 6.23242 12.8999 6.23242 10.311C6.23242 7.7222 8.28052 5.62354 10.807 5.62354C13.3334 5.62354 15.3815 7.7222 15.3815 10.311Z"
              stroke="currentColor"
              strokeWidth="1.5"
              className="group-hover:stroke-[url(#grad)]"
            />
            <path
              d="M16.4055 4.58203H16.3965"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="group-hover:stroke-white"
            />
          </svg>
        </a>
      </li>
      <li className="relative group w-fit">
        <a
          href="https://wa.me/+966553559038"
          aria-label="Contact us via WhatsApp"
          rel="noreferrer"
          target="_blank"
          className="w-[38px] group h-[38px] rounded-[10px] border-1 border-[#ffffff40] bg-[#fff] bg-opacity-15 flex items-center justify-center"
        >
          <span className="sr-only ">Twitter</span>

          <svg
            className="group-hover:text-[#25d366] text-white transition-all"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22.311C17.5228 22.311 22 17.8338 22 12.311C22 6.78819 17.5228 2.31104 12 2.31104C6.47715 2.31104 2 6.78819 2 12.311C2 13.6899 2.27907 15.0036 2.78382 16.1987C3.06278 16.8591 3.20226 17.1894 3.21953 17.439C3.2368 17.6886 3.16334 17.9631 3.01642 18.5122L2 22.311L5.79877 21.2946C6.34788 21.1477 6.62244 21.0742 6.87202 21.0915C7.12161 21.1087 7.45185 21.2482 8.11235 21.5272C9.30745 22.0319 10.6211 22.311 12 22.311Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <path
              d="M8.58815 12.6883L9.45909 11.6066C9.82616 11.1507 10.2799 10.7263 10.3155 10.1193C10.3244 9.96598 10.2166 9.27761 10.0008 7.9009C9.91601 7.35985 9.41086 7.31104 8.97332 7.31104C8.40314 7.31104 8.11805 7.31104 7.83495 7.44035C7.47714 7.60379 7.10979 8.06335 7.02917 8.44837C6.96539 8.753 7.01279 8.9629 7.10759 9.38272C7.51023 11.1658 8.45481 12.9268 9.91948 14.3915C11.3842 15.8562 13.1452 16.8008 14.9283 17.2034C15.3481 17.2982 15.558 17.3456 15.8627 17.2818C16.2477 17.2012 16.7072 16.8339 16.8707 16.476C17 16.1929 17 15.9079 17 15.3377C17 14.9001 16.9512 14.395 16.4101 14.3102C15.0334 14.0944 14.3451 13.9866 14.1917 13.9955C13.5847 14.0311 13.1603 14.4848 12.7044 14.8519L11.6227 15.7228"
              stroke="currentColor"
              strokeWidth="1.5"
            />
          </svg>
        </a>
      </li>
      <li className="relative group w-fit">
        <a
          href="https://x.com/skilltax"
          aria-label="Contact us via X"
          rel="noreferrer"
          target="_blank"
          className="w-[38px] group h-[38px] rounded-[10px] border-1 border-[#ffffff40] bg-[#fff] bg-opacity-15 flex items-center justify-center"
        >
          <span className="sr-only ">Twitter</span>

          <svg
            className="group-hover:text-[#0a66c2] text-white transition-all"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 9.81104H4C3.05719 9.81104 2.58579 9.81103 2.29289 10.1039C2 10.3968 2 10.8682 2 11.811V20.311C2 21.2538 2 21.7252 2.29289 22.0181C2.58579 22.311 3.05719 22.311 4 22.311H4.5C5.44281 22.311 5.91421 22.311 6.20711 22.0181C6.5 21.7252 6.5 21.2538 6.5 20.311V11.811C6.5 10.8682 6.5 10.3968 6.20711 10.1039C5.91421 9.81103 5.44281 9.81104 4.5 9.81104Z"
              stroke="currentColor"
              strokeWidth="1.5"
            />
            <path
              d="M6.5 4.56104C6.5 5.80368 5.49264 6.81104 4.25 6.81104C3.00736 6.81104 2 5.80368 2 4.56104C2 3.3184 3.00736 2.31104 4.25 2.31104C5.49264 2.31104 6.5 3.3184 6.5 4.56104Z"
              stroke="currentColor"
              strokeWidth="1.5"
            />
            <path
              d="M12.326 9.81104H11.5C10.5572 9.81104 10.0858 9.81103 9.79289 10.1039C9.5 10.3968 9.5 10.8682 9.5 11.811V20.311C9.5 21.2538 9.5 21.7252 9.79289 22.0181C10.0858 22.311 10.5572 22.311 11.5 22.311H12C12.9428 22.311 13.4142 22.311 13.7071 22.0181C14 21.7252 14 21.2538 14 20.311L14.0001 16.8111C14.0001 15.1543 14.5281 13.8111 16.0879 13.8111C16.8677 13.8111 17.5 14.4827 17.5 15.3111V19.8111C17.5 20.7539 17.5 21.2253 17.7929 21.5182C18.0857 21.8111 18.5572 21.8111 19.5 21.8111H19.9987C20.9413 21.8111 21.4126 21.8111 21.7055 21.5183C21.9984 21.2255 21.9985 20.7542 21.9987 19.8116L22.0001 14.3112C22.0001 11.826 19.6364 9.81128 17.2968 9.81128C15.9649 9.81128 14.7767 10.4641 14.0001 11.485C14 10.8549 14 10.5399 13.8632 10.306C13.7765 10.1579 13.6531 10.0346 13.505 9.94791C13.2711 9.81104 12.9561 9.81104 12.326 9.81104Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      </li>
    </ul>
  );
};

const Footer = () => {
  const { t } = useTranslation();
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const Hover = () => (
    <span className="absolute left-0 -bottom-0.5 block w-full h-[1px] bg-[#E2E9FD] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></span>
  );

  return (
    <>
      <footer className="bg-[#6365C0] text-white mt-20 lg:mt-32">
        <div className="container relative">
          <div className="bg-[#6365c033] lg:container rounded-[20px] h-[78px] left-0 top-[-39px] absolute -z-10"></div>
          <div className="w-full flex items-center justify-center pt-5 pb-6 border-b border-[#ffffff1a]">
            <div>
              <img
                loading="lazy"
                className="max-w-full"
                src={footerLogo}
                alt="logo"
              />
            </div>
          </div>
          <div className="flex flex-col xl:flex-row justify-between py-7 gap-12">
            <div className="flex flex-col gap-7">
              <p className="max-w-96 ">{t("links.footerText")}</p>
              <SocialIcons />
              <div>
                <p className="text-xl mb-2 mr-14">{t("links.compatible")}</p>
                <img
                  loading="lazy"
                  className="w-[270px] object-cover"
                  src={footerZakat}
                  alt="zakat"
                />
              </div>
            </div>
            <div className="flex flex-col gap-12 md:flex-row md:gap-16  flex-wrap justify-between ">
              <div>
                <p className="font-bold text-xl">{t("links.skilltax")}</p>

                <ul className="mt-4 space-y-4 text-sm text-[#E2E9FD]">
                  <li className="relative group w-fit">
                    <Link onClick={handleLinkClick} to="/">
                      {t("links.home")}
                    </Link>
                    <Hover />
                  </li>
                  <li className="relative group w-fit">
                    <Link onClick={handleLinkClick} to="/about-us">
                      {t("links.aboutUs")}
                    </Link>
                    <Hover />
                  </li>

                  <li className="relative group w-fit">
                    <Link onClick={handleLinkClick} to="/customer-journey">
                      {t("links.ourJourney")}
                    </Link>
                    <Hover />
                  </li>
                  <li className="relative group w-fit">
                    <Link onClick={handleLinkClick} to="/packages">
                      {t("links.packages")}
                    </Link>
                    <Hover />
                  </li>
                  <li className="relative group w-fit">
                    <Link onClick={handleLinkClick} to="/contact-us">
                      {t("links.contactUs")}
                    </Link>
                    <Hover />
                  </li>
                  <li className="relative group w-fit">
                    <Link onClick={handleLinkClick} to="/blogs">
                      {t("links.blog")}
                    </Link>
                    <Hover />
                  </li>
                  <li className="relative group w-fit">
                    <Link onClick={handleLinkClick} to="/be-part">
                      {t("links.bePart")}
                    </Link>
                    <Hover />
                  </li>
                  <li className="relative group w-fit">
                    <Link onClick={handleLinkClick} to="/referrals">
                      {t("links.referrals")}
                    </Link>
                    <Hover />
                  </li>
                </ul>
              </div>
              <div>
                <p className="font-bold text-xl"> {t("links.ourServices")}</p>
                <ul className="mt-4 space-y-4 text-sm text-[#E2E9FD]">
                  <li className="relative group w-fit">
                    <Link onClick={handleLinkClick} to="/services/menu">
                      {t("links.menuServices")}
                    </Link>
                    <Hover />
                  </li>
                  <li className="relative group w-fit">
                    <Link onClick={handleLinkClick} to="/services/tod">
                      {t("links.todServices")}
                    </Link>
                    <Hover />
                  </li>
                  <li className="relative group w-fit">
                    <Link onClick={handleLinkClick} to="/services/waiter">
                      {t("links.waiterServices")}
                    </Link>
                    <Hover />
                  </li>
                  <li className="relative group w-fit">
                    <Link onClick={handleLinkClick} to="/services/special">
                      {t("links.specialServices")}
                    </Link>
                    <Hover />
                  </li>
                  <li className="relative group w-fit">
                    <Link
                      onClick={handleLinkClick}
                      to="/services/restaurant-ordering"
                    >
                      {t("links.orderingServices")}
                    </Link>
                    <Hover />
                  </li>
                  <li className="relative group w-fit">
                    <Link
                      onClick={handleLinkClick}
                      to="/services/booking-tables"
                    >
                      {t("links.bookingServices")}
                    </Link>
                    <Hover />
                  </li>
                </ul>
              </div>
              <div>
                <p className="font-bold text-xl">{t("links.publicLinks")}</p>

                <ul className="mt-4 space-y-4 text-sm text-[#E2E9FD]">
                  <li className="relative group w-fit">
                    <Link onClick={handleLinkClick} to="/faqs">
                      {t("links.faqs")}
                    </Link>
                    <Hover />
                  </li>
                  <li className="relative group w-fit">
                    <Link onClick={handleLinkClick} to="#">
                      {t("links.terms")}
                    </Link>
                    <Hover />
                  </li>
                  <li className="relative group w-fit">
                    <Link onClick={handleLinkClick} to="/privacies">
                      {t("links.privacies")}
                    </Link>
                    <Hover />
                  </li>
                </ul>
              </div>
              <div>
                <p className="font-bold text-xl"> {t("links.payments")}</p>
                <p className="mt-4">
                  <img
                    loading="lazy"
                    className="max-w-full"
                    src={footerPyments}
                    alt="pyments"
                  />
                </p>
              </div>
            </div>
          </div>
          <p className="mx-auto w-full flex justify-center text-sm py-2">
            {t("links.rights")}
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
