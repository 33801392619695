import i18next, { t } from "i18next";
import { technology } from "../../assets";

const Technology = () => {
  return (
    <section className="mt-20 lg:mt-32">
      <div className="container">
        <div className="relative">
          <p
            className={`${
              i18next.language == "ar"
                ? "right-4 lg:right-14 lg:leading-[82px] "
                : "left-4 lg:left-14 lg:leading-[56px] "
            }max-w-[230px] sm:max-w-[624px] text-white font-bold text-lg sm:text-xl lg:text-[50px] absolute top-[50%] translate-y-[-50%]`}
          >
            {t("home.technology")}
          </p>
          <img loading="lazy" src={technology} alt="technology" />
        </div>
      </div>
    </section>
  );
};

export default Technology;
