import { Link } from "react-router-dom";
import EngineSeo from "../Engine/EngineSeo";

const SEO_404 = {
  en: {
    title: "SkillTax - Page Not Found",
    description:
      "Sorry, the page you are looking for does not exist. Please check the URL or return to the homepage.",
    keywords: "404 Page, Page Not Found, SkillTax Error, SkillTax 404",
  },
  ar: {
    title: "SkillTax - الصفحة غير موجودة",
    description:
      "عذرًا، الصفحة التي تبحث عنها غير موجودة. يرجى التحقق من الرابط أو العودة إلى الصفحة الرئيسية.",
    keywords: "صفحة 404, الصفحة غير موجودة, خطأ سكيل تاكس, سكيل تاكس 404",
  },
};

const Page404 = () => {
  return (
    <>
      <EngineSeo {...SEO_404} />
      <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
        <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
          <div className="relative">
            <div className="absolute">
              <div>
                <h1 className="my-2 text-[#131313] font-bold text-2xl">
                  لايمكنك الوصول الى هنا
                </h1>
                <p className="my-2 text-[#131313]">توجه إلى الصفحة الرئيسية</p>
                <Link to="/">
                  <button className="bg-[#6C74E8] h-[52px] w-[226px] rounded-[10px] text-white mt-5">
                    الصفحة الرئيسية
                  </button>
                </Link>
              </div>
            </div>
            <div>
              <img
                loading="lazy"
                src="https://i.ibb.co/G9DC8S0/404-2.png"
                alt="404-icon"
              />
            </div>
          </div>
        </div>
        <div>
          <img
            loading="lazy"
            src="https://i.ibb.co/ck1SGFJ/Group.png"
            alt="not-found"
          />
        </div>
      </div>
    </>
  );
};

export default Page404;
