import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";

// Create a context
export const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const WAJEN_URL = "https://dash.wajen.net/api";

  // FQS
  const [fqs, setFqs] = useState([]);
  const [fqsIsLoading, setFqsIsLoading] = useState(false);
  const fetchFqs = async () => {
    try {
      setFqsIsLoading(true);
      let response = await fetch(`${WAJEN_URL}/apiFaqs`);
      let data = await response.json();
      setFqs(data);
    } catch (err) {
      console.log(err);
    } finally {
      setFqsIsLoading(false);
    }
  };

  // PRIVACIES
  const [privacies, setPrivacies] = useState([]);
  const [PrivaciesIsLoading, setPrivaciesIsLoading] = useState(false);
  const fetchPrivacies = async () => {
    try {
      setPrivaciesIsLoading(true);
      let response = await fetch(`${WAJEN_URL}/privacies`);
      let data = await response.json();
      setPrivacies(data);
    } catch (err) {
      console.log(err);
    } finally {
      setPrivaciesIsLoading(false);
    }
  };

  // CITIES
  const [cities, setCities] = useState([]);
  const [citiesLoading, setCitiesLoading] = useState(false);
  const fetchCities = async () => {
    try {
      setCitiesLoading(true);
      let response = await fetch("https://backend.skilltax.sa/api/v1/cities");
      let data = await response.json();
      const cityOptions = data.map((city) => ({
        value: city.id,
        label: i18n.language == "ar" ? city.ar_name : city.en_name,
        ...city,
      }));
      setCities(cityOptions);
    } catch (err) {
      console.log(err);
    } finally {
      setCitiesLoading(false);
    }
  };

  // BLOGS
  const [blogDepartmentList, setBlogDepartmentList] = useState([]);
  const [blogDepartmentListIsLoading, setBlogDepartmentListIsLoading] =
    useState(false);
  const fetchblogDepartmentList = async () => {
    try {
      setBlogDepartmentListIsLoading(true);
      let response = await fetch(`${WAJEN_URL}/v2/blogDepartmentList`);
      let data = await response.json();
      setBlogDepartmentList(data);
    } catch (err) {
      console.log(err);
    } finally {
      setBlogDepartmentListIsLoading(false);
    }
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    // "ar-SA"
    return date.toLocaleDateString(
      i18n.language == "ar" ? "ar-GB" : "en-GB",
      options
    );
  };

  const contextValue = {
    fqs,
    fetchFqs,
    fqsIsLoading,
    privacies,
    PrivaciesIsLoading,
    fetchPrivacies,
    fetchCities,
    citiesLoading,
    cities,
    formatDate,
    blogDepartmentList,
    blogDepartmentListIsLoading,
    fetchblogDepartmentList,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
