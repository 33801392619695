import { useTranslation } from "react-i18next";
import { whiteTrue } from "../../assets";
const Stepper = ({ currentStep, complete }) => {
  const { i18n } = useTranslation();

  return (
    <>
      <div className="flex justify-between">
        {[{}, {}, {}]?.map((step, i) => (
          <div
            key={i}
            className={`${
              i18n.language == "ar" ? "step-item-ar" : "step-item-en"
            } step-item w-28 sm:w-36 select-none ${
              currentStep === i + 1 && "active"
            } ${(i + 1 < currentStep || complete) && "complete"} `}
          >
            <div className="step">
              {i + 1 < currentStep || complete ? (
                <img src={whiteTrue} alt="true" />
              ) : (
                i + 1
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Stepper;
