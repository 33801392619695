import { useEffect, useState } from "react";
import {
  referralsHero,
  bannerRectangle,
  referralsForm,
  sent,
  buttonArrow,
} from "../assets";
import Field from "../components/Layouts/Field";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import EngineSeo from "../Engine/EngineSeo";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import Stepper from "../components/Layouts/Stepper";
import Sent from "../components/Layouts/Sent";

const SEO_REFERRALS = {
  en: {
    title: "SkillTax - Referrals | Earn Rewards by Recommending Our POS System",
    description:
      "Explore the SkillTax Referrals page to learn how you can earn rewards by referring our POS system to others. Discover the benefits of our referral program and how you can participate to maximize your earnings.",
    keywords:
      "Referrals, SkillTax Referral Program, Earn Rewards, POS System Referrals, Recommend SkillTax, Referral Benefits, POS System Incentives",
  },
  ar: {
    title: "SkillTax - إحالة معارفك | اربح مكافآت من خلال توصية نظامنا",
    description:
      "استكشف صفحة إحالة معارفك الخاصة بسكيل تاكس لتتعلم كيف يمكنك ربح مكافآت من خلال إحالة نظام نقاط البيع لدينا للآخرين. اكتشف فوائد برنامج الإحالة لدينا وكيف يمكنك المشاركة لزيادة أرباحك.",
    keywords:
      "إحالة معارفك, برنامج إحالة سكيل تاكس, اربح مكافآت, إحالات نظام نقاط البيع, أوصِ بسكيل تاكس, فوائد الإحالة, حوافز نظام نقاط البيع",
  },
};

const Referrals = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Form Validation
  const validationSchema = Yup.object().shape({
    friend_first_name: Yup.string().required(t("forms.firstNameReq")),
    friend_last_name: Yup.string().required(t("forms.lastNameReq")),
    friend_phone: Yup.string()
      .required(t("forms.phoneReq"))
      .matches(/^\d{9}$/, t("forms.phoneErr")),
    friend_email: Yup.string()
      .email(t("forms.emailErr"))
      .typeError(t("forms.emailErr"))
      .nullable(),
    subscriber_fullname: Yup.string().required(t("forms.nameReq")),
    subscriber_business_name: Yup.string().required(t("forms.activityNameReq")),
    subscriber_activity: Yup.string().required(t("forms.activityTypeReq")),
    subscriber_phone: Yup.string()
      .required(t("forms.phoneReq"))
      .matches(/^\d{9}$/, t("forms.phoneErr")),
    subscriber_email: Yup.string()
      .email(t("forms.emailErr"))
      .typeError(t("forms.emailErr"))
      .nullable(),
    subscriber_agree: Yup.boolean().nullable(),
    terms_conditions: Yup.boolean().oneOf([true], t("forms.termsReq")),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      friend_first_name: "",
      friend_last_name: "",
      friend_phone: "",
      friend_email: "",
      subscriber_fullname: "",
      subscriber_business_name: "",
      subscriber_activity: "",
      subscriber_phone: "",
      subscriber_agree: false,
      terms_conditions: false,
    },
  });

  const [isValidToStep, setIsValidToStep] = useState(false);
  const watchedFields = watch([
    "friend_first_name",
    "friend_last_name",
    "friend_phone",
    "friend_email",
  ]);

  useEffect(() => {
    const validateFields = async () => {
      try {
        await validationSchema.validateAt("friend_first_name", {
          friend_first_name: watchedFields[0],
        });
        await validationSchema.validateAt("friend_last_name", {
          friend_last_name: watchedFields[1],
        });
        await validationSchema.validateAt("friend_phone", {
          friend_phone: watchedFields[2],
        });
        await validationSchema.validateAt("friend_email", {
          friend_email: watchedFields[3],
        });
        setIsValidToStep(true);
      } catch (error) {
        setIsValidToStep(false);
      }
    };
    validateFields();
  }, [validationSchema, watchedFields, setValue]);

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmission = async (values) => {
    delete values["terms_conditions"];
    try {
      setIsLoading(true);
      const response = await fetch(`https://dash.wajen.net/api/v2/friends`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(values),
      });
      reset({
        friend_first_name: "",
        friend_last_name: "",
        friend_phone: "",
        friend_email: "",
        subscriber_fullname: "",
        subscriber_business_name: "",
        subscriber_activity: "",
        subscriber_phone: "",
        subscriber_email: "",
        subscriber_agree: false,
        terms_conditions: false,
      });
      setComplete(true);
      setCurrentStep(3);
    } catch (error) {
      toast.error(t("forms.err"));
    } finally {
      setIsLoading(false);
    }
  };

  // steps
  const [currentStep, setCurrentStep] = useState(1);
  const [complete, setComplete] = useState(false);

  const submitClick = () => {
    if (currentStep == 1 && isValidToStep) {
      setCurrentStep(2);
    }
    if (currentStep == 2) {
      handleSubmit(handleSubmission)();
      console.log("click");
    }
  };

  useEffect(() => {
    if (currentStep == 2) {
      setValue("subscriber_fullname", "");
      setValue("subscriber_business_name", "");
    }
  }, [currentStep, setValue]);

  return (
    <>
      <EngineSeo {...SEO_REFERRALS} />
      <section className="bg-[#6C74E8] w-full">
        {/* Hero */}
        <div className="w-full flex items-center flex-col md:flex-row pt-8 p-4 md:p-0 relative overflow-hidden">
          <div className="absolute -rotate-180 -top-7 md:right-[8%]">
            <img loading="lazy" src={bannerRectangle} alt="banner-rectangle" />
          </div>
          <div className="hidden md:block absolute -rotate-90 md:bottom-28 -right-[150px] ">
            <img loading="lazy" src={bannerRectangle} alt="banner-rectangle" />
          </div>
          <div className="flex-1 w-full flex items-center justify-center md:px-4">
            <div className="max-w-[570px] text-start mt-4 md:mt-0 text-[#EFEFEF]">
              <h1 className="font-medium relative flex-col-reverse lg:flex-row text-2xl lg:text-[39px] lg:leading-[60.87px] flex items-center md:items-start lg:items-center gap-3">
                <span className="z-10 relative">{t("referrals.heading")}</span>
                <span
                  className={`${
                    i18n.language == "ar"
                      ? "right-0 top-[25px]"
                      : "left-0 top-[20px]"
                  } hidden lg:block absolute h-6 bg-[#5E5DDE] translate-y-0 w-[52.5%]`}
                ></span>
              </h1>
              <div className="mt-8 mb-3 md:mb-6">
                <p className="text-base md:text-lg lg:text-2xl font-medium text-white">
                  {t("referrals.headingTextOne")}
                </p>
                <p className="text-base md:text-lg lg:text-2xl font-medium text-white mt-2 md:mt-3">
                  {t("referrals.headingTextTwo")}
                </p>
              </div>
              <p
                className="text-sm md:text-lg text-[#CAD6FB]"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(t("referrals.headingTextThree")),
                }}
              />
            </div>
          </div>
          <div className="flex-1 flex items-center gap-1 justify-end ">
            <img
              loading="lazy"
              src={referralsHero}
              alt="be-part-hero-image"
              className="w-full object-cover max-w-full rounded-[15px] md:rounded-none mt-7 md:mt-0 h-[362px] lg:h-[500px]"
            />
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <div className="container">
          <h2 className="text-center text-[#131313] font-medium text-[40px] leading-[48px]">
            {t("referrals.advantages")}
          </h2>
          <div className="mt-16 flex items-center flex-wrap gap-6 justify-center">
            <div className="px-[14px] ps-[29px] bg-white rounded-[20px] text-[#131313] text-xl font-medium h-[110px] w-full sm:w-[295px] flex items-center relative">
              <p>{t("referrals.advantagesOne")}</p>
              <span
                className={`${
                  i18n.language == "ar" ? "right-4" : "left-4"
                } absolute top-[48%] sm:top-[50%] translate-y-[-50%] bg-[#5E5DDE] w-[3px] h-11`}
              ></span>
            </div>
            <div className="px-[14px] ps-[29px] bg-white rounded-[20px] text-[#131313] text-xl font-medium h-[110px] w-full sm:w-[295px] flex items-center relative">
              <p>{t("referrals.advantagesTwo")}</p>
              <span
                className={`${
                  i18n.language == "ar" ? "right-4" : "left-4"
                } absolute top-[48%] sm:top-[50%] translate-y-[-50%] bg-[#5E5DDE] w-[3px] h-11`}
              ></span>
            </div>
            <div className="px-[14px] ps-[29px] bg-white rounded-[20px] text-[#131313] text-xl font-medium h-[110px] w-full sm:w-[295px] flex items-center relative">
              <p>{t("referrals.advantagesThree")}</p>
              <span
                className={`${
                  i18n.language == "ar" ? "right-4" : "left-4"
                } absolute top-[48%] sm:top-[50%] translate-y-[-50%] bg-[#5E5DDE] w-[3px] h-11`}
              ></span>
            </div>
            <div className="px-[14px] ps-[29px] bg-white rounded-[20px] text-[#131313] text-xl font-medium h-[110px] w-full sm:w-[295px] flex items-center relative">
              <p>{t("referrals.advantagesFour")}</p>
              <span
                className={`${
                  i18n.language == "ar" ? "right-4" : "left-4"
                } absolute top-[48%] sm:top-[50%] translate-y-[-50%] bg-[#5E5DDE] w-[3px] h-11`}
              ></span>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20 lg:mt-32">
        <h3 className="text-xl sm:text-3xl text-black font-bold text-center px-4 ">
          {t("referrals.invest")}
        </h3>
        <div className="container bg-white lg:bg-transparent mt-16">
          {/* form */}
          <div className="bg-white flex">
            <div className="flex-1 w-full h-full py-10 lg:px-10 2xl:px-20">
              <h4 className="text-2xl md:text-3xl xl:text-5xl xl:leading-[60px] font-medium text-[#101828] text-center">
                {t("referrals.investIt")}
              </h4>
              <div className="my-7 sm:my-10 w-fit mx-auto">
                <Stepper currentStep={currentStep} complete={complete} />
              </div>
              <div className="w-full flex items-center flex-col gap-1">
                {currentStep == 1 ? (
                  <>
                    <div className="w-full flex items-start gap-4 sm:gap-6">
                      <Field
                        name="friend_first_name"
                        label={t("forms.firstName")}
                        control={control}
                        errors={errors}
                        isRequired
                      />
                      <Field
                        name="friend_last_name"
                        label={t("forms.lastName")}
                        control={control}
                        errors={errors}
                        isRequired
                      />
                    </div>
                    <Field
                      type="number"
                      name="friend_phone"
                      label={t("forms.phone")}
                      placeholder="553559038"
                      control={control}
                      errors={errors}
                      isRequired
                      float="+966"
                    />
                    <Field
                      type="email"
                      name="friend_email"
                      label={t("forms.email")}
                      control={control}
                      errors={errors}
                    />
                  </>
                ) : currentStep == 2 ? (
                  <>
                    <p className="text-xl font-medium text-black text-start self-start mb-4">
                      {t("forms.newCustomer")}
                    </p>
                    <Field
                      name="subscriber_fullname"
                      label={t("forms.fullName")}
                      control={control}
                      errors={errors}
                      isRequired
                    />
                    <Field
                      name="subscriber_business_name"
                      label={t("forms.activityName")}
                      control={control}
                      errors={errors}
                      isRequired
                    />

                    <Field
                      name="subscriber_activity"
                      label={t("forms.activityType")}
                      control={control}
                      errors={errors}
                      isRequired
                    />
                    <Field
                      type="number"
                      name="subscriber_phone"
                      label={t("forms.phone")}
                      control={control}
                      placeholder="553559038"
                      errors={errors}
                      float="+966"
                      isRequired
                    />
                    <Field
                      type="email"
                      name="subscriber_email"
                      label={t("forms.email")}
                      control={control}
                      errors={errors}
                    />
                    <Field.CheckBox
                      control={control}
                      errors={errors}
                      name="subscriber_agree"
                    >
                      {t("forms.invited")}
                    </Field.CheckBox>
                    <Field.CheckBox
                      className="mt-3"
                      control={control}
                      errors={errors}
                      name="terms_conditions"
                      isRequired
                    >
                      {t("forms.termsText")}
                      <Link className="underline" to="/privacies">
                        {" "}
                        {t("forms.terms")}
                      </Link>{" "}
                      {t("forms.termsApproved")}
                    </Field.CheckBox>
                  </>
                ) : (
                  <Sent />
                )}
                {!complete && (
                  <Field.Button
                    icon={buttonArrow}
                    disabled={!isValidToStep && currentStep == 1}
                    onClick={submitClick}
                    isLoading={isLoading}
                  >
                    {currentStep == 1
                      ? t("forms.complete")
                      : t("forms.sendData")}
                  </Field.Button>
                )}
              </div>
            </div>
            <div className="flex-1 hidden lg:block">
              <img
                loading="lazy"
                src={referralsForm}
                alt="referrals-form"
                className={`${
                  currentStep == 1
                    ? "h-[695.8px]"
                    : complete
                    ? "h-[512px]"
                    : "h-full"
                } w-full object-cover `}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Referrals;
